import sidebar from "./_sidebar/sidebar.json";
import subRoutes from "./_subRoutes/subRoutes.json";
import mtopbar from "./_top/mTopBarEn.json";
import mLogin from "./_public/mLoginEn.json";
import mSingin from "./_public/mSinginEn.json";
import mDashboard from "./_module/_mDashboard/mDashboardEn.json";
import m1100 from "./_module/_m1000/_m1100/m1100.json";
import m1200 from "./_module/_m1000/_m1100/m1200.json";
import m1300 from "./_module/_m1000/_m1100/m1300.json";
export {
  sidebar,
  subRoutes,
  mtopbar,
  mLogin,
  mSingin,
  mDashboard,
  m1100,
  m1200,
  m1300,
};
