import {
    Badge,
    Button, Checkbox, Descriptions, FloatButton,
    Form,
    Input,
    InputNumber, message, Modal,
    Popconfirm,
    Space,
    Table, TablePaginationConfig,
    type TableProps,
    Tag, Tooltip,
    Typography
} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {useRecoilState} from "recoil";
import {positionMapResponseState} from "../../../state/positionMap";
import dayjs from "dayjs";
import {FacebookOutlined, LinkedinOutlined, SearchOutlined, TwitterOutlined, YoutubeOutlined} from '@ant-design/icons';

import fillKeyColors from "../Theme";
import colorConfigs from "../Theme";
import {formInstance} from "../../../configs/axiosConfig";
import {decryptToken} from "../../../utils/tokenManagement";
import {FilterValue} from "antd/es/table/interface";
import {getDashboardDataSearch} from "../../../pages/_main_contractor/_dashboardMgmt/_service/_admin/service";
import {MdEditLocationAlt} from "react-icons/md";
import HistoryAppendModal from "../../../components/common/Modal/historyAppendModal";
import HistoryAppendForm from "../../../components/common/Modal/historyAppendModal";
import {useNavigate} from "react-router-dom";

interface Item {
    key: string;
    no: string;
    date: string;
    travelHistoryId?: string;
    seq?: string;
    location?: string;
    latitude: number;
    longitude: number;
    fillKey: string;
    state?: string;
    detailLoc?: string;
    substate?: string;
    thGubun?: string;
    action?: string;
    thStartDatetime?: string;
    thEndDatetime?: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

interface DashboardTravelHistoryResponse {
    reactKey: string;
    travelHistoryId: string;
    seq: string;
    userId: string;
    thTitle: string | null;
    thSaddress: string | null;
    thSlatitude: string | null;
    thSlongitude: string | null;
    thEaddress: string;
    thEdetailAddress: string;
    thElatitude: string;
    thElongitude: string;
    thType: string;
    thGubun: string;
    thStartDatetime: string;
    thEndDatetime: string;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       editing,
                                                       dataIndex,
                                                       title,
                                                       inputType,
                                                       record,
                                                       index,
                                                       children,
                                                       ...restProps
                                                   }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

interface GridTableProps<RecordType> extends TableProps<RecordType> {
    keyAttact: boolean;
    setKeyAttact: React.Dispatch<React.SetStateAction<boolean>>;
    selectMapNameResponse: string;
    setSelectMapNameResponse: React.Dispatch<React.SetStateAction<string>>;
    selectMapLatitudeResponse: number;
    setSelectMapLatitudeResponse: React.Dispatch<React.SetStateAction<number>>;
    selectMapLongitudeResponse: number;
    setSelectMapLongitudeResponse: React.Dispatch<React.SetStateAction<number>>;
    historyData: DashboardTravelHistoryResponse[];
    token: string;
    refetch: () => void;
}

const GridTable = <RecordType extends object>(props: GridTableProps<RecordType>) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const {Text} = Typography;
    const movePage = useNavigate();

    const moveHistory = useCallback(
        (isTravelHistory, isMapSynced, isLatitude, isLongitude) => {
            const sanitizedIsTravelHistory = encodeURIComponent(isTravelHistory.trim());
            const sanitizedIsMapSynced = encodeURIComponent(isMapSynced.trim());
            const sanitizedIsLatitude = encodeURIComponent(isLatitude.toString().trim());
            const sanitizedIsLongitude = encodeURIComponent(isLongitude.toString().trim());

            movePage(
                `/manager/workplace/details?isTravelHistory=${sanitizedIsTravelHistory}&isMapSynced=${sanitizedIsMapSynced}&isLatitude=${sanitizedIsLatitude}&isLongitude=${sanitizedIsLongitude}`
            );
        },
        [movePage]
    );
    // const moveHistory = (siteId: string) => {
    //     movePage(`/manager/details?siteId=${siteId}`); // ID에 따라 이동할 경로 설정
    // };
    // // Transform `dashboardTravelHistoryResponses` to match `Item` type

    const [transformedData, setTransformedData] = useState<Item[]>([]);

    useEffect(() => {
        const data = props.historyData
            .filter((item) => item.thType !== 'HOME') // Filter out items with thType 'HOME'
            .map((item, index) => ({
                key: item.reactKey,
                travelHistoryId: item.travelHistoryId,
                seq: item.seq,
                no: (index + 1).toString(),
                date: item.thStartDatetime + '~' + item.thEndDatetime, // Placeholder for date; replace with actual date if available
                location: item.thEaddress,
                latitude: parseFloat(item.thElatitude || '0'),
                longitude: parseFloat(item.thElongitude || '0'),
                thGubun: item.thGubun,
                fillKey: item.thType,
                detailLoc: item.thEdetailAddress || '',
            }));

        setTransformedData(data);
    }, [props.historyData]);

    const isEditing = (record: Item) => record.key === editingKey;

    const edit = (record: Partial<Item> & { key: React.Key }) => {
        form.setFieldsValue({name: '', age: '', address: '', ...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const locationCheck = (record: any) => {
        props.setKeyAttact(false);
        props.setSelectMapNameResponse(record.location);
        props.setSelectMapLatitudeResponse(record.latitude);
        props.setSelectMapLongitudeResponse(record.longitude);
        setTimeout(() => {
            props.setKeyAttact(true);
        }, 0);
        setTimeout(() => {
            props.setKeyAttact(false);
        }, 3000);
    };

    const fnHistoryDelete = (record: any) => {
        const DeleteData = new FormData();

        DeleteData.append('travelHistoryId', record.travelHistoryId);
        DeleteData.append('seq', record.seq);

        // setUploading(true);
        formInstance
            .post(`/api/v1/mDashboard/admin/delete/user/history`, DeleteData, {
                withCredentials: true, headers: {
                    'Authorization': `Bearer ${decryptToken(props.token)}`
                }
            })
            .then(response => {
                message.success('Delete success');
                props.refetch();

            })
            .catch(error => {
                console.error('Delete error:', error);
                message.error('Delete error: ' + (error.response?.data?.message || 'Unknown error'));
            })
            .finally(() => {
            });
    };

    interface TableParams {
        pagination?: TablePaginationConfig;
        sortField?: string;
        sortOrder?: string;
        filters?: Record<string, FilterValue>;
    }


    const columns = [
        {
            title: 'No.',
            dataIndex: 'no',
            width: '60px',
            editable: true,
            render: (_: any, record: Item) => {
                const tagColor = colorConfigs[record.fillKey as keyof typeof colorConfigs] || colorConfigs['defaultFill'];
                return (
                    <Space direction="vertical" style={{textAlign: 'center'}}>
                        <Badge color="#FFFFFF" text={""}/>
                        {record.no}
                    </Space>

                );
            }
        },
        {
            title: '구분',
            dataIndex: 'fillKey',
            width: '25%',
            filters: [
                {text: 'CURRENT POINT', value: 'CURRENT POINT'},
                {text: 'PREDICTED', value: 'PREDICTED'},
                {text: 'FISHING', value: 'FISHING'},
                {text: 'TRAVEL', value: 'TRAVEL'},
                {text: 'CAMPING & FISHING', value: 'CNF'},
                {text: 'FOOD', value: 'FOOD'},
                {text: 'defaultFill', value: 'defaultFill'},
            ],
            onFilter: (value: string | number | boolean, record: Item) => {
                return typeof value === 'string' && record.fillKey.includes(value);
            },
            sorter: (a: { fillKey: string | any[]; }, b: {
                fillKey: string | any[];
            }) => a.fillKey.length - b.fillKey.length,
            render: (_: any, record: Item) => {
                const tagColor = colorConfigs[record.fillKey as keyof typeof colorConfigs] || colorConfigs['defaultFill'];
                return (
                    <Space direction="vertical" style={{textAlign: 'center'}}>
                        <Badge color="#FFFFFF" text={""}/>
                        <Tag icon={<TwitterOutlined/>} color={tagColor}>
                            {record.fillKey}
                        </Tag>
                    </Space>

                );
            }
        },
        {
            title: '내용',
            dataIndex: 'date',
            width: '50%',
            editable: true,
            sorter: (a: { date: string }, b: { date: string }) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            },
            render: (_: any, record: Item) => {
                return (
                    <Space direction="vertical">
                        {record.detailLoc !== "" ? <Badge color="#53BE28FF" text={record.detailLoc}/> : ""}
                        <Badge color="#53BE28FF" text={record.date}/>
                        <Badge color="#53BE28FF" text={record.location}/>
                    </Space>
                );
            }
        },
        {
            title: '고유id',
            dataIndex: 'travelHistoryId',
            width: '20%',
            editable: true,
            hidden: true
        },

        {
            title: '순번',
            dataIndex: 'seq',
            width: '20%',
            editable: true,
            hidden: true
        },
        {
            title: '지역',
            dataIndex: 'location',
            width: '20%',
            editable: true,
            hidden: true
        },
        {
            title: 'latitude',
            dataIndex: 'latitude',
            width: '20%',
            editable: true,
            hidden: true
        },
        {
            title: 'longitude',
            dataIndex: 'longitude',
            width: '20%',
            editable: true,
            hidden: true
        },
        {
            title: 'thGubun',
            dataIndex: 'thGubun',
            width: '20%',
            editable: true,
            hidden: true
        },
        {
            title: '',
            dataIndex: 'action',
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Space direction="vertical">
                        <Typography.Link disabled={editingKey !== ''} onClick={() => locationCheck(record)}>
                            <Badge color={colorConfigs.accentColor} text="위치확인"
                                   style={{color: colorConfigs.accentColor}}/>
                        </Typography.Link>
                        <Typography.Link disabled={false} onClick={() => moveHistory(
                            record?.travelHistoryId || "",
                            record?.thGubun || "",
                            record.latitude,
                            record.longitude)
                        }>
                            <Badge color={colorConfigs.green} text="세부정보" style={{color: colorConfigs.green}}/>
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ''}>
                            <Popconfirm
                                title="경고!"
                                description="일정을 삭제하시겠습니까?"
                                okText="Yes"
                                onConfirm={() => fnHistoryDelete(record)}
                            >
                                <Badge color={colorConfigs.dangerColor} text="삭제"
                                       style={{color: colorConfigs.dangerColor}}/>
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 2,
        },
    });

    const [isModalVisible, setIsModalVisible] = useState(false); // 모달 가시성 상태
    const [paModalVisible, setPaModalVisible] = useState(false); // 모달 가시성 상태

    useEffect(() => {
        setIsModalVisible(paModalVisible)
    }, [paModalVisible]);

    const showModal = () => {
        setPaModalVisible(true);
        setIsModalVisible(true); // 모달 열기
    };

    const handleOk = () => {
        setIsModalVisible(false); // 확인 클릭 시 모달 닫기
        if (props.refetch) {
            props.refetch(); // 필요 시 refetch 호출
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false); // 취소 클릭 시 모달 닫기
    };


    return (
        <>

            <Descriptions
                bordered
                title="검색조건"
                size={"small"}
                extra={
                    <Tooltip title="search">
                        <Button
                            type="primary"
                            icon={<SearchOutlined/>}
                            onClick={showModal} // 클릭 시 showModal 호출
                        >
                            일정등록
                        </Button>
                    </Tooltip>
                }
                style={{marginTop: 10}}
            />

            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                size={"small"}
                bordered
                dataSource={transformedData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{pageSize: 3}}
                // pagination={tableParams.pagination}
                // pagination={{
                //     onChange: cancel,
                // }}
                scroll={{y: '50vh'}}
                // size="small"
            />
            {/*<Modal*/}
            {/*    visible={isModalVisible}*/}
            {/*    onOk={handleOk}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    width={0}*/}
            {/*    footer={null} // 필요한 경우 footer 추가 가능*/}
            {/*    destroyOnClose={true} // 모달이 닫힐 때 컴포넌트 언마운트*/}
            {/*>*/}
            {isModalVisible ?
                <HistoryAppendForm token={props.token} refetch={props.refetch} paVisible={true}
                                   setIsModalVisible={setPaModalVisible}/>
                :
                null}
            {/*</Modal>*/}

        </>
    );
};

export default GridTable;
