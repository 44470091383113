const assets = {
  images: {
    logo: require("./images/fitaLogo.png"),
    logo_icon: require("./images/logo_icon.png"),
    logo_full: require("./images/logo_full.png"),
    hejp_logo: require("./images/fitaLogo.png"),
    hejp_icon: require("./images/hejp_icon.png"),
    info_bg: require("./images/born.png")
  }
};

export default assets;