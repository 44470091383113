import { Outlet, useLocation, useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import _sidebar from "../common/Sidebar/_sidebar";
import _topbar from "../common/Header/_topbar";
import { useRecoilValue } from "recoil";
import AppFooter from "../common/Footer/_footer";
import { useCallback, useEffect, useState } from "react";
import {RsWrapper, Wrapper} from "../common/commonComponents";
import useWidth from "../common/useWidth";
import { Button, Layout, Menu, Row } from "antd";
import styled from "styled-components";
import { Header } from "antd/lib/layout/layout";
import _topMainMenu from "../common/Header/_main_contractor/_topMenu";
import { loginResponseState } from "../../state/userinfo";
import _topSubMenu from "../common/Header/_sub_contractor/_topMenu";
import { isToggleAtomState } from "../../state/systemInit";
import { WarningOutlined } from "@ant-design/icons";
import { Cookies } from "react-cookie";
import { GENERAL } from "../../configs/userTypeConfig";
import _menubar from "../../components/common/Sidebar/_menubar";
import ImageMaker from "../common/ImageMaker/ImageMaker";
import {isMobile, isTablet} from "react-device-detect";
const MainLayout = () => {
  const loginResponse = useRecoilValue(loginResponseState);
  const isToggle = useRecoilValue(isToggleAtomState);
  const agent = navigator?.userAgent;
  const appVersion = navigator?.appVersion;

  const [isChrome, setIsChrome] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const width = useWidth();
  const location = useLocation();

  const cookies = new Cookies();

  const browserDisplayNone = () => {
    const popup = document?.getElementById(`browser`);
    if (popup) {
      popup.style.display = "none";
    }
  };

  const _closeTodayPopupHandler = useCallback(() => {
    browserDisplayNone();

    cookies.set(`browser`, "y", {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
    setIsChrome(true);
  }, []);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const cookie = cookies.get("browser");

    if (/Chrome/.test(agent) && /Edg/.test(appVersion)) {
      setIsChrome(false);
      if (cookie === "y") {
        browserDisplayNone();
      }
    } else if (/Chrome/.test(agent)) {
      setIsChrome(true);
    } else {
      setIsChrome(false);
      if (cookie === "y") {
        browserDisplayNone();
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper dr={`row`} width={`100%`} ju={`flex-start`}>
      {/*{!isChrome && (*/}
      {/*  <Wrapper*/}
      {/*    dr={`row`}*/}
      {/*    bgColor={`rgba(245, 204, 217, 1)`}*/}
      {/*    padding="5px 0"*/}
      {/*    id={`browser`}*/}
      {/*  >*/}
      {/*    <WarningOutlined style={{ color: `rgb(226,128, 158)` }} />*/}
      {/*    &nbsp; HEJP는 Chrome 브라우저의 이용을 권장합니다. &nbsp;*/}
      {/*    <Button*/}
      {/*      ghost*/}
      {/*      type="link"*/}
      {/*      href="https://www.google.com/intl/ko_kr/chrome/"*/}
      {/*      target="_blank"*/}
      {/*    >*/}
      {/*      크롬 다운로드*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      type="link"*/}
      {/*      ghost*/}
      {/*      danger*/}
      {/*      onClick={() => _closeTodayPopupHandler()}*/}
      {/*    >*/}
      {/*      오늘 하루 보지 않기*/}
      {/*    </Button>*/}
      {/*  </Wrapper>*/}
      {/*)}*/}

      {/*{loginResponse?.loginType !== GENERAL && width > 700 && (*/}
      {/*  <_sidebar />*/}
      {/*)}*/}
      {/* RIGHT */}

        <_topbar />
      {width < 950 ? (
          <Wrapper padding={`10px`}>
            <Outlet />
          </Wrapper>
      ) : (
        <Wrapper
          height={`calc(100vh - 64px)`}
          al={`flex-start`}
          ju={`flex-start`}
        >
          {width < 950 ? (
              <Wrapper padding={`10px`}>
                <Outlet />
              </Wrapper>
          ) : (
              <Wrapper
                  dr={`row`}
                  height={`calc(100dvh - 64px)`}
                  minHeight={`calc(100dvh - 64px)`}
                  maxHeight={`calc(100dvh - 64px)`}
                  al={`stretch`}
                  ju={`flex-end`}
              >
                {/*<_sidebar />*/}
                <Wrapper
                    width={
                          `calc(100% )`
                    }
                    overflowY="scroll"
                    position="relative"
                    al={`flex-start`}
                    ju={`space-between`}
                    overflowX={`hidden`}
                    bgColor={colorConfigs.mainBg}
                >

                  <Wrapper
                      flex="1" // this makes it take up all available space
                      overflowY="scroll"
                      position="relative"
                      al={`flex-start`}
                      ju={`space-between`}
                      overflowX={`hidden`}
                      bgColor={colorConfigs.mainBg}
                  >
                    <Wrapper dr={`row`} al={`flex-start`} padding={`10px 10px`}>
                      {location.pathname.split("/").length > 2 && !location.pathname.includes("/manager/workplace") && !location.pathname.includes("/general/workplace")  && !location.pathname.includes("/management") && <_menubar />}
                      {isTablet || (width<=1366 && !isMobile)? (
                          <Wrapper
                              width={
                                location.pathname.split("/").length > 2 && !location.pathname.includes("/manager/workplace") && !location.pathname.includes("/general/workplace") && !location.pathname.includes("/management")
                                    ? `calc(100% - 320px)`
                                    : `100%`
                              }
                          >
                            <Wrapper dr={`row`} ju={`flex-start`} al={`flex-start`} padding={`6px 10px`}>
                              {loginResponse?.loginType === GENERAL ? (
                                  // <_topSubMenu />
                                  <_topMainMenu />
                              ) : (
                                  <_topMainMenu />
                              )}
                            </Wrapper>
                            <Outlet />
                          </Wrapper>
                          ) : (
                          <RsWrapper
                              width={
                                location.pathname.split("/").length > 2 && !location.pathname.includes("/manager/workplace") && !location.pathname.includes("/general/workplace") && !location.pathname.includes("/management")
                                    ? `calc(100% - 320px)`
                                    : `100%`
                              }
                          >
                            <Wrapper dr={`row`} ju={`flex-start`} al={`flex-start`} padding={`6px 10px`}>
                              {loginResponse?.loginType === GENERAL ? (
                                  // <_topSubMenu />
                                  <_topMainMenu />
                              ) : (
                                  <_topMainMenu />
                              )}
                            </Wrapper>
                            <Outlet />
                          </RsWrapper>
                      )}

                    </Wrapper>
                    <AppFooter />
                  </Wrapper>
                </Wrapper>
              </Wrapper>
          )}
          {/*<Wrapper*/}
          {/*  flex="1" // this makes it take up all available space*/}
          {/*  overflowY="scroll"*/}
          {/*  position="relative"*/}
          {/*  al={`flex-start`}*/}
          {/*  ju={`space-between`}*/}
          {/*  overflowX={`hidden`}*/}
          {/*  bgColor={colorConfigs.mainBg}*/}
          {/*>*/}
          {/*  <_sidebar />*/}

          {/*  <Wrapper dr={`row`} ju={`flex-start`} al={`flex-start`} padding={`20px 10px`}>*/}
          {/*    {loginResponse?.loginType === GENERAL ? (*/}
          {/*        <_topSubMenu />*/}
          {/*    ) : (*/}
          {/*        <_topMainMenu />*/}
          {/*    )}*/}
          {/*    {location.pathname.split("/").length > 2 && <_menubar />}*/}

          {/*    <Wrapper*/}
          {/*      width={*/}
          {/*        location.pathname.split("/").length > 2*/}
          {/*          ? `calc(100% - 50px)`*/}
          {/*          : `calc(100% - 320px)`*/}
          {/*      }*/}

          {/*    >*/}
          {/*      <Outlet />*/}
          {/*    </Wrapper>*/}
          {/*  </Wrapper>*/}
          {/*</Wrapper>*/}
        </Wrapper>
      )}
      {width < 950 && <AppFooter />}
      </Wrapper>
  );
};

export default MainLayout;
