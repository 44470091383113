import React from "react";
import {Provider} from "react-redux";
import App from "./app";
import {store} from "./redux/store";
import {RecoilRoot} from "recoil";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import "./locales";
import {QueryClient, QueryClientProvider} from "react-query";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {CookiesProvider} from "react-cookie";
import "./index.css"

const queryClient = new QueryClient(); // 생성


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <CookiesProvider>
                <QueryClientProvider client={queryClient}>
                    <RecoilRoot>
                        <App/>
                    </RecoilRoot>
                </QueryClientProvider>
            </CookiesProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();

reportWebVitals();
