// helpers.ts 파일
import * as CryptoJS from "crypto-js";
import {cryptoKey} from "./cryptoManagement";

export function decryptToken(token: string): string {
    /**
     * 주어진 암호화된 토큰을 복호화하여 원래의 문자열을 반환합니다.
     * @param token 암호화된 토큰
     * @returns 복호화된 문자열
     */
    const decryptedBytes = CryptoJS.AES.decrypt(token, cryptoKey);
    const decryptedAccessToken = decryptedBytes.toString(CryptoJS.enc.Utf8);

    return  decryptedAccessToken;
}


export function encryptedAccessToken(token: string): string{
    const encryptedAccessToken = CryptoJS.AES.encrypt(token, cryptoKey).toString();

    return encryptedAccessToken;
}