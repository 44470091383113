import axios from "axios";
import {useCookies} from "react-cookie";
import codeDefine from "../defined/codeDefine";
// Create a base Axios instance for your API calls
export const api = axios.create({
    // Dev
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        accept: "application/json",
    },
});

// Create another Axios instance for your API calls with credentials
export const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        accept: "application/json",
    },
    withCredentials: true, // Enable credentials (cookies) for CORS
});
export const apis = {
  get: () => api.get("/api/v1/public/pages/community/content/lists"), //지금은 단순한 get요청
  // 코드 추가시...
};

export const formInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "multipart/form-data; charset=UTF-8",
        // accept: "multipart/form-data",
    },
    withCredentials: true, // Enable credentials (cookies) for CORS
});
export const getM1100DataSearch = (
    pd_name: String,
) =>
    api
        .get(
            `/api/v1/m1100/prod/object/lists?pa_pd_name=${pd_name}`
        )
        .then((res) => res.data);


export const getM1200DataSearch = () =>
  api.get("/api/v1/m1100/jobinfo/lists").then((res) => res.data);

export const getM1300DataSearch = (pa_pd_name: String,
                                   pa_start_dt: String,
                                   pa_end_dt: String) =>
    api.get(`/api/v1/m1100/jobinfolog/lists?pa_pd_code=${pa_pd_name}&pa_start_dt=${pa_start_dt}&pa_end_dt=${pa_end_dt}`).then((res) => res.data);

export const getTestData = () =>
  api.get("api/v1/main/grid/data").then((res) => res.data);

// export const updateMovies = (id: number) =>
//     api
//         .get(`/movie/popular?api_key=${API_KEY}&page=${id}`)
//         .then((res) => res.data)
