// ZoomSlider.tsx
import React from 'react';
import { Slider } from 'antd';

interface ZoomSliderProps {
    zoomLevel: number;
    onZoomChange: (value: number) => void;
}

const ZoomSlider: React.FC<ZoomSliderProps> = ({ zoomLevel, onZoomChange }) => {
    return (
        <div style={{
            // position: 'absolute',
            bottom: '10px',
            left: '50%',
            // transform: 'translateX(-50%)',
            width: '80%',
        }}>
            {/*<div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', width: '80%', marginTop: '30px' }}>*/}
            <Slider
                min={50}
                max={250}
                step={1}
                value={(zoomLevel / 4000) * 100}
                onChange={onZoomChange}
                tooltipVisible
                tooltip={{ formatter: value => `${value}%` }}
                marks={{
                    50: '50%',
                    100: '100%',
                    150: '150%',
                    200: '200%',
                    250: '250%',
                }}
            />
        </div>
    );
};

export default ZoomSlider;
