import React from "react";
import { Image, Wrapper, Text } from "../commonComponents";
import Theme from "../Theme";
import assets from "../../../assets";
import { Col, FloatButton, Layout, Row } from "antd";
import useWidth from "../useWidth";
import { MdManageSearch } from "react-icons/md";
import { AiFillCustomerService } from "react-icons/ai";
import { useRecoilValue } from "recoil";
import { loginResponseState } from "../../../state/userinfo";
import colorConfigs from "../../../configs/colorConfigs";
import { GENERAL } from "../../../configs/userTypeConfig";

const AppFooter = () => {
  const width = useWidth();
  const { Footer } = Layout;
  const loginResponse = useRecoilValue(loginResponseState);

  return (
    <Footer
      // padding={width < 700 ? `30px 0` : `60px 0`}
      // color={Theme.darkColor}
      style={{
        width: "100%", // Make sure width is 100%
        height: `30px`,
        padding: 60,
        backgroundColor:
          loginResponse?.loginType === GENERAL
            ? colorConfigs.mainBg
            : colorConfigs.basic.white,
      }}
    >
      <Row gutter={[16, 32]}>
        <Col xs={20} md={20}>
          <Wrapper
            width={`auto`}
            fontSize={width ? `13px` : `14px`}
            al={`flex-start`}
          >
            {width < 700 ? (
              <>
                <Text margin={`0 0 7px`}>
                  주소 : 대전광역시 유성구 가정북로 96 일자리경제진흥원 411호
                </Text>
              </>
            ) : (
              <Row gutter={[16, 32]}>
                <Col xs={8} md={8}>
                  <Image width={`150px`} src={assets.images.logo_full} />
                </Col>
                <Col xs={16} md={16}>
                  <Text
                    color={Theme.grey_C}
                    fontSize={`12px`}
                    margin={width < 700 ? `0 0 7px` : "0"}
                  >
                    COPYRIGHT (c) OVERCOMPLEX ALL RIGHTS RESERVED.
                  </Text>
                  <Text color={Theme.grey_C} fontSize={`12px`}>
                    Home ||&nbsp;
                    <a
                      className="App-link"
                      href="http://www.overcomplex.co.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.overcomplex.co.kr
                    </a>
                  </Text>
                </Col>
              </Row>
            )}
          </Wrapper>
        </Col>
        <Col xs={4} md={4}>
          <Wrapper
            width={`auto`}
            fontSize={`25px`}
            fontWeight={`900`}
            al={`flex-start`}
          >

            {/*<Image width={`200px`} src={assets.images.logo_full}/>*/}
          </Wrapper>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
