import {initI18n} from "./i18n";


export const i18n_language_items = [
    /**
     * i18n language 셋팅
     * */
    {
        key: "🇰🇷 ko-KR",
        label: "🇰🇷 ko-KR",
    },
    {
        key: "🇺🇸 en-US",
        label: "🇺🇸 en-US",
    },
];
export default initI18n("🇰🇷 ko-KR");