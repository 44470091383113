import { instance } from "../../../../configs/axiosConfig";
import codeDefine from "../../../../defined/codeDefine";
import { LoadNotification } from "../../../../components/common/Notification/_notification";
import { LoginResponse, UserInfo } from "../../../../state/userinfo";
import { SystemInit } from "../../../../state/systemInit";
import { encryptedAccessToken } from "../../../../utils/tokenManagement"; // 'crypto-js'로 import
import {ADMIN, GENERAL} from "../../../../configs/userTypeConfig";

const loginUser = (
  data: any,
  navigate: Function,
  setCookie: Function,
  setLoginResponse: (
    valOrUpdater:
      | ((currVal: UserInfo | null) => UserInfo | null)
      | UserInfo
      | null
  ) => void,
  setSystemInit: (
    valOrUpdater:
      | ((currVal: SystemInit | null) => SystemInit | null)
      | SystemInit
      | null
  ) => void
) => {
  const moveMainRouter = () => {
    navigate("/manager");
  };
  const moveSubRouter = () => {
    // navigate("/general");
    navigate("/manager");
  };
  const updateLoginEnabled = () => {
    const newLoginActivateValue = true;
    setSystemInit((prevSystemInit: SystemInit | null) => ({
      ...prevSystemInit!,
      loginActivate: newLoginActivateValue,
    }));
  };
  instance
    .post(`/api/v1/auth/user/login`, data, { withCredentials: true })
    .then((response) => {
      const responseData = response.data.result;
      const someDateInTheFuture = new Date();

      if (responseData.resultCode === 200) {
        someDateInTheFuture.setDate(someDateInTheFuture.getDate() + 1); // Adds 1 days
        setCookie(
          "accessToken",
          encryptedAccessToken(responseData.accessToken),
          { path: "/", expires: someDateInTheFuture }
        );
        LoadNotification({
          msg: "알림",
          content: responseData.userInfo.userName +"/"+ responseData.userInfo.troleCode+ " Login Success",
        });
        // Use the response data to update the Recoil state
        setLoginResponse(responseData.userInfo);
        updateLoginEnabled();
        responseData.userInfo.loginType === ADMIN
          ? moveMainRouter()
          : moveSubRouter();
      } else {
        LoadNotification({ msg: "Error", content: response.data.resultMsg });
      }
    })
    .catch((error) => {
      LoadNotification({
        msg: "Error",
        content: codeDefine.axiosResponseMessages.error,
      });
      if (error.response) {
        const errorResponseData = error.response.data;
        LoadNotification({ msg: "Error", content: errorResponseData });
      } else if (error.request) {
        LoadNotification({
          msg: "Error",
          content: codeDefine.axiosResponseMessages.networkError,
        });
      } else {
        LoadNotification({
          msg: "Error",
          content: codeDefine.axiosResponseMessages.unexpectedError,
        });
        console.error("Unexpected error:", error.message);
      }
    });
};

export { loginUser };
