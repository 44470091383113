import { RouteType } from "./config";
import React, { ReactNode } from "react";
import _moduleBean from "../pages/_public/_moduleBean";
import { BsFillBoxSeamFill, BsInfoCircleFill } from "react-icons/bs";
import { Route } from "react-router-dom";
import PageWrapper from "../components/layout/PageWrapper";
import FileUploadTest from "../pages/_sub_contractor/_mc9000/_mc9100/_mc9101/FileUploadTest";

export const subRoutes: RouteType[] = [
  // sub-contractor
  // ----------------------------


  {
    index: true,
    path: "/test/file-upload",
    element: <FileUploadTest />,
    state: "_fileMgmt",
    sidebarProps: {
      displayText: "File Upload Test",
      icon: <BsInfoCircleFill />,
    },
  },

];

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    )
  );
};

export const sRoutes: ReactNode = generateRoute(subRoutes);
