import { atom } from 'recoil';
import * as CryptoJS from 'crypto-js';
import {cryptoKey} from "../utils/cryptoManagement"; // 'crypto-js'로 import

export type UserInfo = {
    uid: string;
    userPassword: string;
    userImage:string;
    userStatus: string;
    troleCode: string;
    roleDesc: string;
    userSequence: null | string;
    roleCode: string;
    userName: string;
    loginType: string;

    companyNo: string;
    companyName: string;

    lastLoginDatetime: string;
};

export type LoginResponse = {
    resultCode: number;
    failMsg: null | string;
    userInfo: UserInfo;
    accessToken: string;
    refreshToken: string;
};

//
// export const loginResponseState = atom<UserInfo | null>({
//     key: 'loginResponse',
//     default: null,
//     effects_UNSTABLE: [
//         ({ setSelf, onSet }) => {
//             const savedValue = localStorage.getItem('loginResponse');
//             if (savedValue != null) {
//                 setSelf(JSON.parse(savedValue));
//             }
//
//             onSet((newValue) => {
//                 if (newValue == null) {
//                     localStorage.removeItem('loginResponse');
//                 } else {
//                     localStorage.setItem('loginResponse', JSON.stringify(newValue));
//                 }
//             });
//         },
//     ],
// });

export const loginResponseState = atom<UserInfo | null>({
    key: 'loginResponse',
    default: null,
    effects_UNSTABLE: [
        ({ setSelf, onSet }) => {
            const encryptedValue = localStorage.getItem('loginResponse');
            if (encryptedValue != null) {
                // 로컬 스토리지에서 암호화된 값을 가져오고 복호화합니다.
                const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, cryptoKey).toString(CryptoJS.enc.Utf8);
                if (decryptedValue) {
                    setSelf(JSON.parse(decryptedValue));
                }
            }

            onSet((newValue) => {
                if (newValue == null) {
                    localStorage.removeItem('loginResponse');
                } else {
                    // 값을 암호화하여 로컬 스토리지에 저장합니다.
                    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(newValue), cryptoKey).toString();
                    localStorage.setItem('loginResponse', encryptedValue);
                }
            });
        },
    ],
});