import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import React, { useCallback, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import colorConfigs from "./configs/colorConfigs";
import _moduleLogin from "./pages/_public/_loginMgmt/_moduleLogin";
import _moduleUserInfo from "./pages/_public/_userInformationMgmt/_moduleUserInfo";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { initI18n } from "./locales/i18n";
import { IsLanguage, isLanguageState } from "./state/systemInit";
import _moduleRegister from "./pages/_public/_registerMgmt/_moduleRegister";
import _moduleBean from "./pages/_public/_moduleBean";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CookiesProvider } from "react-cookie";
import { tRoutes } from "./routes/templateRoutes";
import { sRoutes as subRoutes } from "./routes/subRoutes";
import MainConstractorDashboardDetailIndex from "./pages/_main_contractor/_dashboardMgmt/_dashboardDetailIndex";
import {setScreenHeight} from "./utils/setScreenHeight";


function App() {
  const toggleLocales = useCallback((locale: string) => {
    initI18n(locale);
  }, []);
  const setLanguageAtom = useSetRecoilState(isLanguageState);
  const languageState = useRecoilValue(isLanguageState);
  const isLanguage = useRecoilValue(isLanguageState);
  useEffect(() => {
    setScreenHeight();

    // resize 이벤트가 발생하면 다시 계산하도록 아래 코드 추가
    window.addEventListener('resize', setScreenHeight);
    return () => window.removeEventListener('resize', setScreenHeight);
  }, []);
  useEffect(() => {
    // languageState이 null이면 기본 언어 값을 사용하고, 그렇지 않으면 languageState의 언어 값을 반환
    const language = languageState ? languageState.language : "🇰🇷 ko-KR";

    const newLanguageValue = "🇰🇷 ko-KR";
    setLanguageAtom((prevIsLanguage: IsLanguage | null) => {
      if (prevIsLanguage) {
        return {
          ...prevIsLanguage,
          language: language,
        };
      }
      return {
        language: language,
      };
    });
    initI18n(language);
  }, [setLanguageAtom]);
  return (
    <ThemeProvider theme={colorConfigs}>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<_moduleLogin />} />
            <Route path="/signup" element={<_moduleRegister />} />
            <Route path="/subcontractor" element={<MainLayout />} />
            {/*<Route path="/manager/detail" element={<MainConstractorDashboardDetailIndex />} />*/}

            <Route path="/" element={<_moduleLogin />} />
            <Route>{tRoutes}</Route>
            {/* <Route element={<TemplateLayout />}>{tRoutes}</Route> */}
            <Route element={<MainLayout />}>{subRoutes}</Route>
            <Route element={<MainLayout />}>{routes}</Route>
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </ThemeProvider>
  );
}

export default App;
