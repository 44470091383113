import React, { useState, useEffect } from 'react';
import mapJsonData from './dirJson/Country_Data_with.json';

// Define a type for the country option
interface CountryOption {
    value: string;
    label: string;
}

interface SelectMapProps {
    onChange?: (value: string) => void; // Prop to handle changes in selected value
}

const SelectMap: React.FC<SelectMapProps> = ({ onChange }) => {
    const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);

    useEffect(() => {
        // Using the imported JSON data instead of fetching it
        const fetchCountryData = () => {
            try {
                // Map through the imported JSON data
                const options: CountryOption[] = mapJsonData.map((country: { country_id: string; country_label?: string | null }) => ({
                    value: country.country_id,
                    label: country.country_label ?? country.country_id, // Use fallback if country_label is null or undefined
                }));

                setCountryOptions(options);
            } catch (error) {
                console.error('Error parsing the JSON data:', error);
            }
        };

        fetchCountryData();
    }, []); // Empty dependency array ensures this runs once when the component mounts

    return (
        <>
            <select
                style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '4px',
                    borderColor: '#d9d9d9'
                }}
                onChange={(e) => {
                    const selectedValue = e.target.value; // Get the selected value
                    if (onChange) {
                        onChange(selectedValue); // Call the onChange prop with the selected value
                    }
                }}
            >
                <option value="NONE">--선택--</option>

                {countryOptions.map((country, index) => (
                    <option key={index} value={country.value}>
                        {country.label}
                    </option>
                ))}
            </select>
        </>
    );
};

export default SelectMap;
