import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Image,
  SpanText,
  WholeWrapper,
  Wrapper,
} from "../../../components/common/commonComponents";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popover,
  Result,
  Row,
  Segmented,
  Select,
  Space,
  Steps,
  theme,
  Typography,
} from "antd";
import Theme from "../../../components/common/Theme";
import colorConfigs from "../../../configs/colorConfigs";
import _topbar from "../../../components/common/Header/_topbar";
import { SystemInit, systemInitState } from "../../../state/systemInit";
import useWidth from "../../../components/common/useWidth";
import { MdOutlineArrowBack } from "react-icons/md";
import { TbUser, TbUsers } from "react-icons/tb";
import { StepFormData } from "./_model/model";
import { useTranslation } from "react-i18next";
import { api, instance } from "../../../configs/axiosConfig";
import { LoadNotification } from "../../../components/common/Notification/_notification";
import dayjs, { Dayjs } from "dayjs";
import {
  BsCalendar3Week,
  BsFillFileEarmarkPersonFill,
  BsFillPersonVcardFill,
} from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import codeDefine, { userRoleCode } from "../../../defined/codeDefine";
import { registerUser } from "./_service/service";
import { AxiosResponse } from "axios";
import assets from "../../../assets";
import { GENERAL } from "../../../configs/userTypeConfig";
import GoogleMapCM from "../../../components/common/Map/googleMap";

interface ApiLoginParams {
  data: any;
}

const _moduleRegister = () => {
  const id = "daum-postcode"; // script가 이미 rending 되어 있는지 확인하기 위한 ID
  const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

  useEffect(() => {
    const isAlready = document.getElementById(id);

    if (!isAlready) {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      document.body.append(script);
    }
  }, []);
  const width = useWidth();
  const { t } = useTranslation("mSingin");

  // useEffect(() => {
  //   // updateLoginInit(); // This will run only once on the initial render
  //   // stepFormData.userType = "MAIN_CONTRACTOR_USER";
  // }, []);


  const { Text } = Typography;

  const [currentStep, setCurrentStep] = useState(0);

  const [step1Form] = Form.useForm();
  const [step2Form] = Form.useForm();
  const [step3Form] = Form.useForm();
  const [step4Form] = Form.useForm();

  const movePage = useNavigate();
  const postcodeRef = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  // const [selectedUserLatitude, setSelectedUserLatitude] = useState("");
  // const [selectedUserLongitude, setSelectedUserLongitude] = useState("");
  const [selectedZoneCode, setSelectedZoneCode] = useState(0);
  const [selectedRoadAddress, setSelectedRoadAddress] = useState("");
  const [selectedDetailAddress, setSelectedDetailAddress] = useState("");
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedLongitude, setSelectedLongitude] = useState("");


  const [businessNumber, setBusinessNumber] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [stepFormData, setStepFormData] = useState<StepFormData>({});
  const [isDateValue, setIsDateValue] = useState<string>("");
  const [isUserBirthDate, setIsUserBirthDate] = useState<string>("");
  const [isStatusValid, setIsStatusValid] = useState<boolean | null>(false);
  const [isAllStatusValid, setIsAllStatusValid] = useState<boolean | null>(
    false
  );
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [isIdCheck, setIsIdCheck] = useState<boolean | null>(null);

  const [open, setOpen] = useState(false);
  const [isResponse, setResponse] = useState<AxiosResponse | null>(null); // Define 'response' state

  const apiRegister = ({ data }: ApiLoginParams) => {
    console.log("data in apiLogin:", data); // Debugging
    registerUser(data, movePage);
  };
  // useEffect(() => {
  //   if (selectedUserLatitude !== null && selectedUserLongitude !== null) {
  //     setSelectedLatitude(selectedUserLatitude);
  //     setSelectedLongitude(selectedUserLongitude);
  //   }
  // }, [selectedUserLatitude, selectedUserLongitude]);


  useEffect(() => {
    step3Form.setFieldsValue({
      userZone: selectedZoneCode,
      userAddress: selectedAddress,
      userRoadAddress: selectedRoadAddress,
    });
  }, [selectedAddress, selectedZoneCode, selectedRoadAddress]);


  useEffect(() => {
    step3Form.setFieldsValue({
      userDetailAddress: selectedDetailAddress,
    });
  }, [selectedDetailAddress]);



  useEffect(() => {
    step3Form.setFieldsValue({
      userLatitude: selectedLatitude,
      userLongitude: selectedLongitude,
    });
  }, [selectedLatitude, selectedLongitude]);

  const postVendorStatus = () => {
    /**
     * 사업자 등록번호 상태 확인 API POST
     * 필수데이터 : 사업자등록번호
     * */
    const values = step1Form.getFieldsValue() || "";
    const bNo = values.bNo || "";
    const sanitizedCompanyCode = bNo.replace(/-/g, "") || "";
    if (bNo && bNo.trim() !== "") {
      const data = {
        b_no: [sanitizedCompanyCode],
      };
      instance
        .post(`/api/v1/auth/user/vendor/status`, data)
        .then((response) => {
          setResponse(response);
          // 성공시
          if (response.data.resultCode == 200) {
            LoadNotification({
              msg: codeDefine.msgResponse.success,
              content: "Success: " + response.data.resultMsg,
            });
            setIsStatusValid(true);
          } else if (response.data.resultCode == 201) {
            showModal();
          } else if (response.data.resultCode == 401) {
            LoadNotification({
              msg: codeDefine.msgResponse.error,
              content: "Error: " + response.data.resultMsg,
            });
          }
          // refetch()
          // window.location.replace("/");
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {});
    } else {
      LoadNotification({
        msg: codeDefine.msgResponse.warning,
        content: "사업자코드 확인 필요. ",
      });
    }
  };
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (isResponse) {
      // Check if 'response' is available
      // LoadNotification({msg: "알림", content: "상태 체크: " + isResponse.data.resultMsg});
      setIsDateValue(isResponse.data.result.businessOpenDate);
      step1Form.setFieldsValue({
        startDt: isResponse.data.result.businessOpenDate,
        pNm: isResponse.data.result.businessPerson,
        bNm: isResponse.data.result.businessNm,
        companyPerson: isResponse.data.result.companyPerson,
        companyPersonTel: isResponse.data.result.companyPersonTel,
        companyPersonEmail: isResponse.data.result.companyPersonEmail,
      });
      setIsAllStatusValid(true);
      setIsStatusValid(true);

      setOpen(false);
    }
  };

  const handleCancel = () => {
    setResponse(null);
    setOpen(false);
  };

  const postIdCheckStatus = () => {
    /**
     * 사업자 등록번호 상태 확인 API POST
     * 필수데이터 : 사업자등록번호
     * */
    const values = step2Form.getFieldsValue();
    const userId = values.userId;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!userId || !userId.trim()) {
      // userId is null or empty, so set isIdCheck to null
      LoadNotification({
        msg: codeDefine.msgResponse.warning,
        content: "상태 체크: 사용자 아이디 공백 또는 Null.",
      });
      setIsIdCheck(null);
      return; // Exit the function early
    }

    if (!emailRegex.test(userId)) {
      // userId doesn't match the email regex, so set isIdCheck to null
      LoadNotification({
        msg: codeDefine.msgResponse.warning,
        content: "상태 체크: 이메일 형식에 어긋납니다.",
      });
      setIsIdCheck(null);
      return; // Exit the function early
    }

    const data = {
      uid: userId,
    };
    instance
      .post(`/api/v1/auth/user/uid/check`, data)
      .then((response) => {
        // 성공시
        if (response.data.result.statusCode == 101) {
          LoadNotification({
            msg: codeDefine.msgResponse.success,
            content: "Success: " + response.data.result.comment,
          });
          setIsIdCheck(true);
        } else if (response.data.result.statusCode == 901) {
          LoadNotification({
            msg: codeDefine.msgResponse.error,
            content: "Error: " + response.data.result.comment,
          });
          setIsIdCheck(false);
        }
      })
      .catch((error) => {
        // 실패시
        LoadNotification({
          msg: codeDefine.msgResponse.error,
          content: "Error: " + error.response.data.resultMsg,
        });
        setIsIdCheck(null);
      })
      .finally(() => {});
  };

  const postVendorValidate = () => {
    /**
     * 사업자 등록번호 진위 확인 API POST
     * 필수데이터 : 사업자등록번호, 대표자성함, 개업날짜, 업체상호
     * */
    const values = step1Form.getFieldsValue();
    const bNo = values.bNo || "";
    const startDt = values.startDt || "";

    const sanitizedCompanyCode = bNo.replace(/-/g, "") || "";
    const sanitizedStartDate = startDt.replace(/-/g, "") || "";

    const data = {
      businesses: [
        {
          b_no: sanitizedCompanyCode,
          start_dt: sanitizedStartDate,
          p_nm: values.pNm,
          b_nm: values.bNm,
        },
      ],
    };
    instance
      .post(`/api/v1/auth/user/vendor/validate`, data)
      .then((response) => {
        if (response.data.status != 500) {
          LoadNotification({
            msg: codeDefine.msgResponse.success,
            content: "상태 체크: " + response.data.resultMsg,
          });
          const formData = step1Form.getFieldsValue(); // Get current form data
          nextStep(formData);
        } else {
          LoadNotification({
            msg: codeDefine.msgResponse.error,
            content: "상태 체크: " + "Error.",
          });
        }
        // 성공시
      })
      .catch((error) => {
        // 실패시
        LoadNotification({
          msg: codeDefine.msgResponse.error,
          content: "에러 체크: " + error.response,
        });
        console.log(error.response);
      })
      .finally(() => {});
  };

  const nextStep = useCallback(
    /**
     * 회원 등록 절차 다음스텝 이동 이벤트
     * */
    (e) => {
      // Rename 'e' to 'formData' for clarity
      setStepFormData((prev) => Object.assign(prev, e));

      if (currentStep === 0) {
        if (isIdCheck) {
          setCurrentStep((prev) => prev + 1);
        } else {
          LoadNotification({
            msg: codeDefine.msgResponse.warning,
            content: t("message_user_id_check"),
          });
          const myInput = document.getElementById("uidInput");
          if (myInput !== null) {
            myInput.focus();
          }
        }
      } else if (currentStep === 1) {
        const data = {
          roleCode: GENERAL,
          //로그인 정보
          uid: stepFormData.userId, // Use formData
          userPassword: stepFormData.password, // Use formData
          userPasswordCheck: stepFormData.passwordCheck,
          //사용자 정보
          userName: stepFormData.userName,
          userTel: stepFormData.userTel,
          userZone: stepFormData.userZone,
          userEmail: stepFormData.userEmail,
          userAddress: stepFormData.userRoadAddress,
          userRoadAddress: stepFormData.userRoadAddress,
          userDetailAddress: stepFormData.userDetailAddress,
          userLatitude: stepFormData.userLatitude,
          userLongitude: stepFormData.userLongitude,
          userBirth: stepFormData.userBirth,
          userSystemLanguage: "ko"
          //중장비 정보
          // equipmentList: stepFormData.equipmentList
        };
        apiRegister({ data: data });
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    },
    [currentStep, stepFormData, isIdCheck]
  );
  const prevStep = () => {
    /**
     * 회원 등록 절차 이전 스텝 이동 이벤트
     * 단 첫 단계에서의 이전은 홈 화면으로 이동.
     * */
    if (currentStep === 0) {
      movePage("/login");
    }
    if (currentStep === 1) {
      setCurrentStep(0);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };
  const loadLayout = () => {
    /**
     * 카카오 주소 팝업 Init
     * */
    setSelectedAddress(""); // 선택한 주소를 상태에 저장
    setSelectedZoneCode(0);
    setSelectedRoadAddress("");
    setSelectedDetailAddress("");
    setSelectedLatitude("");
    setSelectedLongitude("");

    window.daum.postcode.load(() => {
      new window.daum.Postcode({
        oncomplete: function (data) {
          console.log("location: " + JSON.stringify(data));
          const fullAddress = data.roadAddress;
          const zoneCode = data.zonecode;
          const roadAddress = data.roadAddress;
          setSelectedAddress(fullAddress); // 선택한 주소를 상태에 저장
          setSelectedZoneCode(zoneCode);
          setSelectedRoadAddress(roadAddress);
          getLatLng(fullAddress);
        },
      }).open();
    });
  };
  const [isGoogleMapVisible, setGoogleMapVisible] = useState(false); // To control Google Map modal visibility

  const [isMapType, setIsMapType] = useState("");

  const loadGoogleLayout = () => {
    /**
     * 구글 주소 팝업 Init
     * */
    setIsMapType("");
    setSelectedAddress(""); // 선택한 주소를 상태에 저장
    setSelectedZoneCode(0);
    setSelectedRoadAddress("");
    setSelectedDetailAddress("");
    setSelectedLatitude("");
    setSelectedLongitude("");

    setIsMapType("GOOGLE");
    setGoogleMapVisible(true); // Show Google Map modal

  };


  const handleMapSelect = (lat: string, lng: string, address: string, roadAddress: string) => {
    setSelectedLatitude(lat);
    setSelectedLongitude(lng);
    setSelectedAddress(address);
    setSelectedRoadAddress(roadAddress)
    setGoogleMapVisible(false); // Close the modal after selecting a location
  };

  const getLatLng = (address: any) => {
    const geocoder = new window.kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, function(result: {
      y: any;
      x: any; }[], status: string) {
      if (status === window.kakao.maps.services.Status.OK) {
        const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
        const x = coords.getLat()
        const y = coords.getLng()
        setSelectedLatitude(x);
        setSelectedLongitude(y);
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };
  const handleKeyDown = (e: { key: string }) => {
    /**
     * 1스텝 사업자 상태 필드에서 Enter 입력시 postVendorStatus 이벤트 진행
     * */
    const values = step1Form.getFieldsValue();
    const bNo = values.bNo;

    if (e.key === "Enter") {
      // Code to be executed when the Enter key is pressed
      if (bNo?.length >= 12) {
        postVendorStatus();
      } else {
        LoadNotification({
          msg: codeDefine.msgResponse.warning,
          content: t("message_business_key_down"),
        });
      }
    }
  };


  const customStepOneClick = () => {
    step1Form.submit();
    postVendorValidate();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Truncate to 10 digits if there are more
    if (value.length > 10) {
      value = value.slice(0, 10);
      setBusinessNumber(value);
    }

    // Apply the format: Add the dashes after 3rd and 5th digits
    if (value.length > 5) {
      value = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
    } else if (value.length > 3) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    }

    setBusinessNumber(value);

    const regex = /^\d{3}-\d{2}-\d{5}$/; // Regex for Korean business number format
    setIsValid(regex.test(value) || value === ""); // Check if the value matches the regex or is empty
    step1Form.setFieldsValue({ bNo: value });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueDate = e.target.value;
    // Remove all non-digit characters
    valueDate = valueDate.replace(/\D/g, "");

    // Truncate to 8 digits if there are more (since yyyy-mm-dd has 8 digits)
    if (valueDate.length > 8) {
      valueDate = valueDate.slice(0, 8);
    }
    // Apply the format: Add the dashes after the 4th and 6th digits
    if (valueDate.length > 6) {
      valueDate = `${valueDate.slice(0, 4)}-${valueDate.slice(
        4,
        6
      )}-${valueDate.slice(6)}`;
    } else if (valueDate.length > 4) {
      valueDate = `${valueDate.slice(0, 4)}-${valueDate.slice(4)}`;
    }
    setIsDateValue(valueDate);
    step1Form.setFieldsValue({ startDt: valueDate });
  };
  const selectedDate: DatePickerProps["onChange"] = (date, dateString) => {
    // @ts-ignore
    setIsDateValue(dateString);
    step1Form.setFieldsValue({ startDt: dateString });
  };
  const handleInputUserBirthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let valueDate = e.target.value;
    // Remove all non-digit characters
    valueDate = valueDate.replace(/\D/g, "");

    // Truncate to 8 digits if there are more (since yyyy-mm-dd has 8 digits)
    if (valueDate.length > 8) {
      valueDate = valueDate.slice(0, 8);
    }
    // Apply the format: Add the dashes after the 4th and 6th digits
    if (valueDate.length > 6) {
      valueDate = `${valueDate.slice(0, 4)}-${valueDate.slice(
        4,
        6
      )}-${valueDate.slice(6)}`;
    } else if (valueDate.length > 4) {
      valueDate = `${valueDate.slice(0, 4)}-${valueDate.slice(4)}`;
    }
    setIsUserBirthDate(valueDate);
    step3Form.setFieldsValue({ userBirth: valueDate });
  };
  const handleInputPersonTelChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let valueTel = e.target.value;

    // Remove all non-digit characters
    valueTel = valueTel.replace(/\D/g, "");

    // Apply the format: XXX-XXXX-XXXX
    if (valueTel.length >= 8) {
      valueTel = `${valueTel.slice(0, 3)}-${valueTel.slice(
        3,
        7
      )}-${valueTel.slice(7, 11)}`;
    } else if (valueTel.length >= 4) {
      valueTel = `${valueTel.slice(0, 3)}-${valueTel.slice(3, 7)}`;
    }

    // Update the field value using your form library (step3Form.setFieldValue)
    step1Form.setFieldsValue({ companyPersonTel: valueTel });
  };
  const handleInputUserTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueTel = e.target.value;

    // Remove all non-digit characters
    valueTel = valueTel.replace(/\D/g, "");

    // Apply the format: XXX-XXXX-XXXX
    if (valueTel.length >= 8) {
      valueTel = `${valueTel.slice(0, 3)}-${valueTel.slice(
        3,
        7
      )}-${valueTel.slice(7, 11)}`;
    } else if (valueTel.length >= 4) {
      valueTel = `${valueTel.slice(0, 3)}-${valueTel.slice(3, 7)}`;
    }

    // Update the field value using your form library (step3Form.setFieldValue)
    step3Form.setFieldsValue({ userTel: valueTel });
  };

  const selectedUserBirthDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    // @ts-ignore
    setIsUserBirthDate(dateString);
    step3Form.setFieldsValue({ userBirth: dateString });
  };

  return (
    <Wrapper>
      <_topbar style={{ margin: 0, padding: 0 }} />
      <WholeWrapper bgColor={colorConfigs.basic.white} height={`90vh`}>
        <Wrapper
          dr={`row`}
          shadow={`rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`}
          width={`90%`}
          height={`800px`}
          radius={`10px`}
        >
          <Wrapper
            width={width < 700 ? `100%` : `50%`}
            height={width < 700 ? `40%` : `100%`}
            // bgImg={`radial-gradient(ellipse farthest-corner at 0 140%, #FAD02E 0%, #EAB543 30%, #F76C6C 60%, #FF9A8B 100%)`}
            radius={width < 700 ? `10px 10px 0 0` : `10px 0 0 10px`}
          >
            <Wrapper>
              <Image
                src={assets.images.hejp_logo}
                style={{
                  margin: "10px auto", // 가운데 정렬을 위해 왼쪽 및 오른쪽 여백을 자동으로 설정합니다.
                  width: "30%", // 이미지 가로 크기를 30%로 설정합니다.
                  height: "auto", // 이미지의 세로 크기를 자동으로 조정합니다.
                }}
              />
              <Wrapper fontSize={width < 1350 ? `16px` : `20px`} dr={`row`}>
                <SpanText color={colorConfigs.basic.black} boldFont>
                  {t("span_text_title")}
                </SpanText>
                <SpanText
                  color={colorConfigs.basic.black}
                  style={{ marginLeft: 30 }}
                  boldFont
                >
                  {t("span_text_content")}
                </SpanText>
              </Wrapper>
              <SpanText
                color={colorConfigs.basic.black}
                style={{ marginTop: 30 }}
                boldFont
              >
                {t("span_text_description")}
              </SpanText>
            </Wrapper>
          </Wrapper>
          <Wrapper
            width={width < 700 ? `100%` : `50%`}
            height={width < 700 ? `85%` : `100%`}
            radius={width < 700 ? `0 0 10px 10px` : `0 10px 10px 0`}
            padding={width < 700 ? `0 0 20px 0` : `30px 0 50px 0`}
            overflowY={"auto"}
          >
            <Wrapper
              width={`90%`}
              ju={`space-between`}
              height={`100%`}
              overflowY={"auto"}
            >
              <Wrapper>
                <Wrapper margin={width < 700 ? `20px 0 0px 0` : `20px 0 50px`}>
                  <Steps current={currentStep}>
                    <Steps.Step title={t("step_two")} />
                    <Steps.Step title={t("step_three")} />
                  </Steps>
                </Wrapper>

                {
                  /**
                   * 로그인 정보 step2 , currentStep = 0
                   * */
                  currentStep === 0 ? (
                    <Form
                      style={{ width: `100%` }}
                      form={step2Form}
                      onFinish={nextStep}
                    >
                      <Text>{t("text_uid")}</Text>
                      {isIdCheck != null &&
                        (isIdCheck ? (
                          <Text
                            style={{
                              marginLeft: 10,
                              color: "blue",
                            }}
                          >
                            {t("result_uid_success")}
                          </Text>
                        ) : (
                          <Text type="danger" style={{ marginLeft: 10 }}>
                            {t("result_uid_already")}
                          </Text>
                        ))}

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              t("input_rule_rq_uid") || "Please enter your ID.",
                          },
                          {
                            pattern:
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                            message:
                              t("input_rule_pt_uid") ||
                              "The email format is incorrect.",
                          },
                        ]}
                        name={`userId`}
                      >
                        <Row gutter={[16, 16]} style={{ width: "100%" }}>
                          <Col xl={20}>
                            <Input
                              id={"uidInput"}
                              size="large"
                              bordered={false}
                              style={{
                                borderBottom: `2px solid ${Theme.basicTheme_C}`,
                              }}
                              placeholder={
                                t("input_rule_ph_uid") ||
                                "Please enter your ID."
                              }
                            />
                          </Col>
                          <Col xl={4}>
                            <Button
                              onClick={() => postIdCheckStatus()}
                              style={{
                                borderBottom: `2px solid ${Theme.basicTheme_C}`,
                              }}
                            >
                              {t("input_ph_uid_validate")}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>

                      <Text>{t("text_password")}</Text>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t("input_rule_rq_password") || "",
                          },
                          {
                            pattern:
                              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{4,}$/,
                            message: (
                              <Wrapper al={"flex-start"}>
                                {t("input_rule_pt1_password")}
                                <br />- {t("input_rule_pt2_password")}
                                <br />- {t("input_rule_pt3_password")}
                                <br />- {t("input_rule_pt4_password")}
                                <br />- {t("input_rule_pt5_password")}
                                <br />- {t("input_rule_pt6_password")}
                              </Wrapper>
                            ),
                          },
                        ]}
                        name={`password`}
                      >
                        <Input.Password
                          size="large"
                          bordered={false}
                          style={{
                            borderBottom: `2px solid ${Theme.basicTheme_C}`,
                          }}
                          placeholder={t("input_rule_ph_password") || ""}
                        />
                      </Form.Item>
                      <Text>{t("text_password_check")}</Text>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t("input_rule_rq_password_check") || "",
                          },
                          {
                            pattern:
                              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{4,}$/,
                            message: t("input_rule_pt_password_check") || "",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t("input_rule_password_check") || "")
                              );
                            },
                          }),
                        ]}
                        name={`passwordCheck`}
                      >
                        <Input.Password
                          size="large"
                          bordered={false}
                          style={{
                            borderBottom: `2px solid ${Theme.basicTheme_C}`,
                          }}
                          placeholder={t("input_rule_ph_password_check") || ""}
                        />
                      </Form.Item>
                    </Form>
                  ) : null
                }
                {currentStep === 0 ? (
                  <Wrapper dr={`row`} style={{ marginTop: 30 }}>
                    <Button
                      size="large"
                      type={`primary`}
                      style={{
                        width: `50px`,
                        margin: `0 10px 0 0`,
                        transition: `0.5s`,
                      }}
                      htmlType={`submit`}
                      onClick={prevStep}
                      ghost={true}
                    >
                      <MdOutlineArrowBack />
                    </Button>

                    <Button
                      size="large"
                      type={`primary`}
                      htmlType="submit"
                      style={{
                        width: `calc(100% - 50px - 10px)`,
                        transition: `0.5s`,
                      }}
                      onClick={() => {
                        step2Form.submit();
                      }}
                    >
                      {t("btn_next")}
                    </Button>
                  </Wrapper>
                ) : null}

                {currentStep === 1 ? (
                  <Form
                    style={{ width: `100%` }}
                    form={step3Form}
                    onFinish={nextStep}
                  >
                    <Text type={"danger"}>{t("text_username")}</Text>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("input_rule_rq_username") || "",
                        },
                      ]}
                      name={`userName`}
                    >
                      <Input
                        placeholder={t("input_rule_ph_username") || ""}
                        size="large"
                        bordered={false}
                        style={{
                          borderBottom: `2px solid ${Theme.basicTheme_C}`,
                        }}
                      />
                    </Form.Item>
                    <Text>{t("text_birthdate")}</Text>
                    <Form.Item
                      name="userBirth"
                      rules={[
                        {
                          required: true,
                          message: t("input_rule_rq_birthdate") || "",
                        },
                        {
                          pattern: /^\d{4}-\d{2}-\d{2}$/,
                          message: t("input_rule_pt_birthdate") || "", // This message will be displayed if the input doesn't match the pattern
                        },
                      ]}
                    >
                      <Row gutter={[16, 16]} style={{ width: "100%" }}>
                        <Col xl={20}>
                          <Input
                            value={isUserBirthDate}
                            onChange={handleInputUserBirthChange}
                            placeholder={t("input_rule_ph_birthdate") || ""}
                            size="large"
                            bordered={false}
                            style={{
                              borderBottom: `2px solid ${Theme.basicTheme_C}`,
                            }}
                          />
                        </Col>
                        <Col xl={4}>
                          <DatePicker
                            className="icon-only-datepicker"
                            onChange={selectedUserBirthDate}
                            style={{
                              borderBottom: `2px solid ${Theme.basicTheme_C}`,
                            }}
                            suffixIcon={<BsCalendar3Week />}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <Text type={"danger"}>{t("text_email")}</Text>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("input_rule_rq_email") || "",
                        },
                        {
                          pattern:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                          message: t("input_rule_pt_email") || "",
                        },
                      ]}
                      name={`userEmail`}
                    >
                      <Input
                        size="large"
                        bordered={false}
                        placeholder={t("input_rule_ph_email") || ""}
                        style={{
                          borderBottom: `2px solid ${Theme.basicTheme_C}`,
                        }}
                      />
                    </Form.Item>
                    <Text type={"danger"}>{t("text_tel")}</Text>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("input_rule_rq_tel") || "",
                        },
                        {
                          pattern: /^\d{3}-\d{4}-\d{4}$/,
                          message: t("input_rule_pt_tel") || "", // This message will be displayed if the input doesn't match the pattern
                        },
                      ]}
                      name={`userTel`}
                    >
                      <Input
                        placeholder={t("input_rule_ph_tel") || ""}
                        size="large"
                        bordered={false}
                        onChange={handleInputUserTelChange}
                        style={{
                          borderBottom: `2px solid ${Theme.basicTheme_C}`,
                        }}
                      />
                    </Form.Item>
                    <Text>{t("text_address")}</Text>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                          message: t("input_rule_rq_address") || "",
                        },
                      ]}
                      name={`userRoadAddress`}
                    >
                      <Input
                        placeholder={t("input_rule_ph_address") || ""}
                        size="large"
                        bordered={false}
                        value={selectedAddress}
                        style={{
                          borderBottom: `2px solid ${Theme.basicTheme_C}`,
                        }}
                        onClick={loadGoogleLayout}
                      />
                      <div ref={postcodeRef}></div>
                    </Form.Item>

                    {/* Google Map Modal */}
                    {isGoogleMapVisible && (
                        <Wrapper
                            style={{
                              position: 'fixed',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              zIndex: 1000,
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }}
                        >
                          <Card
                              title="구글 지도 검색"
                              style={{width: 800, height: '80%', borderRadius: '10px'}}
                          >
                            <GoogleMapCM
                                address={selectedAddress}
                                onLocationSelect={handleMapSelect} // Pass this to handle location select
                            />
                            {/*<Button onClick={() => setGoogleMapVisible(false)}>닫기</Button>*/}
                          </Card>
                        </Wrapper>
                    )}

                    <Text>{t("text_address_detail")}</Text>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                          message: t("input_rule_rq_address_detail") || "",
                        },
                      ]}
                      name={`userDetailAddress`}
                    >
                      <Input
                        placeholder={t("input_rule_ph_address_detail") || ""}
                        size="large"
                        bordered={false}
                        value={selectedDetailAddress}
                        style={{
                          borderBottom: `2px solid ${Theme.basicTheme_C}`,
                        }}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={12} >
                        <Form.Item
                            rules={[
                              {
                                required: false,
                                message: t("input_rule_rq_address") || "",
                              },
                            ]}
                            name={`userLatitude`}
                        >
                          <Input
                              placeholder={t("input_rule_ph_address") || ""}
                              size="large"
                              bordered={false}
                              style={{
                                borderBottom: `2px solid ${Theme.basicTheme_C}`,
                              }}
                              value={selectedLatitude}
                              disabled={true}
                              type={"hidden"}

                          />
                        </Form.Item>
                      </Col>
                    <Col span={12} >
                    <Form.Item
                        rules={[
                          {
                            required: false,
                            message: t("input_rule_rq_address") || "",
                          },
                        ]}
                        name={`userLongitude`}
                    >
                      <Input
                          placeholder={t("input_rule_ph_address") || ""}
                          size="large"
                          bordered={false}
                          value={selectedLongitude}
                          style={{
                            borderBottom: `2px solid ${Theme.basicTheme_C}`,
                          }}
                          disabled={true}
                          type={"hidden"}

                      />
                    </Form.Item>
                    </Col>
                    </Row>
                  </Form>
                ) : null}

                {currentStep === 1 ? (
                  <Wrapper dr={`row`}>
                    <Button
                      size="large"
                      type={`primary`}
                      style={{
                        width: `50px`,
                        margin: `0 10px 0 0`,
                        transition: `0.5s`,
                      }}
                      htmlType={`submit`}
                      onClick={prevStep}
                      ghost={true}
                    >
                      <MdOutlineArrowBack />
                    </Button>

                    <Button
                      size="large"
                      type={`primary`}
                      style={{
                        width:
                          currentStep > 0 ? `calc(100% - 50px - 10px)` : `100%`,
                        transition: `0.5s`,
                      }}
                      onClick={() => step3Form.submit()}
                    >
                      {t("btn_final")}
                    </Button>
                  </Wrapper>
                ) : null}
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </WholeWrapper>

    </Wrapper>
  );
};

export default _moduleRegister;
