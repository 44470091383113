import React, { FC, useCallback } from "react";
import { Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {RsWrapper, Wrapper} from "../../commonComponents";
import colorConfigs from "../../../../configs/colorConfigs";
import useWidth from "../../useWidth";
import appRoutes from "../../../../routes/appRoutes";
import {loginResponseState} from "../../../../state/userinfo";
import {menuSelectedKeyState} from "../../../../state/menuState";
import {RouteType} from "../../../../routes/config";  // Ensure RouteType is properly exported

const _topMainMenu: FC = () => {
  const {t} = useTranslation("sidebar");
  const width = useWidth();
  const navigate = useNavigate();
  const [loginResponse, setLoginResponse] = useRecoilState(loginResponseState);
  const [selectedKey, setSelectedKey] = useRecoilState(menuSelectedKeyState);

  const onClick = useCallback((key: string) => {
    setSelectedKey(key);
    navigate(key);
  }, [navigate, setSelectedKey]);

  const renderMenuItems = (routes: RouteType[]): React.ReactNode => {
    return routes.map((route) => {
      if (route.hidden || (route.auth && !route.auth.includes(loginResponse?.loginType as string)) || (route.menuType && !route.menuType.includes(loginResponse?.loginType as string))) {
        return null;
      }
      if (route.child) {
        return (
            <Menu.SubMenu
                key={route.path}
                title={route.sidebarProps?.displayText && t(route.sidebarProps.displayText)}
                icon={route.sidebarProps?.icon}
            >
              {renderMenuItems(route.child)}
            </Menu.SubMenu>
        );
      }

      return (
          <Menu.Item
              key={route.path}
              icon={route.sidebarProps?.icon}
              onClick={() => onClick(route.path)}
          >
            {route.sidebarProps?.displayText && t(route.sidebarProps.displayText)}
          </Menu.Item>
      );
    }).filter(item => item !== null);
  };

  return (
      <Wrapper
          zIndex="99"
          top="0"
          right="0"
          shadow="unset"
          bgColor={colorConfigs.mainBg}
          color={colorConfigs.basic.black}
          dr="row"
          ju="flex-start"
          height={width < 700 ? "64px" : "70px"}
      >
        <Menu
            selectedKeys={[selectedKey]}
            mode="horizontal"
            style={{ backgroundColor: colorConfigs.basic.lightGray, width: "100vw" }}
        >
          {renderMenuItems(appRoutes)}
        </Menu>
      </Wrapper>
  );
};

export default _topMainMenu;
