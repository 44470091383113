import { useSetRecoilState, useRecoilValue } from "recoil";
import assets from "../../../assets";
import colorConfigs from "../../../configs/colorConfigs";
import sizeConfigs from "../../../configs/sizeConfigs";
import appRoutes from "../../../routes/appRoutes";
import { Badge, Divider, Menu, Typography } from "antd";

import { Image, Text, Wrapper } from "../commonComponents";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteType } from "../../../routes/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isToggleAtomState } from "../../../state/systemInit";
import { subRoutes } from "../../../routes/subRoutes";
import { MenuOutlined, MinusOutlined } from "@ant-design/icons";
// const Divider = styled(Wrapper)`
//   margin: 8px 0;
//   height: 1px;
//   background: #e0e0e0;
// `;
const firstSegment = (txt: string) => txt.split("/")[1];
const Item = styled(Wrapper)`
  height: 40px;
  padding: 8px 12px;
  border-radius: 8px;
  color: #616161;

  &.current {
    color: #000;
    background: #eee;
  }
  &:hover {
    background: #eee;
  }
  cursor: pointer;
`;
interface MenuItemType {
  movePage: Function;
  path: string;
  isCurrent: Boolean;
  value?: string;
  isDivider: Boolean;
}
interface MenuToggleType {
  items: RouteType[];
}

const MenuItem: React.FC<MenuItemType> = ({
  movePage,
  path,
  isCurrent,
  value,
  isDivider,
}) => {
  const { t } = useTranslation("subRoutes");
  const txt = t(firstSegment(path));
  return (
    <>
      {isDivider && (
        <Wrapper height={`40px`}>
          <Divider orientation="left" style={{ fontSize: `14px`, margin: `0` }}>
            {/* {txt} */}
          </Divider>
        </Wrapper>
      )}
      <Item
        onClick={() => movePage(path)}
        height={`40px`}
        padding={`8px 12px`}
        className={isCurrent ? "current" : "not_current"}
        al={`flex-start`}
      >
        {value}
      </Item>
    </>
  );
};

const MenuToggle: React.FC<MenuToggleType> = ({ items }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const movePage = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("subRoutes");
  return (
    <Wrapper>
      <Wrapper onClick={() => setIsOpen((prev) => !prev)}>
        <Item dr={`row`} ju={`space-between`}>
          <Wrapper width={`calc(100% - 30px)`}>
            <Divider style={{ margin: `0` }} />
          </Wrapper>
          {!isOpen ? (
            <MenuOutlined style={{ fontSize: `18px` }} />
          ) : (
            <MinusOutlined style={{ fontSize: `18px` }} />
          )}
        </Item>
      </Wrapper>
      {isOpen &&
        items.map((route, idx) => {
          const txt = route.sidebarProps?.displayText
            ? t(route.sidebarProps?.displayText)
            : "";
          return (
            <MenuItem
              key={route.path}
              movePage={movePage}
              path={route.path}
              value={txt}
              // isDivider={items.length === idx + 1}
              isDivider={false}
              isCurrent={location.pathname === route.path}
            />
          );
        })}
    </Wrapper>
  );
};

type tempDataType = {
  [key: string]: tempDataType2;
};

type tempDataType2 = {
  [key: string]: RouteType[];
};

const _menubar = () => {
  const { t } = useTranslation("subRoutes");
  const location = useLocation();
  const movePage = useNavigate();
  const [currentOpen, setCurrentOpen] = useState<string | null>(null);
  const [menuItem, setMenuItem] = useState<tempDataType2>({});

  const getSplitData = (pathname: string) => {
    const splitData = pathname.split("/");
    return { firstValue: splitData[1], secondValue: splitData[2] };
  };

  useEffect(() => {
    // const currentSplitData = getSplitData(location.pathname);
    const groupedRoutes: tempDataType = {};
    subRoutes.forEach((route) => {
      const first = getSplitData(route.path).firstValue;
      const second = getSplitData(route.path).secondValue;
      if (!groupedRoutes[first]) {
        groupedRoutes[first] = {};
      }
      if (!groupedRoutes[first][second]) {
        if (second) {
          groupedRoutes[first][second] = [];
        }
      }
      second && groupedRoutes[first][second].push(route);
    });

    setMenuItem(groupedRoutes[location.pathname.split("/")[1]]);
  }, [location.pathname]);

  useEffect(() => {
    const currentData = getSplitData(location.pathname);
    console.log(menuItem);
    console.log(Object.keys(menuItem[currentData.firstValue]));
  }, [location.pathname]);

  return (
    <Wrapper
      width={`320px`}
      padding={`0 0 0 50px`}
      height={`calc(100vh - 120px - 70px - 65px)`}
      ju={`flex-start`}
      bgColor=""
      // al={`center`}
    >
      {Object.keys(menuItem).map((mdata, index) => {
        const isCurrent = mdata === getSplitData(location.pathname).secondValue;
        if (isCurrent) {
          return menuItem[mdata].map((data, idx) => {
            const txt = data.sidebarProps?.displayText
              ? t(data.sidebarProps?.displayText)
              : "";
            return (
              <MenuItem
                key={data.path}
                movePage={movePage}
                path={data.path}
                value={txt}
                isDivider={idx === 0}
                // isDivider={menuItem[mdata].length === idx + 1}
                // isDivider={false}
                isCurrent={location.pathname === data.path}
              />
            );
          });
        } else {
          return <MenuToggle key={"t_" + index} items={menuItem[mdata]} />;
          // <MenuItem
          //   key={data.path}
          //   movePage={movePage}
          //   path={data.path}
          //   value={"value"}
          //   isDivider={idx === 0}
          //   isCurrent={location.pathname === data.path}
          // />;
        }
      })}
    </Wrapper>
  );
};

export default _menubar;
