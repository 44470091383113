import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Breadcrumb,
    Layout,
    Row,
    Col,
    Divider,
    Table,
    Button,
    Popconfirm,
    Form,
    Input,
    Space, Badge, Typography, Spin, Empty, Flex, Descriptions, Card, Image, Tabs, Tag, Watermark, Collapse,
} from "antd";
import type {FormInstance} from "antd/es/form";
import {SpanText, Wrapper} from "../../../../components/common/commonComponents";
import colorConfigs from "../../../../configs/colorConfigs";
import TopoJsonDetailMap from "../../../../components/common/Map/topoJsonDetailMap";
import TopoJsonGlobalDetailMap from "../../../../components/common/Map/topoJsonGlobalDetailMap";
import {useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";
import {
    getDashboardDetailDataSearch
} from "../../../../pages/_main_contractor/_dashboardMgmt/_service/_admin/service";
import {decryptToken} from "../../../../utils/tokenManagement";
import {useCookies} from "react-cookie";
import ZoomSlider from "../../../../components/common/Map/ZoomSilder";
import {IoIosMore} from "react-icons/io";
import useEmblaCarousel from "embla-carousel-react";
import {TwitterOutlined} from "@ant-design/icons";

const {Text} = Typography;

const {Content} = Layout;

// Editable Table Context
const EditableContext = React.createContext<FormInstance<any> | null>(null);

// Editable Row
const EditableRow: React.FC<{ index: number }> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

// Editable Cell
const EditableCell: React.FC<{
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: any;
    handleSave: (record: any) => void;
}> = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = React.useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

interface DashboardCategoryResponse {
    reactKey: string;
    categoryValue: string;
    categoryColor: string;
    cntRef: string;
}

interface DashboardTravelHistoryDetailResponse {
    reactKey: string;
    travelHistoryId: string;
    seq: string;
    userId: string;
    thTitle: string | null;
    thSaddress: string | null;
    thSlatitude: string | null;
    thSlongitude: string | null;
    thEaddress: string;
    thEdetailAddress: string;
    thElatitude: string;
    thElongitude: string;
    thType: string;
    thEzoneKeyword: string;
    thGubun: string;
    thContent: string;
    thStartDatetime: string;
    thEndDatetime: string;

}

interface DashboardTravelHistoryDetailImgResponse {
    reactKey: string | null;
    thTitle: string | null;
    thEaddress: string | null;
    travelHistoryAttId: string | null;
    travelHistoryAttSeq: string | null;
    travelHistoryId: string | null;
    travelHistorySeq: string | null;
    thImgFileName: string | null;
    thImgFileUrl: string | null;
    userId: string | null;
    thImgFileDir: string | null;
    recordCnt: string | null;
    createdDatetime: string | null;
    modifiedDatetime: string | null;
}

interface IResult {
    userId: string;
    dashboardTravelHistoryDetailResponses: DashboardTravelHistoryDetailResponse[];
    dashboardTravelHistoryDetailImgResponses: DashboardTravelHistoryDetailImgResponse[];
}

interface IResponseProps {
    result: IResult;
    resultCode: number;
    resultMsg: string;
}

const _dashboardFsgIndex: React.FC = () => {
    const [isParams] = useSearchParams();
    const isDsbType = isParams.get('isDsbType');


    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
    const token = cookies.accessToken;
    const [searchParams, setSearchParams] = useState({
        isDsbType: isDsbType,
    });

    // const {data, isLoading, isError, refetch} = useQuery<IResponseProps>(
    //     ["data", searchParams],
    //     () => getDashboardDetailDataSearch(decryptToken(token), setCookie, removeCookie, searchParams.travelHistoryId || ""),
    //     {refetchOnWindowFocus: true}
    // );



    const [dataSource, setDataSource] = useState([
        {
            key: "0",
            rowNumber: 1,
            name: "Home",
            address: "London, Park Lane no. 0",
        },
        {
            key: "98",
            rowNumber: 2,
            name: "목적지",
            address: "London, Park Lane no. 1",
        },
        {
            key: "99",
            rowNumber: 3,
            name: "Home",
            address: "London, Park Lane no. 1",
        },
    ]);
    const [count, setCount] = useState(100); // 기본적으로 추가되는 키 값은 100부터 시작

    const updateRowNumbers = (data: any[]) => {
        return data.map((item, index) => ({
            ...item,
            rowNumber: index + 1,
        }));
    };

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(updateRowNumbers(newData));
    };

    const handleAdd = () => {
        const newData = {
            key: count.toString(),
            name: `Edward King ${count}`,
            address: `London, Park Lane no. ${count}`,
        };

        const updatedData = [...dataSource, newData];
        setDataSource(updateRowNumbers(updatedData));
        setCount((prevCount) => prevCount + 1); // count 증가
    };

    const handleAddBelow = (key: string) => {
        const newData = {
            key: count.toString(),
            name: `New Row ${count}`,
            address: `New Address ${count}`,
        };

        const index = dataSource.findIndex((item) => item.key === key);
        if (index !== -1) {
            const updatedData = [
                ...dataSource.slice(0, index + 1),
                newData,
                ...dataSource.slice(index + 1),
            ];
            setDataSource(updateRowNumbers(updatedData));
            setCount((prevCount) => prevCount + 1);
        }
    };

    const handleMoveUp = (key: string) => {
        const index = dataSource.findIndex((item) => item.key === key);
        if (index > 0) {
            const newData = [...dataSource];
            const temp = newData[index - 1];
            newData[index - 1] = newData[index];
            newData[index] = temp;
            setDataSource(updateRowNumbers(newData));
        }
    };

    const handleMoveDown = (key: string) => {
        const index = dataSource.findIndex((item) => item.key === key);
        if (index < dataSource.length - 1) {
            const newData = [...dataSource];
            const temp = newData[index + 1];
            newData[index + 1] = newData[index];
            newData[index] = temp;
            setDataSource(updateRowNumbers(newData));
        }
    };

    const handleSave = (row: any) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const {Panel} = Collapse;
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [mainViewportRef, emblaMain] = useEmblaCarousel({skipSnaps: false});
    const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
        containScroll: "keepSnaps",
        dragFree: true,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = () => {
        if (!emblaMain || !emblaThumbs) return;
        setSelectedIndex(emblaMain.selectedScrollSnap());
        emblaThumbs.scrollTo(emblaMain.selectedScrollSnap());
    };

    const scrollTo = (index: number) => {
        if (!emblaMain || !emblaThumbs) return;
        emblaMain.scrollTo(index);
    };

    useEffect(() => {
        if (!emblaMain) return;
        emblaMain.on("select", onSelect);
        onSelect();
    }, [emblaMain]);

    const columns = [
        {
            title: "Row Number",
            dataIndex: "rowNumber",
            editable: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            editable: true,
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_: any, record: { key: string }) => (
                <Space>
                    <Popconfirm title="Sure to append?" onConfirm={() => handleAddBelow(record.key)}>
                        <Typography.Link onClick={() => handleMoveUp(record.key)}>
                            <Badge color={colorConfigs.table_status.forwarding} text="Append"
                                   style={{color: colorConfigs.table_status.forwarding}}/>
                        </Typography.Link>
                    </Popconfirm>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <Typography.Link onClick={() => handleMoveUp(record.key)}>
                            <Badge color={colorConfigs.table_status.ban} text="Delete"
                                   style={{color: colorConfigs.table_status.ban}}/>
                        </Typography.Link>
                    </Popconfirm>
                    <Typography.Link onClick={() => handleMoveUp(record.key)}>
                        <Badge color={colorConfigs.table_status.actionColor} text="Move Up"
                               style={{color: colorConfigs.table_status.actionColor}}/>
                    </Typography.Link>
                    <Typography.Link onClick={() => handleMoveDown(record.key)}>
                        <Badge color={colorConfigs.table_status.actionColor} text="Move Down"
                               style={{color: colorConfigs.table_status.actionColor}}/>
                    </Typography.Link>
                </Space>
            ),
        },
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });


    const [defaultActiveKey, setDefaultActiveKey] = useState("historyInfoList");
    const {TabPane} = Tabs;
    const [keyAttact, setKeyAttact] = useState<boolean>(false); // Default zoom level

    const handleTabChange = (key: React.SetStateAction<string>) => {
        setDefaultActiveKey(key);
        setKeyAttact(false);
    };
    // 데이터 조회 로딩
    // if (isLoading) {
    //     return <Spin/>;
    // }
    // // 데이터 조회 에러
    // if (isError) {
    //     return (
    //         <Content
    //             style={{
    //                 padding: 14,
    //                 margin: 0,
    //                 minHeight: 280,
    //                 // background: Theme.basicTheme_C,
    //             }}
    //         >
    //             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    //         </Content>
    //     );
    // }
    return (
        <Wrapper>
            <Layout style={{padding: "0 10px 100px", width: "100%", height: "100%"}}>
                <Breadcrumb style={{margin: "0px 0px 10px 0px"}}>
                    <Breadcrumb.Item>FITA-GRAM</Breadcrumb.Item>
                    <Breadcrumb.Item>{isDsbType}</Breadcrumb.Item>
                </Breadcrumb>
                <Divider/>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>

                    </Col>
                    <Divider></Divider>

                </Row>
            </Layout>
        </Wrapper>
    )
        ;
};

export default _dashboardFsgIndex;
