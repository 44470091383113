import {
    Badge,
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    message,
    Card,
    FloatButton, Checkbox, Space, Switch,
} from 'antd';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {MdEditLocationAlt} from 'react-icons/md';
import TextArea from "antd/es/input/TextArea";
import {formInstance} from "../../../configs/axiosConfig";
import {decryptToken} from "../../../utils/tokenManagement";
import useClickAway from './useClickAway';
import Theme from "../Theme";
import {Wrapper} from "../commonComponents";
import GoogleMapCM from "../Map/googleMap";
import SelectMap from "../Map/selectMap";

interface HistoryAppendFormProps {
    token: string;
    refetch: () => void;
    paVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>; // 상태 변경 함수

}

interface PreviewFile {
    uid: string;
    name: string;
    url: string;
}

const HistoryAppendForm = (props: HistoryAppendFormProps) => {
    const [isGoogleMapVisible, setGoogleMapVisible] = useState(false); // To control Google Map modal visibility
    const [isMapSynced, setIsMapSynced] = useState(false);

    const id = "daum-postcode"; // script가 이미 rending 되어 있는지 확인하기 위한 ID
    const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
    const [appendForm] = Form.useForm();
    const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLDivElement>(null);
    const [isEndDateSynced, setIsEndDateSynced] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedAddress, setSelectedAddress] = useState("");
    // const [selectedUserLatitude, setSelectedUserLatitude] = useState("");
    // const [selectedUserLongitude, setSelectedUserLongitude] = useState("");
    const [selectedZoneCode, setSelectedZoneCode] = useState(0);
    const [selectedRoadAddress, setSelectedRoadAddress] = useState("");
    const [selectedDetailAddress, setSelectedDetailAddress] = useState("");
    const [selectedLatitude, setSelectedLatitude] = useState("");
    const [selectedLongitude, setSelectedLongitude] = useState("");
    const [isMapType, setIsMapType] = useState("");
    const postcodeRef = useRef(null);
    const [selectedTypeGubun, setSelectedTypeGubun] = useState("DOMESTIC");



    const handleMapSelect = (lat: string, lng: string, address: string, roadAddress: string) => {
        setSelectedLatitude(lat);
        setSelectedLongitude(lng);
        setSelectedAddress(address);
        setSelectedRoadAddress(roadAddress)
        setGoogleMapVisible(false); // Close the modal after selecting a location
    };


    useEffect(() => {
        setFormVisible(true)
    }, [props.paVisible]);

    useEffect(() => {
        const isAlready = document.getElementById(id);

        if (!isAlready) {
            const script = document.createElement("script");
            script.src = src;
            script.id = id;
            document.body.append(script);
        }
    }, []);


    useEffect(() => {
        appendForm.setFieldsValue({
            userLatitude: selectedLatitude,
            userLongitude: selectedLongitude,
        });
    }, [selectedLatitude, selectedLongitude]);

    useEffect(() => {
        appendForm.setFieldsValue({
            userAddress: selectedAddress,
            userRoadAddress: selectedRoadAddress,
            userZone: selectedZoneCode,
        });
    }, [selectedAddress, selectedZoneCode, selectedRoadAddress]);

    const fileChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const previewArray: PreviewFile[] = [];
            Array.from(files).forEach(file => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = (e) => {
                    const result = e?.target?.result as string;
                    previewArray.push({uid: file.name, name: file.name, url: result});
                    if (previewArray.length === files.length) {
                        setPreviewFiles(previewArray);
                    }
                };
            });
        }
    }, []);


    const initForm = () => {
        setFormVisible(!formVisible);
        appendForm.setFieldsValue({
            historyStartDate: null,
            historyEndDate: null,
        });
    };

    const resetAppendForm = () => {
        props.setIsModalVisible?.(false || false);
        appendForm.resetFields();
        setPreviewFiles([]);
        setFormVisible(false);
    };

    const fileuploadApi = () => {
        appendForm.validateFields().then(values => {
            const formData = new FormData();
            formData.append('userHistoryTitle', values.userHistoryTitle);
            formData.append('locationName', values.userRoadAddress);
            formData.append('locationCode', values.userZone);
            formData.append('locationLatitude', values.userLatitude);
            formData.append('locationLongitude', values.userLongitude);
            formData.append('locationDetail', values.userDetailAddress);
            formData.append('historyContent', values.historyContent);
            formData.append('historyStartDate', startDate);
            formData.append('historyEndDate', endDate);
            formData.append('historyType', values.historyType);
            formData.append('historyTypeGubun', values.selectedTypeGubun);
            formData.append('isMapType', isMapType);
            formData.append('historyZoneKeyword', values.selectedZoneKeyword || "");

            const files = fileRef.current?.files;
            if (files) {
                Array.from(files).forEach(file => {
                    formData.append('files', file);
                });
            }

            formInstance
                .post(`/api/v1/mDashboard/admin/append/user/history`, formData, {
                    withCredentials: true, headers: {
                        'Authorization': `Bearer ${decryptToken(props.token)}`
                    }
                })
                .then(response => {
                    message.success('Upload success');
                    resetAppendForm();
                    props.setIsModalVisible?.(false || false);

                    props.refetch();

                })
                .catch(error => {
                    console.error('Upload error:', error);
                    message.error('Upload error: ' + (error.response?.data?.message || 'Unknown error'));
                })
                .finally(() => {
                    setUploading(false);
                    setStartDate('');
                    setEndDate('');
                });
        }).catch(errorInfo => {
            console.error('Failed:', errorInfo);
            message.error('Validation Failed: ' + errorInfo.errorFields.map((field: any) => field.name).join(', '));
            setUploading(false);
        });

    };

    const loadGoogleLayout = () => {
        /**
         * 구글 주소 팝업 Init
         * */
        setIsMapType("");
        setSelectedAddress(""); // 선택한 주소를 상태에 저장
        setSelectedZoneCode(0);
        setSelectedRoadAddress("");
        setSelectedDetailAddress("");
        setSelectedLatitude("");
        setSelectedLongitude("");

        setIsMapType("GOOGLE");
        setGoogleMapVisible(true); // Show Google Map modal

    };


    const loadLayout = () => {
        /**
         * 카카오 주소 팝업 Init
         * */
        setIsMapType("");
        setSelectedAddress(""); // 선택한 주소를 상태에 저장
        setSelectedZoneCode(0);
        setSelectedRoadAddress("");
        setSelectedDetailAddress("");
        setSelectedLatitude("");
        setSelectedLongitude("");

        setIsMapType("KAKAO");
        appendForm.setFieldsValue({
            userDetailAddress: selectedDetailAddress,
        });

        window.daum.postcode.load(() => {
            new window.daum.Postcode({
                oncomplete: function (data) {
                    console.log("location: " + JSON.stringify(data));
                    // const fullAddress = data.jibunAddress;
                    const fullAddress = data.address;
                    const zoneCode = data.zonecode;
                    const roadAddress = data.roadAddress;

                    setSelectedAddress(fullAddress); // 선택한 주소를 상태에 저장
                    setSelectedZoneCode(zoneCode);
                    setSelectedRoadAddress(roadAddress);

                    fnGetLatLng(fullAddress);
                },
            }).open();
        });
    };


    const fnGetLatLng = (address: any) => {
        const geocoder = new window.kakao.maps.services.Geocoder();

        geocoder.addressSearch(address, function (result: {
            y: any;
            x: any;
        }[], status: string) {
            if (status === window.kakao.maps.services.Status.OK) {
                const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                const x = coords.getLat()
                const y = coords.getLng()
                setSelectedLatitude(x);
                setSelectedLongitude(y);
            } else {
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    useEffect(() => {
        // useEffect의 콜백 함수 내부에서 setFieldsValue 호출
        // setIsMapSynced(true);
        appendForm.setFieldsValue({
            selectedTypeGubun: isMapSynced ? "GLOBAL" : "DOMESTIC",
        });
    }, [isMapSynced]); // appendForm과 isMapSynced를 의존성 배열에 추가

    return (
        <>
            <FloatButton
                type="primary"
                style={{right: 24}}
                icon={<MdEditLocationAlt/>}
                onClick={() => initForm()}
            />

            {formVisible && (
                <Wrapper
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }}
                >
                    <Card
                        ref={formRef}
                        title={<span style={{
                            color: '#e8edef',
                            fontWeight: 'bold'
                        }}>* 【GM-0001】 일지 등록 폼.</span>} // Adding bold font for emphasis
                        headStyle={{backgroundColor: 'rgb(13,98,194)', border: 0}}
                        style={{
                            width: 600,
                            height: '80%',
                            // borderTop: '5px solid #132e3c', // Slightly thinner line for subtlety
                            // paddingTop: '16px', // Adds some padding between the top line and the content
                            borderRadius: '10px', // Adds rounded corners for a softer appearance
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a soft shadow for depth
                            overflowY: 'auto' // Enables vertical scrolling
                        }}
                    >
                        <Form
                            form={appendForm}
                            layout="vertical"
                        >
                            <Form.Item name="userHistoryTitle" label="제목: " hidden={false}
                                       rules={[{required: true, message: 'Please input the Title!'}]}>
                                <Input disabled={false}/>
                            </Form.Item>

                            <Form.Item name="gubunType" label="지역 구분">
                                <Switch checkedChildren="국내" unCheckedChildren="해외" defaultChecked
                                        onChange={(checked) => {
                                            setIsMapSynced(!checked);
                                            // appendForm.setFieldsValue({
                                            //     selectedTypeGubun: isMapSynced ? "DOMESTIC" : "GLOBAL",
                                            // });
                                        }}
                                />
                            </Form.Item>
                            <Space direction="horizontal">
                                <Form.Item
                                    label="지번 주소: "
                                    rules={[{required: true, message: 'Please input the location name!'}]}
                                    name={`userAddress`}
                                >
                                    {/*<Search placeholder="input search text" style={{ width: 300 }} />*/}
                                    <Input disabled={true}></Input>
                                </Form.Item>

                                {/*<Button onClick={() => loadLayout()}>*/}
                                {/*    카카오지도 검색*/}
                                {/*</Button>*/}
                                <Button onClick={() => loadGoogleLayout()}>
                                    구글지도 검색
                                </Button>
                            </Space>

                            <div ref={postcodeRef}></div>
                            <Form.Item name="userRoadAddress" label="도로명 주소: "
                                       rules={[{required: true, message: 'Please input the location name!'}]}>
                                <Input disabled={false}/>
                            </Form.Item>

                            <Form.Item
                                label="상세주소: "
                                rules={[{required: true, message: 'Please input the location name!'}]}
                                name={`userDetailAddress`}
                            >
                                <Input disabled={false}/>
                            </Form.Item>
                            <Form.Item name="historyType" label="카테고리 구분: "
                                       rules={[{required: true, message: 'Please select the history type!'}]}>
                                <select style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    borderColor: '#d9d9d9'
                                }}>
                                    <option value="NONE">--선택--</option>
                                    <option value="PREDICTED">PREDICTED</option>
                                    <option value="FISHING">FISHING</option>
                                    <option value="TRAVEL">TRAVEL</option>
                                    <option value="CNF">CAMPING & FISHING</option>
                                    <option value="FOOD">DELICIOUS FOOD</option>
                                </select>
                            </Form.Item>

                            <Form.Item name="userZone" label="위치코드: " hidden={true}
                                       rules={[{required: false, message: 'Please input the location code!'}]}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item name="userLatitude" label="위도: " hidden={true}
                                       rules={[{required: true, message: 'Please input the latitude!'}]}>
                                <Input disabled={false}/>
                            </Form.Item>
                            <Form.Item name="userLongitude" label="경도: " hidden={true}
                                       rules={[{required: true, message: 'Please input the longitude!'}]}>
                                <Input disabled={false}/>
                            </Form.Item>
                            <Form.Item name="historyStartDate" label="시작 일자: "
                                       rules={[{required: true, message: 'Please input the history date!'}]}>
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <Input
                                        type="date"
                                        name="startDate"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                            if (isEndDateSynced) {
                                                setEndDate(e.target.value);
                                                appendForm.setFieldsValue({historyEndDate: e.target.value});
                                            }

                                        }}
                                        style={{width: '50%'}}
                                    />
                                    <Checkbox
                                        checked={isEndDateSynced}
                                        onChange={(e) => {
                                            setIsEndDateSynced(e.target.checked);
                                            if (e.target.checked) {
                                                setEndDate(startDate);
                                                appendForm.setFieldsValue({historyEndDate: startDate});
                                            }
                                        }}
                                    >
                                        종료 일자 동일.
                                    </Checkbox>
                                </div>
                            </Form.Item>
                            <Form.Item name="historyEndDate" label="종료 일자: "
                                       rules={[{required: true, message: 'Please input the history date!'}]}>
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <Input
                                        type="date"
                                        name="endDate"
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                            appendForm.setFieldsValue({historyEndDate: e.target.value});
                                        }}
                                        disabled={isEndDateSynced}
                                        style={{width: '50%'}}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item name="selectedTypeGubun" label="구분: "
                                       rules={[{required: true, message: 'Please select the history type!'}]}>
                                {isMapSynced ? (
                                    <Input
                                        name="selectedTypeGubun"
                                        value={selectedTypeGubun}
                                        disabled={true}
                                        style={{width: '50%'}}
                                    />

                                ) : (
                                    <Input
                                        name="selectedTypeGubun"
                                        value={selectedTypeGubun}
                                        disabled={true}
                                        style={{width: '50%'}}
                                    />
                                )}
                            </Form.Item>

                            {isMapSynced ? (
                                <Form.Item name="selectedZoneKeyword" label="지역: "
                                           rules={[{required: true, message: 'Please select the history type!'}]}>
                                    <SelectMap onChange={(value) => {
                                        appendForm.setFieldsValue({
                                            selectedZoneKeyword: value,
                                        });
                                    }}></SelectMap>
                                </Form.Item>

                            ) : null}


                            <Form.Item name="historyContent" label="메모: ">
                                <TextArea rows={4} defaultValue=""/>
                            </Form.Item>
                            <Form.Item label="파일: ">
                                <input type="file" ref={fileRef} onChange={fileChangeHandler} multiple
                                       style={{display: 'none'}}/>
                                <Button onClick={() => fileRef.current?.click()}>
                                    <PlusOutlined/> Upload
                                </Button>
                                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 8}}>
                                    {previewFiles.map(file => (
                                        <div key={file.uid} style={{marginRight: 8}}>
                                            <img src={file.url} alt={file.name} style={{width: 100, height: 100}}/>
                                        </div>
                                    ))}
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={fileuploadApi} type="primary" loading={uploading}>
                                    전송
                                </Button>
                                <Button onClick={resetAppendForm} style={{marginLeft: '8px'}}>
                                    취소
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    {/* Google Map Modal */}
                    {isGoogleMapVisible && (
                        <Wrapper
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1000,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            <Card
                                title="구글 지도 검색"
                                style={{width: 800, height: '80%', borderRadius: '10px'}}
                            >
                                <GoogleMapCM
                                    address={selectedAddress}
                                    onLocationSelect={handleMapSelect} // Pass this to handle location select
                                />
                                {/*<Button onClick={() => setGoogleMapVisible(false)}>닫기</Button>*/}
                            </Card>
                        </Wrapper>
                    )}
                </Wrapper>
            )}
        </>
    );
};

export default HistoryAppendForm;
