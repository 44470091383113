import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./en";
import * as ko from "./ko";

const resources: Resource = {
  "🇺🇸 en-US": {
    ...en
  },
  "🇰🇷 ko-KR": {
    ...ko
  }
} as const;

// 초기 설정 언어를 받을 수 있는 initI18n 함수
export const initI18n = (initialLanguage: string) => {
  i18n.use(initReactI18next).init({
    resources,
    lng: initialLanguage, // 초기 설정 언어를 받은 파라미터로 설정
    fallbackLng: {
      "en-US": ["🇺🇸 en-US"],
      default: ["🇰🇷 ko-KR"]
    },
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });
};

export default i18n;
