import React, {useEffect, useState} from "react";
import {
    Image, RsWrapper,
    SpanText,
    WholeWrapper,
    Wrapper,
} from "../../../components/common/commonComponents";
import {Link, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {
    Card,
    Col, Divider,
    message,
    Modal,
    notification,
    Row,
    Segmented,
    Space,
    Tabs, theme,
    Typography,
} from "antd";
import {loginUser} from "./_service/service";
import {useCookies} from "react-cookie";
import {useRecoilState, useSetRecoilState} from "recoil";
import {loginResponseState} from "../../../state/userinfo";
import {FaMugHot, FaSnowflake} from "react-icons/fa";
import Theme from "../../../components/common/Theme";
import assets from "../../../assets";
import colorConfigs from "../../../configs/colorConfigs";
import Title from "antd/lib/typography/Title";
import {TbUser, TbUsers} from "react-icons/tb";
import _topbar from "../../../components/common/Header/_topbar";
import {SystemInit, systemInitState} from "../../../state/systemInit";
import useWidth from "../../../components/common/useWidth";
import {useTranslation} from "react-i18next";
import {GENERAL} from "../../../configs/userTypeConfig";
// import {
//     LoginFormPage,
//     ProConfigProvider,
//     ProFormCheckbox,
// } from "@ant-design/pro-components";
import {
    AlipayOutlined, ExclamationCircleOutlined,
    LockOutlined,
    MobileOutlined,
    TaobaoOutlined,
    UserOutlined,
    WeiboOutlined
} from "@ant-design/icons";
import {CSSProperties} from "styled-components";
import {SiFacebook, SiKakaotalk, SiNaver} from "react-icons/si";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import {ControlBar, Player} from "video-react";

const {Text} = Typography;
const Form = styled.form`
    margin: 0 auto;
`;

const Label = styled.label`
    margin-bottom: 16px;

    & > span {
        display: block;
        text-align: left;
        padding-bottom: 8px;
        font-size: 15px;
        cursor: pointer;
        line-height: 1.46666667;
        font-weight: 700;
    }
`;

const Input = styled.input`
    border-radius: 4px;
    border: 1px solid rgba(var(--sk_foreground_high_solid, 134, 134, 134), 1);
    transition: border 80ms ease-out, box-shadow 80ms ease-out;
    box-sizing: border-box;
    margin: 0 0 20px;
    width: 100%;
    max-width: 100%;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    background-color: rgba(var(--sk_primary_background, 255, 255, 255), 1);
    padding: 12px;
    height: 44px;
    padding-top: 11px;
    padding-bottom: 13px;
    font-size: 18px;
    line-height: 1.33333333;

    &:focus {
        border: 1px solid rgba(var(--sk_highlight, 18, 100, 163), 1);
        box-shadow: 0 0 0 1px rgba(var(--sk_highlight, 18, 100, 163), 1),
        0 0 0 5px rgba(29, 155, 209, 0.3);
    }
`;


const Button = styled.button`
    margin-bottom: 12px;
    width: 100%;
    max-width: 100%;
    color: #fff;
    background-color: #1677ff;
    border: none;
    font-size: 18px;
    font-weight: 900;
    height: 44px;
    min-width: 96px;
    padding: 0 16px 3px;
    transition: 300ms;
    user-select: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

    &:hover {
        background-color: #7777ff;
        border: none;
    }

    &:focus {
        --saf-0: rgba(var(--sk_highlight, 18, 100, 163), 1);
        box-shadow: 0 0 0 1px, 0 0 0 5px rgba(29, 155, 209, 0.3);
    }
`;

const LinkContainer = styled.p`
    font-size: 13px;
    color: #616061;
    margin: 0 auto 8px;
    width: 400px;
    max-width: 400px;

    & a {
        color: #1264a3;
        text-decoration: none;
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }
`;
type LoginType = 'GENERAL' | 'ADMIN';

const iconStyles: CSSProperties = {
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
    verticalAlign: 'middle',
    cursor: 'pointer',
};


const _moduleLogin = () => {
    const [loginType, setLoginType] = useState<LoginType>('GENERAL');
    const {token} = theme.useToken();
    const movePage = useNavigate();
    const {t} = useTranslation("mLogin");
    const [uid, setUid] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [cookies, setCookie] = useCookies(["accessToken"]);
    const setLoginResponse = useSetRecoilState(loginResponseState);
    const [selectedValue, setSelectedValue] = useState<string>(
        "SUB_CONTRACTOR_USER"
    );
    const [companycode, setCompanycode] = useState("");
    const [typeValue, setTypeValue] = useState<string>("SUB_CONTRACTOR_USER"); // Rename it to typeValue
    const [systemInit, setSystemInit] = useRecoilState(systemInitState);
    const width = useWidth();

    const [modal, contextHolder] = Modal.useModal();

    const confirm = () => {
        modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined/>,
            content: 'Bla bla ...',
            okText: '确认',
            cancelText: '取消',
        });
    };

    useEffect(() => {
        updateLoginInit(); // This will run only once on the initial render
    }, []);
    const updateLoginInit = () => {
        const newLoginActivateValue = false;
        setSystemInit((prevSystemInit: SystemInit | null) => ({
            ...prevSystemInit!,
            loginActivate: newLoginActivateValue,
        }));
    };

    const handleKeyPress = (e: { key: string; preventDefault: () => void; }) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submit
            apiLogin(); // Call your login function
        }
    };
    const moveRegisterRouter = () => {
        // navigate("/general");
        movePage("/signup");
    };
    const moveRegister = () => {
        moveRegisterRouter();
    };

    const apiLogin = () => {
        const data = {
            loginType: loginType,
            companyCode: companycode,
            uid: uid,
            userPassword: userPassword,
        };
        console.log("data in apiLogin:", data); // Debugging

        loginUser(data, movePage, setCookie, setLoginResponse, setSystemInit);
    };

    const handleSegmentedChange = (value: any) => {
        setSelectedValue(value);
        setTypeValue(value);
        console.log("typeValue in handleSegmentedChange:", typeValue); // Debugging
    };

    return (

        <WholeWrapper bgColor={Theme.lightColor}>
            <_topbar style={{margin: 0, padding: 0}}/>

            <Wrapper
                dr={`row`}
                shadow={`rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`}
                width={`80%`}
                height={`95vh`}
                // height={width < 700 ? `100vh-54px` : `100vh-64px`}
                radius={`10px`}
            >

                <Wrapper
                    width={width < 700 ? `100%` : `50%`}
                    height={width < 700 ? `60%` : `100%`}
                    radius={width < 700 ? `0 0 10px 10px` : `0 10px 10px 0`}
                >
                    {/*<Image*/}
                    {/*    width={`30%`}*/}
                    {/*    style={{marginBottom: 10}}*/}
                    {/*    src={assets.images.logo}*/}
                    {/*/>*/}
                    <SpanText  style={{fontSize:"20px", margin:`0 0 30px`, borderWidth: 1}}>FITA GRAM 오신것을 환영합니다.</SpanText>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <video
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                playsInline={true}
                                width={"100%"}
                            >
                                <source
                                    src={"https://overcomplex.co.kr/files/fishing_ta_gram/public/backgroundVideo.mp4"}
                                    type="video/mp4"
                                />
                            </video>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <Wrapper>
                                <Tabs
                                    centered
                                    activeKey={loginType}
                                    onChange={(activeKey) => setLoginType(activeKey as LoginType)}
                                >
                                    <Tabs.TabPane key={'GENERAL'} tab={'일반사용자 로그인'}/>
                                    {/*<Tabs.TabPane key={'ADMIN'} tab={'ADMIN-Login'}/>*/}
                                </Tabs>
                                {/*<Wrapper>*/}
                                {/*    <Text*/}
                                {/*        // fontSize={`40px`}*/}
                                {/*        // boldFont*/}
                                {/*        // color={Theme.white_C}*/}
                                {/*        // margin={`0 0 30px`}*/}
                                {/*    >*/}
                                {/*        Log in*/}
                                {/*    </Text>*/}
                                {/*</Wrapper>*/}
                                {loginType === 'GENERAL' && (
                                    <>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                apiLogin();
                                            }}
                                        >
                                            {selectedValue === GENERAL && (
                                                <Label id="companycode-label">
                                                    <Text>{t("input_business")}</Text>
                                                    <Input
                                                        type="input"
                                                        id="companycode"
                                                        name="companycode"
                                                        value={companycode}
                                                        onKeyPress={handleKeyPress} // Handle the Enter key press
                                                        onChange={(e) => setCompanycode(e.target.value)}
                                                    />
                                                </Label>
                                            )}
                                            <Label id="email-label">
                                                <Text>{t("input_uid")}</Text>
                                                <Input
                                                    type="input"
                                                    id="uid"
                                                    name="uid"
                                                    value={uid}
                                                    onKeyPress={handleKeyPress} // Handle the Enter key press
                                                    onChange={(e) => setUid(e.target.value)}
                                                />
                                            </Label>
                                            <Label id="password-label">
                                                <Text>{t("input_password")}</Text>
                                                <Input
                                                    type="password"
                                                    id="userPassword"
                                                    name="userPassword"
                                                    value={userPassword}
                                                    onChange={(e) => setUserPassword(e.target.value)}
                                                    style={{
                                                        color: token.colorText,
                                                    }}
                                                    onKeyPress={handleKeyPress} // Handle the Enter key press
                                                    className={'prefixIcon'}
                                                />
                                            </Label>

                                            <Button type="submit">{t("button_login")}</Button>
                                        </Form>
                                    </>
                                )}

                                <div
                                    style={{
                                        marginBlockEnd: 24,
                                    }}
                                >
                                    <a
                                        onClick={() => moveRegister()}
                                        style={{
                                            float: 'right',
                                        }}
                                    >
                                        {t("move_register")}
                                    </a>
                                </div>
                                {/*<div>*/}
                                {/*    <Button*/}
                                {/*        onClick={() => apiLogin()}*/}
                                {/*        className={"buttonShow"} // Adjusted for CSS module*/}
                                {/*        style={{*/}
                                {/*            borderRadius: 20,*/}
                                {/*            background: token.colorPrimary,*/}
                                {/*            color: token.colorBgBase,*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        {t("button_login")}*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                            </Wrapper>
                        </Col>
                    </Row>

                </Wrapper>
            </Wrapper>
        </WholeWrapper>


    );
};

export default _moduleLogin;
