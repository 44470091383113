import React, { useState, useMemo } from 'react';
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api';
import { Input, Button, Space, Card } from 'antd';

interface MapProps {
    address: string;
    onLocationSelect: (lat: string, lng: string, address: string, roadAddress: string) => void;
}

interface Location {
    lat: number;
    lng: number;
}

const GoogleMapCM: React.FC<MapProps> = ({ address, onLocationSelect }) => {
    const [location, setLocation] = useState<Location>({
        lat: 37.5665,
        lng: 126.978,
    }); // Default to Seoul
    const [markerPosition, setMarkerPosition] = useState<Location>({
        lat: 37.5665,
        lng: 126.978,
    });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchRtm, setSearchRtn] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<{
        lat: number;
        lng: number;
        address: string;
        roadAddress: string;
    } | null>(null); // Store the search result
    const [confirmedLocation, setConfirmedLocation] = useState<{
        lat: number;
        lng: number;
        address: string;
        roadAddress: string;
    } | null>(null); // Store confirmed location

    // Function to geocode an address (convert address to lat/lng)
    const handleAddressSearch = () => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: searchTerm }, (results, status) => {
            if (status === 'OK' && results && results[0]) {
                console.log(results[0])
                const location = results[0].geometry.location;
                const lat = location.lat();
                const lng = location.lng();
                const formattedAddress = results[0].formatted_address;

                setSearchResult({ lat, lng, address: formattedAddress, roadAddress: ""}); // Store the search result without immediately updating the map

                // Move the map to the search result
                setLocation({ lat, lng });
                setMarkerPosition({ lat, lng });
                setSearchRtn(true);
            } else {
                console.log(
                    'Geocode was not successful for the following reason: ' +
                    status
                );
                setSearchRtn(false);
            }
        });
    };

    // Function to confirm the selected location
    const handleConfirmLocation = (e: google.maps.MapMouseEvent | null = null) => {
          let lat: number, lng: number;

            if (e && e.latLng) {
                lat = e.latLng.lat();
                lng = e.latLng.lng();
            } else {
                // If no map click, use current location
                lat = location.lat;
                lng = location.lng;
            }

            setMarkerPosition({ lat, lng });
            setLocation({ lat, lng });

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                if (status === 'OK' && results && results[0]) {
                    const address = results[0].formatted_address;
                    const roadAddress = results[0].formatted_address;

                    onLocationSelect(lat.toString(), lng.toString(), address, roadAddress); // Update location on map click or button click
                    setSearchResult({ lat, lng, address, roadAddress}); // Update search result
                    setConfirmedLocation({ lat, lng, address, roadAddress }); // Store confirmed location
                }
            });
        };

    const handleNullConfirmLocation = (e: google.maps.MapMouseEvent | null = null) => {
        let lat: number, lng: number;
        let roadAddress: string;

        lat = 0;
        lng = 0;
        roadAddress = "";

        if (e && e.latLng) {
            lat = e.latLng.lat();
            lng = e.latLng.lng();
        } else {
            // If no map click, use current location
            lat = location.lat;
            lng = location.lng;
        }

        setMarkerPosition({ lat, lng });
        setLocation({ lat, lng });

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results && results[0]) {
                const address = results[0].formatted_address;
                const roadAddress = results[0].formatted_address;

                onLocationSelect("", "", "", ""); // Update location on map click or button click
                setSearchResult({ lat, lng, address, roadAddress}); // Update search result
                setConfirmedLocation({ lat, lng, address, roadAddress }); // Store confirmed location
            }
        });

    };

    // Function to handle map click and confirm location
    const handleMapClick = (e: google.maps.MapMouseEvent | null = null) => {
        let lat: number, lng: number;
        let roadAddress: string;

        if (e && e.latLng) {
            lat = e.latLng.lat();
            lng = e.latLng.lng();
        } else {
            // If no map click, use current location
            lat = location.lat;
            lng = location.lng;
        }

        setMarkerPosition({ lat, lng });
        setLocation({ lat, lng });

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results && results[0]) {
                const address = results[0].formatted_address;
                const addressComponents = results[0].address_components;

                // Check if the address is a road name address (도로명 주소)
                const roadAddressComponent = addressComponents.find(component =>
                    component.types.includes("route")
                );

                if (roadAddressComponent) {
                    roadAddress = results[0].formatted_address;
                    console.log("Road Address: ", roadAddress);
                    onLocationSelect(lat.toString(), lng.toString(), address, roadAddress);
                    setSearchResult({ lat, lng, address, roadAddress }); // Update search result
                    setConfirmedLocation({ lat, lng, address, roadAddress }); // Store confirmed location
                } else {
                    console.log("Road Address not found.");
                    setSearchResult({ lat, lng, address, roadAddress }); // Update search result
                    setConfirmedLocation({ lat, lng, address, roadAddress }); // Store confirmed location
                }
                setSearchRtn(true);
                // Do not call onLocationSelect here

            }else{
                setSearchRtn(false);
            }
        });
    };

    const defaultCenter: Location = useMemo(
        () => ({ lat: location.lat, lng: location.lng }),
        [location]
    );
    const defaultZoom = 16;

    return (
        <>
            <Space direction="horizontal" style={{ marginBottom: '10px' }}>
                <Input
                    placeholder="Enter address to search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onPressEnter={handleAddressSearch}
                    style={{ width: 300 }}
                />
                <Button type="primary" onClick={handleAddressSearch}>
                    Search
                </Button>
            </Space>

            <LoadScriptNext googleMapsApiKey="AIzaSyAYN-uqY4QkrrPXqR8Kl7WrUDn63sc6yJU">
                <GoogleMap
                    clickableIcons={true}
                    zoom={defaultZoom}
                    center={defaultCenter}
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    onClick={handleMapClick}
                >
                    {/* Display the marker at the confirmed or clicked location */}
                    <MarkerF position={markerPosition} />
                </GoogleMap>
            </LoadScriptNext>


            {/* Show search results and a confirm button */}
            {(searchResult && searchRtm) ? (
                <Card style={{marginBottom: '10px', width: '100%'}}>
                    <h3>검색 결과</h3>
                    <p>위도: {searchResult.lat}</p>
                    <p>경도: {searchResult.lng}</p>
                    <p>주소: {searchResult.address}</p>
                </Card>
            ):(
                <Card style={{marginBottom: '10px', width: '100%'}}>
                    <h3>검색 결과</h3>
                    <p>위치를 찾을 수 없습니다.</p>

                </Card>
            )}


            <Button color={"danger"}  type="default" onClick={() => handleNullConfirmLocation(null)} >
                이전
            </Button>

            {searchRtm && (
                <Button type="primary" onClick={() => handleConfirmLocation(null)}>
                    주소 전송
                </Button>
            )}

        </>
    );
};

export default GoogleMapCM;
