import { atom } from 'recoil';
import * as CryptoJS from 'crypto-js';
import { cryptoKey } from "../utils/cryptoManagement"; // 'crypto-js'로 import

export type PositionMap = {
    locationName: string;
    locationCode: string;
    Latitude: number;
    Longitude: number;
    existenceAndNonexistence: boolean;
    fillType?: string;
};
//
// export type PositionMapResponse = {
//     resultCode: number;
//     failMsg: null | string;
//     accessToken: string;
//     refreshToken: string;
// };

export const positionMapResponseState = atom<PositionMap | null>({
    key: 'positionMapResponse',
    default: null,
    effects_UNSTABLE: [
        ({ setSelf, onSet }) => {
            const encryptedValue = localStorage.getItem('positionMapResponse');
            if (encryptedValue != null) {
                // 로컬 스토리지에서 암호화된 값을 가져오고 복호화합니다.
                const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, cryptoKey).toString(CryptoJS.enc.Utf8);
                if (decryptedValue) {
                    setSelf(JSON.parse(decryptedValue));
                }
            }

            onSet((newValue) => {
                if (newValue == null) {
                    localStorage.removeItem('positionMapResponse');
                } else {
                    // 값을 암호화하여 로컬 스토리지에 저장합니다.
                    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(newValue), cryptoKey).toString();
                    localStorage.setItem('positionMapResponse', encryptedValue);
                }
            });
        },
    ],
});
