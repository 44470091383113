const colorConfigs = {
    basicTheme_C: "#389dff",
    subTheme_C: "#27BAE8",
    subTheme2_C: "#e7f3ff",

    // black
    black_C: "#000000",
    black2_C: "#242424",

    // grey
    grey_C: `#777777`,
    darkGrey_C: "#2d3436",
    bgGrey_C: "#b1b3b4",

    // point
    kakao_C: "#FAE100",
    naver_C: "#00C73C",
    red_C: "#e74c3c",

    // color define
    antiquewhite: "#FAEBD7",
    aliceblue: "#F0F8FF",
    primaryColor: "#ff6b6b",
    secondaryColor: "#70c1b3",
    tertiaryColor: "#f9c74f",
    accentColor: "#4361ee",
    darkColor: "#1c1c1c",
    lightColor: "#f5f5f5",
    successColor: "#28a745",
    warningColor: "#ffc107",
    dangerColor: "#dc3545",
    infoColor: "#17a2b8",
    mutedColor: "#6c757d",
    textColor: "#333333",
    linkColor: "#007bff",
    backgroundColor: "#ffffff",
    purple: "#8c1eff",
    yellow: "#ffc400",
    pink: "#e83e8c",
    green: "#1e6f3e",
    navy: "#3b3b98",
    gray: "#84817a",
    brown: "#5c2a2a",
    teal: "#20c997",
    lavender: "#9370db",
    gold: "#ffd700",
    orange: "#ff8800",
    red: "#d42b2b",
    blue: "#0077c2",
    lightPurple: "#9a70de",
    lightYellow: "#fffacc",
    lightPink: "#ffa3b2",
    lightGreen: "#93d6a3",
    lightNavy: "#6b6bb6",
    lightGray: "#dcdcdc",
    lightBrown: "#d8c3a5",
    lightTeal: "#a7ebd3",
    lightLavender: "#c9b2e3",
    lightGold: "#fafad2",
    lightOrange: "#ffcc99",
    lightRed: "#ffa1a1",
    lightBlue: "#99ccff",
    customGreen: "#9dcfae",
    CURRENTPOINT: '#53BE28FF',
    PREDICTED: '#7128be',
    FISHING: '#0e7fe5',
    TRAVEL: '#ff6347',
    CNF: '#567756',
    FOOD: '#e8b326',
    defaultFill: '#dddddd',
};


export default colorConfigs;
