import { Breadcrumb, Layout, Modal } from "antd";
import React, { MouseEvent, useCallback, useRef, useState } from "react";
import {
  Button,
  Wrapper,
} from "../../../../../components/common/commonComponents";
import { useCookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { loginResponseState } from "../../../../../state/userinfo";
import colorConfigs from "../../../../../configs/colorConfigs";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { GENERAL } from "../../../../../configs/userTypeConfig";

interface fileListInterface {
  file_id: string | null;
  sub_seq: number | null;
  file: File | null;
  url: string | null;
  file_name: string | null;
  type: "default" | "new";
}

const FileUploadTest = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("mDashboard");

  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [isModal, setIsModal] = useState(false);
  const [fileList, setFileList] = useState<fileListInterface[] | []>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const loginResponse = useRecoilValue(loginResponseState);

  const fileChangeHandler = useCallback((e) => {
    const files = Array.from(e.target.files) as File[];
    files.map((file: File, idx) => {
      setFileList((prev?: fileListInterface[]) => [
        ...(prev || []),
        {
          file_id: null,
          sub_seq: null,
          file: file,
          url: null,
          file_name: file.name,
          type: "new",
        },
      ]);
    });
  }, []);

  const evenToNull = (event: MouseEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = "";
  };

  const fileDelete = useCallback(
    (index) => {
      const data = fileList.filter((_, idx) => index !== idx);
      setFileList(data);
    },
    [fileList]
  );

  const modalCancel = useCallback(() => {
    setIsModal(false);
    setFileList([]);
  }, []);

  const modalSubmit = useCallback(() => {
    // fileLoading
    if (fileList.length !== 0) {
      mutate(fileList);
    }
  }, [fileList]);

  const {
    mutate,
    isError: uploadError,
    isLoading: uploadLoading,
    isSuccess: uploadSuccess,
  } = useMutation(
    async (data: fileListInterface[]) => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      fileList.forEach((data, index) => {
        data.file && formData.append(`file[${index}]`, data.file);
      });
      await axios
        .post(`/api/v1/auth/vender/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {},
    }
  );

  return (
    <Wrapper>
      <Layout
        style={{
          padding: "0 24px 24px",
          width: "100%",
          height: `100%`,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            {t("breadcrumb_target_job_seeking")}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("breadcrumb_page")}</Breadcrumb.Item>
        </Breadcrumb>
        <Wrapper>
          <Button
            type={`primary`}
            padding={`0 20px`}
            onClick={() => setIsModal((prev) => !prev)}
          >
            모달
          </Button>
        </Wrapper>
        <Modal
          open={isModal}
          onCancel={modalCancel}
          title={`파일 리스트`}
          closable={!uploadLoading}
          footer={
            <Wrapper dr={`row`} ju={`flex-end`}>
              <Button
                padding={`0 20px`}
                htmlType="reset"
                onClick={modalCancel}
                loading={uploadLoading}
                border={`1px solid ${colorConfigs.basic.grey1}`}
              >
                취소
              </Button>
              &nbsp;
              <Button
                padding={`0 20px`}
                type={`primary`}
                onClick={modalSubmit}
                loading={uploadLoading}
              >
                추가
              </Button>
            </Wrapper>
          }
        >
          <Wrapper padding={`20px 0`}>
            {fileList?.length === 0 ? (
              <Wrapper
                al={`flex-start`}
                padding={`20px`}
                margin={`0 0 10px`}
                cursor={`pointer`}
                radius={`7px`}
                bgColor={colorConfigs.basic.lightGray}
                dr={`row`}
                shadow={`0 1px 4px rgba(0, 0, 0, 0.3)`}
              >
                <WarningOutlined
                  style={{
                    color: colorConfigs.table_status.hover,
                    fontSize: `20px`,
                    margin: `0 10px 0 0`,
                  }}
                />{" "}
                업로드된 파일이 없습니다
              </Wrapper>
            ) : (
              fileList?.map((data, idx) => {
                return (
                  <Wrapper dr={`row`} ju={`space-between`} margin={`0 0 10px`}>
                    <Wrapper
                      al={`flex-start`}
                      padding={`10px 0`}
                      borderBottom={`1px solid${colorConfigs.themeColor.theme2}`}
                      style={{ width: "calc(100% - 50px)" }}
                      cursor={`pointer`}
                      // onClick={() =>
                      // data.url
                      //   ? fileDownloadHandler(data.url, data.file_name)
                      //   : message.error(
                      //       "저장된 이미지만 다운로드할 수 있습니다."
                      //     )
                      // }
                    >
                      {data.file_name}
                    </Wrapper>
                    <Button
                      danger
                      border={`1px solid ${colorConfigs.table_status.ban}`}
                      style={{ padding: `4px 10px` }}
                      onClick={() => fileDelete(idx)}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Wrapper>
                );
              })
            )}

            <Wrapper
              color={colorConfigs.themeColor.theme2}
              cursor={`pointer`}
              fontSize={`18px`}
              height={`32px`}
              border={`1px solid ${colorConfigs.themeColor.theme2}`}
              dr={`row`}
              margin={`0 0 30px`}
              onClick={() => fileRef?.current?.click?.()}
            >
              <input
                hidden
                type="file"
                name="file"
                accept="image/*"
                multiple
                ref={fileRef}
                onChange={fileChangeHandler}
                onClick={(e) => evenToNull(e)}
              />
              <UploadOutlined />
            </Wrapper>
          </Wrapper>
        </Modal>
      </Layout>
    </Wrapper>
  );
};

export default FileUploadTest;
