import {
    Avatar,
    Breadcrumb,
    Card,
    Carousel,
    Col,
    Divider,
    Empty,
    Layout,
    List,
    Row,
    Space,
    Input, Statistic, Button, DatePicker, Pagination, Typography, Tag,
} from "antd";

import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {
    Image,
    Text,
    Wrapper,
} from "../../../components/common/commonComponents";
import {IResponseProps} from "./_model/model";
import {useCookies} from "react-cookie";
import {getDashboardDataSearch} from "../../_main_contractor/_dashboardMgmt/_service/_admin/service";
import {useRecoilValue} from "recoil";
import {loginResponseState} from "../../../state/userinfo";
import colorConfigs from "../../../configs/colorConfigs";
import {useTranslation} from "react-i18next";

import useWidth from "../../../components/common/useWidth";

import {decryptToken} from "../../../utils/tokenManagement";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CalendarOutlined,
    LikeOutlined,
    PullRequestOutlined, SearchOutlined
} from "@ant-design/icons";
import {MdOutlineFactory} from "react-icons/md";
import Theme from "../../../components/common/Theme";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import dayjs from "dayjs";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

const {Content} = Layout;

interface DescriptionsProps {
    key: string;
    userImage?: string;
    userName: string;
    userPage?: string;
    children: React.ReactNode; // You can use React.ReactNode for varying children types
    span?: number; // Optional property
}

type Props = {};

const SubConstractorDashboardDetailIndex = (props: Props) => {
    const [searchParams] = useSearchParams();
    const siteId = searchParams.get('sid');
    const siteName = searchParams.get('siteName');


    useEffect(() => {
        dataPickerBtn("today")
        setSeletedEquipDetail(equipDataInit);
    }, []); // Empty dependency array means this runs once on mount
    const {t} = useTranslation("mDashboard");
    const width = useWidth();

    const {TextArea} = Input;
    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

    const {Meta} = Card;
    const token = cookies.accessToken;
    const [searchType, setSearchType] = useState("*");
    const [searchGubun, setSearchGubun] = useState("*");

    const {data, isLoading, isError, error, refetch} = useQuery<IResponseProps>(
        ["data"],
        () => getDashboardDataSearch(decryptToken(token), setCookie, removeCookie,searchType,searchGubun,'2024-12-31','2024-12-31'),
        {refetchOnWindowFocus: true}
    );
    const loginResponse = useRecoilValue(loginResponseState);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [seletedEquipDetail, setSeletedEquipDetail] = useState<any[]>([]);
    const { RangePicker } = DatePicker;
    const items: DescriptionsProps[] = [
        {
            key: "7",
            userImage:
                loginResponse?.userImage ||
                "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
            userName: loginResponse?.userName || "",
            children: (
                <>
                    <br/>
                    {loginResponse?.roleCode}
                    <br/>
                    {loginResponse?.userStatus}
                    <br/>
                </>
            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const dataList = Array.from({length: 23}).map((_, i) => ({
        href: "https://ant.design",
        title: `작업명 ${i + 1}`,
        avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        content:
            "작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다.",
    }));

    const IconText = ({icon, text}: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
    const ITEMS_PER_PAGE = 6;

    const equipmentInfoData = [
        {
            equipmentCode: 'EQ_A1001',
            name: 'PLC-2002',
            definedErrorCount: 4,
            definedProcessCount: 2,
        },
        {
            equipmentCode: 'EQ_A1002',
            name: 'PLC-3002',
            definedErrorCount: 4,
            definedProcessCount: 2,
        },
        {
            equipmentCode: 'EQ_A1003',
            name: 'PLC-4002',
            definedErrorCount: 4,
            definedProcessCount: 2,
        },
    ];

    const equipDataInit = [
        {
            name: '2024-03-23',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-24',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-25',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-26',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-27',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-28',
            errorCount: 0,
            processing: 0,
        },
        {
            name: '2024-03-29',
            errorCount: 0,
            processing: 0,
        },
    ];
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = equipmentInfoData.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (page: React.SetStateAction<number>) => {
        setCurrentPage(page);
    };

    const generateSampleTodayData = () => {
        const sampleData = [];
        let hour = 0;
        let minute = 0;

        while (hour < 24) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time = `${formattedHour}:${formattedMinute}:00`;

            sampleData.push({
                name: time,
                errorCount: Math.floor(Math.random() * 50), // 임의의 오류 횟수 생성
                processing: Math.floor(Math.random() * 5),  // 임의의 처리 중인 항목 수 생성
            });

            // 30분씩 증가
            minute += 30;
            if (minute >= 60) {
                minute = 0;
                hour++;
            }
        }

        return sampleData;
    };

    const generateSampleYearData = () => {
        const sampleData = [];
        let month = 1;

        while (month <= 12) {
            const formattedMonth = month.toString().padStart(2, '0');
            const time = `2024-${formattedMonth}`;

            sampleData.push({
                name: time,
                errorCount: Math.floor(Math.random() * 50), // 임의의 오류 횟수 생성
                processing: Math.floor(Math.random() * 5),  // 임의의 처리 중인 항목 수 생성
            });

            // 30분씩 증가
            month++;
        }

        return sampleData;
    };
    const generateSamplePrev6MonthData = () => {
        const sampleData = [];
        let fromdt = "";
        let preMonth = 0;
        const today = dayjs();

        fromdt = today
            .subtract(6, "month")
            .startOf("month")
            .format("YYYY-MM");
        preMonth = Number(today
            .subtract(6, "month")
            .startOf("month")
            .format("MM"));

        let month = preMonth;

        while (month > 0) {
            fromdt = today
                .subtract(month, "month")
                .startOf("month")
                .format("YYYY-MM");

            const formattedMonth = month.toString().padStart(2, '0');
            const time = `${fromdt}`;

            sampleData.push({
                name: time,
                errorCount: Math.floor(Math.random() * 50), // 임의의 오류 횟수 생성
                processing: Math.floor(Math.random() * 5),  // 임의의 처리 중인 항목 수 생성
            });

            // 30분씩 증가
            month--;
        }

        return sampleData;
    };
    const generateSamplePrevMonthData = () => {
        const sampleData = [];
        let fromdt = "";
        let todt = 0;
        const today = dayjs();

        fromdt = today
            .subtract(1, "month")
            .startOf("month")
            .format("MM");
        todt = Number(today.subtract(1, "month").endOf("month").format("DD"));

        let day = 1;

        while (day <= todt) {
            const formattedDay = day.toString().padStart(2, '0');
            const time = `2024-${fromdt}-${formattedDay}`;

            sampleData.push({
                name: time,
                errorCount: Math.floor(Math.random() * 50), // 임의의 오류 횟수 생성
                processing: Math.floor(Math.random() * 5),  // 임의의 처리 중인 항목 수 생성
            });

            // 30분씩 증가
            day++;
        }

        return sampleData;
    };

    const todayData = generateSampleTodayData();
    const yearData = generateSampleYearData();
    const prev6Month = generateSamplePrev6MonthData();
    const prevMonth = generateSamplePrevMonthData();

    const [rangePicker, setRangePicker] = useState<[string, string]>([
        dayjs().format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
    ]);
    const format = "YYYY-MM-DD";
    const dataPickerBtn = useCallback((type: string) => {
        let fromdt = "";
        let todt = "";
        const today = dayjs();

        switch (type) {
            case "prevMonth":
                fromdt = today
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                todt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                setSeletedEquipDetail(prevMonth)
                break;
            case "prev6Month":
                fromdt = today
                    .subtract(6, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                todt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                setSeletedEquipDetail(prev6Month)
                break;
            case "year":
                fromdt = today.startOf("year").format("YYYY-MM-DD");
                todt = today.endOf("year").format("YYYY-MM-DD");
                setSeletedEquipDetail(yearData);
                break;
            case "today":
                fromdt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                todt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                setSeletedEquipDetail(todayData);
                break;
            default:
                break;
        }

        return setRangePicker([fromdt, todt]);
    }, []);

    const searchHandldr = useCallback(() => {
        refetch();
    }, []);

    // 데이터 조회 로딩
    // if (isLoading) {
    //     return <Spin/>;
    // }
    // 데이터 조회 에러
    //   if (isError) {
    //     return (
    //       <Content
    //         style={{
    //           padding: 14,
    //           margin: 0,
    //           minHeight: 280,
    //           // background: Theme.basicTheme_C,
    //         }}
    //       >
    //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //       </Content>
    //     );
    //   }

    return (
        <Wrapper>
            <Layout
                style={{
                    padding: "0 0 0 10px",
                    width: "100%",
                    height: `100%`,
                }}
            >
                <Breadcrumb style={{margin: "0px 0px 10px 0px"}}>
                    <Breadcrumb.Item>
                        {t("breadcrumb_target_job_seeking")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("breadcrumb_page")}</Breadcrumb.Item>
                </Breadcrumb>
                <Wrapper
                    display={`flex`}
                    ju={`flex-start`} al={`space-between`}
                >
                    <Row gutter={[36, 24]}>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Registered Equipment"
                                value={equipmentInfoData.length}
                                prefix={<MdOutlineFactory/>}
                                valueStyle={{color: '#3f8600'}}
                            />
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic title="Number of errors encountered" value={93} suffix="/ 100"/>
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Number of unhandled errors"
                                value={11.28}
                                precision={2}
                                valueStyle={{color: '#3f8600'}}
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Error Rate"
                                value={9.3}
                                precision={2}
                                valueStyle={{color: '#cf1322'}}
                                prefix={<ArrowDownOutlined/>}
                                suffix="%"
                            />
                        </Col>
                    </Row>
                </Wrapper>
                <Divider orientation="left" />

                <Row gutter={[36, 24]} style={{padding: "0 24px 24px"}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Breadcrumb style={{margin: "16px 0"}}>
                            <Breadcrumb.Item>sid-Site</Breadcrumb.Item>
                            <Breadcrumb.Item>Equipment-List</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider orientation="left">{siteName || null} <Tag color={"warning"}>{siteId || null}</Tag> Equipment List</Divider>
                        <Wrapper
                            display={`flex`}
                            dr={`column`}
                            ju={`flex-start`} al={`space-between`}
                            padding={`32px 16px`}
                            // height={`120px`}
                            minHeight={`500px`}
                            radius={`25px`}
                            overflow={`hidden`}
                            shadow={`0 2px 4px rgba(0, 0, 0, 0.1)`}
                            bgColor={colorConfigs.basic.white}
                        >
                            <Row gutter={[16, 24]} >
                                {currentItems.map((item, index) => (
                                    <Col key={index} xs={24} sm={24} md={24} lg={24} xl={8}>
                                        <Card
                                            style={{
                                                backgroundColor: Theme.lightColor,
                                                border: "1px solid #f0f0f0",
                                                minHeight: '120px',
                                                maxHeight: '120px',
                                            }}
                                            hoverable
                                            title={item.name}
                                            onClick={() =>setSeletedEquipDetail(todayData)}
                                        >
                                            <Meta description={`DE: ${item.definedErrorCount}, DP: ${item.definedProcessCount}`} />
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <Pagination
                                current={currentPage}
                                onChange={handleChangePage}
                                pageSize={ITEMS_PER_PAGE}
                                total={equipmentInfoData.length}
                                // Optionally customize the pagination component to fit your layout
                                style={{ marginTop: 20, textAlign: 'center' }}
                            />
                        </Wrapper>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Breadcrumb style={{margin: "16px 0"}}>
                            <Breadcrumb.Item>Information</Breadcrumb.Item>
                            <Breadcrumb.Item>Equipment Detail Information</Breadcrumb.Item>
                        </Breadcrumb>

                        <Divider orientation="left">Side-B Content</Divider>
                        <Wrapper
                            display={`flex`}
                            dr={`column`}
                            ju={`flex-start`} al={`space-between`}
                            padding={`32px 16px`}
                            // height={`120px`}
                            minHeight={`500px`}
                            radius={`25px`}
                            overflow={`hidden`}
                            shadow={`0 2px 4px rgba(0, 0, 0, 0.1)`}
                            bgColor={colorConfigs.basic.white}
                        >
                            {
                                /**
                                 * content-B List View Component
                                 * Data Length = 0  : Empty
                                 * */
                                dataList.length == 0 ? (
                                    <Empty/>
                                ) : (
                                    <>
                                        <Wrapper dr={`row`} ju={`flex-start`} padding={`20px`}>

                                            <Wrapper
                                                width={`auto`}
                                                dr={`row`}
                                                ju={`flex-start`}
                                                margin={width < 950 ? `0` : `0 20px 0 0`}
                                            >
                                                <Text margin={`0 20px 0 0`}>조회시간</Text>
                                                <DatePicker.RangePicker
                                                    style={{ width: width < 950 ? `100%` : `` }}
                                                    onChange={(_, data) => setRangePicker(data)}
                                                    value={[
                                                        dayjs(rangePicker[0], format),
                                                        dayjs(rangePicker[1], format),
                                                    ]}
                                                    allowClear={false}
                                                />

                                                <Button
                                                    style={{margin:`0 20px 0 10px`}}
                                                    type={`primary`}
                                                    icon={<SearchOutlined />}
                                                    onClick={() => searchHandldr()}
                                                >
                                                    검색
                                                </Button>
                                            </Wrapper>

                                        </Wrapper>
                                        <Wrapper
                                            width={width < 950 ? `100%` : `auto`}
                                            dr={`row`}
                                            ju={width < 950 ? `space-between` : `flex-end`}
                                            margin={`0 0 10px 0`}
                                        >
                                            <Button
                                                type={`primary`}
                                                ghost
                                                icon={<CalendarOutlined />}
                                                onClick={() => dataPickerBtn("today")}
                                                style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                                            >
                                                오늘
                                            </Button>
                                            <Button
                                                type={`primary`}
                                                ghost
                                                icon={<CalendarOutlined />}
                                                onClick={() => dataPickerBtn("year")}
                                                style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                                            >
                                                연간
                                            </Button>
                                            <Button
                                                type={`primary`}
                                                ghost
                                                icon={<CalendarOutlined />}
                                                style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                                                onClick={() => dataPickerBtn("prevMonth")}
                                            >
                                                전월
                                            </Button>
                                            <Button
                                                type={`primary`}
                                                ghost
                                                icon={<CalendarOutlined />}
                                                style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                                                onClick={() => dataPickerBtn("prev6Month")}
                                            >
                                                이전 6개월
                                            </Button>

                                        </Wrapper>
                                        <Divider />

                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={seletedEquipDetail}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 10,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" height={60}  />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="errorCount" stroke="#8884d8"  />
                                            <Line type="monotone" dataKey="processing" stroke="#82ca9d" />
                                        </LineChart>
                                    </>
                                )
                            }
                        </Wrapper>
                    </Col>
                </Row>
            </Layout>
        </Wrapper>
    );
};

export default SubConstractorDashboardDetailIndex;
