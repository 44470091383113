import {notification} from "antd";
import {ImNotification} from "react-icons/im";
import codeDefine from "../../../defined/codeDefine";

export const LoadNotification = ({msg, content}: { msg: any, content: any }) => {
    notification.open({
        icon: <ImNotification/>,
        message: msg,
        description: content,
        onClick: () => {},
        style: {
            backgroundColor: msg == codeDefine.msgResponse.success ? '#eaf8ff': msg == codeDefine.msgResponse.warning ? '#efd6c1': '#efc1c1',
            border: msg == codeDefine.msgResponse.success ? '1px solid #a8d6ff': msg == codeDefine.msgResponse.warning ? '1px solid #efd6c1': '1px solid #efc1c1',
            color: '#333'
        },
        duration: 3
    });
};
