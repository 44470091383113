import {
    Badge,
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    message,
    Card,
    FloatButton, Checkbox, Space, Switch, Tooltip, Descriptions,
} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {getDashboardDataSearch} from "../../../pages/_main_contractor/_dashboardMgmt/_service/_admin/service";
import {decryptToken} from "../../../utils/tokenManagement";

interface HistoryAppendFormProps {
    setThType?: React.Dispatch<React.SetStateAction<string>>; // 상태 변경 함수
    setThGubun?: React.Dispatch<React.SetStateAction<string>>; // 상태 변경 함수
    setThStartDatetime?: React.Dispatch<React.SetStateAction<string>>; // 상태 변경 함수
    setThEndDatetime?: React.Dispatch<React.SetStateAction<string>>; // 상태 변경 함수
}

interface PreviewFile {
    uid: string;
    name: string;
    url: string;
}


const DashboardSearchForm = (props: HistoryAppendFormProps) => {

    const [paType, setPaType] = useState<string>("*"); // Default zoom level
    const [paGubun, setPaGubun] = useState<string>("*"); // Default zoom level
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

// Set initial dates
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st
    const lastDayOfMonth = new Date(currentYear, new Date().getMonth() + 1, 0); // Last day of the current month

    const [paStartDate, setPaStartDate] = useState<string>(formatDate(firstDayOfYear));
    const [paEndDate, setPaEndDate] = useState<string>(formatDate(lastDayOfMonth));


    const handleRegionChange = (event: { target: { value: any; }; }) => {
        setPaGubun(event.target.value);
    };
    const handleTypeChange = (event: { target: { value: any; }; }) => {
        setPaType(event.target.value);
    };
    const handleStartDateChange = (event: { target: { value: any; }; }) => {
        setPaStartDate(event.target.value);
    };
    const handleEndDateChange = (event: { target: { value: any; }; }) => {
        setPaEndDate(event.target.value);
    };
    const [isEndDateSynced, setIsEndDateSynced] = useState(true);


    const reSearch = () => {
        props.setThGubun?.(paGubun || "");
        props.setThType?.(paType || "");
        props.setThStartDatetime?.(paStartDate || "");
        props.setThEndDatetime?.(paEndDate || "");
    }
    // const reSearch = () => {
    //     getDashboardDataSearch(decryptToken(token), setCookie, removeCookie, thType, thGubun);
    //     searchHandldr();
    //     // refetch();
    //
    // }
    return (
        <>
            <Descriptions
                bordered
                title="검색조건"
                size={"small"}
                // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                extra={
                    <>
                        <Tooltip title="search">
                            <Button type="primary" icon={<SearchOutlined/>}
                                    onClick={() => reSearch()}>
                                검색
                            </Button>
                        </Tooltip>
                    </>
                }
            >
                <Descriptions.Item label="지역구분" span={4}>
                    <select
                        onChange={handleRegionChange}
                        style={{
                            width: '40%',
                            padding: '8px',
                            borderRadius: '4px',
                            borderColor: '#d9d9d9'
                        }}>
                        <option value="*">--전체--</option>
                        <option value="GLOBAL">GLOBAL</option>
                        <option value="DOMESTIC">DOMESTIC</option>
                    </select>
                </Descriptions.Item>
                <Descriptions.Item label="카테고리 구분" span={4}>
                    <select
                        onChange={handleTypeChange}
                        style={{
                            width: '40%',
                            padding: '8px',
                            borderRadius: '4px',
                            borderColor: '#d9d9d9'
                        }}>
                        <option value="*">--전체--</option>
                        <option value="PREDICTED">PREDICTED</option>
                        <option value="FISHING">FISHING</option>
                        <option value="TRAVEL">TRAVEL</option>
                        <option value="CNF">CAMPING & FISHING</option>
                        <option value="FOOD">DELICIOUS FOOD</option>
                    </select>
                </Descriptions.Item>

                <Descriptions.Item label="검색 일자">
                    <Input
                        type="date"
                        name="startDate"
                        value={paStartDate}
                        onChange={(e) => {
                            setPaStartDate(e.target.value);
                            if (isEndDateSynced) {
                                setPaEndDate(e.target.value);
                                // appendForm.setFieldsValue({historyEndDate: e.target.value});
                            }
                        }}
                        style={{width: '90%'}}
                    />

                </Descriptions.Item>
                <Descriptions.Item label="~">
                    <Input
                        type="date"
                        name="endDate"
                        value={paEndDate}
                        onChange={(e) => setPaEndDate(e.target.value)}
                        disabled={isEndDateSynced}
                        style={{width: '90%'}}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="옵션">
                    <Switch
                        checkedChildren="동일"
                        unCheckedChildren="개별"
                        defaultChecked
                        onChange={(checked) => {
                            setIsEndDateSynced(checked);
                            if (checked) {
                                setPaEndDate(paStartDate);
                                // appendForm.setFieldsValue({historyEndDate: startDate});
                            }
                        }}
                    />
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};

export default DashboardSearchForm;
