import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
} from "antd";
import {
  Image,
  Text,
  Wrapper,
} from "../../../components/common/commonComponents";
import { MouseEvent, RefObject, useCallback, useRef, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";

const _moduleUserInfo = () => {
  const [imageChange, setImageChange] = useState<Boolean>(false);
  const [passwordChange, setPasswordChange] = useState<Boolean>(false);
  const [nameChange, setNameChange] = useState<Boolean>(false);
  const [emailChange, setEmailChange] = useState<Boolean>(false);

  // 휴대전화 기능 보류
  // const [phoneChange, setPhoneChange] = useState<Boolean>(false);

  const [previewImage, setPreviewImage] = useState<string>(
      "http://via.placeholder.com/100x100"
  );

  const fileRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const fileChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (e) => {
            const result = e?.target?.result as string;
            setPreviewImage(result);
          };
        }
      },
      []
  );

  const evenToNull = (event: MouseEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = "";
  };

  const fileClickHandler = useCallback(() => {
    if (fileRef.current) {
      fileRef?.current?.click();
    }
  }, [fileRef]);
  return (
      <Wrapper>
        <Layout
            style={{
              padding: "0 10px 100px",
              width: "100%",
              height: `100%`,
              background: `none`,
            }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>공동모듈 관리</Breadcrumb.Item>
            <Breadcrumb.Item>사용자 정보 관리</Breadcrumb.Item>
          </Breadcrumb>
          <Row style={{ padding: "0 24px 24px", height: `100%` }}>
            <Wrapper al={`flex-start`}>
              <Wrapper width={`650px`}>
                <Divider orientation="left">기본 회원 정보</Divider>
              </Wrapper>
            </Wrapper>
            <Wrapper
                width={`650px`}
                bgColor={colorConfigs.basic.white}
                padding={`30px`}
                radius={`25px`}
                shadow="rgba(0, 0, 0, 0.1) 0px 5px 10px"
            >
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  al={`flex-start`}
                  padding="30px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                <Text width={`150px`} fontSize="18px">
                  사진
                </Text>

                <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                  <Wrapper
                      radius="50%"
                      width={`80px`}
                      height={`80px`}
                      overflow="hidden"
                      margin={`0 0 20px`}
                  >
                    <Image src={previewImage} height={`100%`} />
                  </Wrapper>
                  <Text>회원님을 알릴 사진을 등록해 주세요.</Text>
                  {imageChange && (
                      <Wrapper dr={`row`} ju={`flex-start`} margin={`10px 0 0`}>
                        <Button
                            style={{ margin: `0 10px 0 0` }}
                            onClick={fileClickHandler}
                        >
                          사진 선택
                        </Button>
                        <input
                            hidden
                            type={`file`}
                            name={`file`}
                            ref={fileRef}
                            onChange={fileChangeHandler}
                            onClick={(e) => evenToNull(e)}
                            accept="image/*"
                        />
                        <Button
                            onClick={() =>
                                setPreviewImage("https://via.placeholder.com/100x100")
                            }
                        >
                          기본 이미지
                        </Button>
                      </Wrapper>
                  )}
                </Wrapper>

                <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                  {imageChange ? (
                      <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                        <Button style={{ width: `70px` }}>저장</Button>
                        <Button
                            style={{ width: `70px` }}
                            onClick={() => setImageChange(false)}
                        >
                          취소
                        </Button>
                      </Wrapper>
                  ) : (
                      <Button
                          onClick={() => setImageChange(true)}
                          style={{ width: `110px` }}
                      >
                        사진 변경
                      </Button>
                  )}
                </Wrapper>
              </Wrapper>
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  al={`flex-start`}
                  padding="30px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                <Text width={`150px`} fontSize="18px">
                  아이디
                </Text>
                <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                  minhyoungo
                </Wrapper>
              </Wrapper>
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  al={passwordChange ? `flex-start` : `center`}
                  padding="21px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                <Text width={`150px`} fontSize="18px">
                  비밀번호
                </Text>

                {passwordChange ? (
                    <Wrapper
                        dr={`row`}
                        width={`calc(100% - 150px - 150px)`}
                        al={`flex-start`}
                        ju={`flex-start`}
                    >
                      <Form>
                        <Wrapper dr={`row`} margin={`0 0 20px`}>
                          <Text width={`140px`}>현재 비밀번호</Text>
                          <Form.Item
                              style={{ width: `calc(100% - 140px)`, margin: `0` }}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Wrapper>
                        <Wrapper dr={`row`} margin={`0 0 20px`}>
                          <Text width={`140px`}>신규 비밀번호</Text>
                          <Form.Item
                              style={{ width: `calc(100% - 140px)`, margin: `0` }}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Wrapper>
                        <Wrapper dr={`row`} margin={`0 0 20px`}>
                          <Text width={`140px`}>신규 비밀번호 재 입력</Text>
                          <Form.Item
                              style={{ width: `calc(100% - 140px)`, margin: `0` }}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Wrapper>
                        <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                          <Button
                              style={{ width: `70px` }}
                              onClick={() => setPasswordChange(false)}
                          >
                            취소
                          </Button>
                          <Button style={{ width: `70px` }}>완료</Button>
                        </Wrapper>
                      </Form>
                    </Wrapper>
                ) : (
                    <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                      ********
                    </Wrapper>
                )}
                <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                  {!passwordChange && (
                      <Button
                          onClick={() => setPasswordChange(true)}
                          style={{ width: `110px` }}
                      >
                        비밀번호 변경
                      </Button>
                  )}
                </Wrapper>
              </Wrapper>
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  //   al={`flex-start`}
                  padding="21px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                {/*TODO 인증방식 전화번호 인증? 서업자번호 인증? .... */}
                <Text width={`150px`} fontSize="18px">
                  이름
                </Text>
                <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                  오민형
                </Wrapper>
                <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                  {nameChange ? (
                      <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                        <Button style={{ width: `70px` }}>저장</Button>
                        <Button
                            style={{ width: `70px` }}
                            onClick={() => setNameChange(false)}
                        >
                          취소
                        </Button>
                      </Wrapper>
                  ) : (
                      <Button
                          onClick={() => setNameChange(true)}
                          style={{ width: `110px` }}
                      >
                        이름수정
                      </Button>
                  )}
                </Wrapper>
              </Wrapper>
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  //   al={`flex-start`}
                  padding="21px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                <Text width={`150px`} fontSize="18px">
                  이메일
                </Text>
                <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                  minhoyungo@naver.com
                </Wrapper>
                <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                  {emailChange ? (
                      <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                        <Button style={{ width: `70px` }}>저장</Button>
                        <Button
                            style={{ width: `70px` }}
                            onClick={() => setEmailChange(false)}
                        >
                          취소
                        </Button>
                      </Wrapper>
                  ) : (
                      <Button
                          onClick={() => setEmailChange(true)}
                          style={{ width: `110px` }}
                      >
                        이메일 변경
                      </Button>
                  )}
                </Wrapper>
              </Wrapper>
              <Wrapper
                  dr={`row`}
                  ju={`flex-start`}
                  //   al={`flex-start`}
                  padding="21px 0"
                  borderBottom={`1px solid ${colorConfigs.basic.bwhite}`}
              >
                <Text width={`150px`} fontSize="18px">
                  휴대전화
                </Text>
                <Wrapper width={`calc(100% - 150px - 150px)`} al={`flex-start`}>
                  010-6269-1936
                </Wrapper>

                {/* 휴대전화 기능 보류 */}

                {/* <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                {phoneChange ? (
                  <Wrapper dr={`row`} width={`150px`} ju={`space-between`}>
                    <Button style={{ width: `70px` }}>저장</Button>
                    <Button
                      style={{ width: `70px` }}
                      onClick={() => setPhoneChange(false)}
                    >
                      취소
                    </Button>
                  </Wrapper>
                ) : (
                  <Button
                    onClick={() => setPhoneChange(true)}
                    style={{ width: `110px` }}
                  >
                    휴대전화 변경
                  </Button>
                )}
              </Wrapper> */}
              </Wrapper>
            </Wrapper>
          </Row>
        </Layout>

        {/* MODAL AREA */}
      </Wrapper>
  );
};

export default _moduleUserInfo;
