import { RouteType } from "./config";

// ICONS
import { TbId } from "react-icons/tb";
import { FaClipboardList, FaHospitalUser } from "react-icons/fa";
import {
  MdManageSearch,
  MdOutlineHistory,
  MdOutlineSupervisedUserCircle,
} from "react-icons/md";

import {
  BsBuildingExclamation,
  BsFillBoxSeamFill,
  BsInfoCircleFill,
  BsKeyFill,
  BsShareFill,
} from "react-icons/bs";
import { BiHomeAlt } from "react-icons/bi";

// PAGES
import CodeMasterPageLayout from "../pages/_main_contractor/_M1000Mgmt/_codeMasterPageLayout";
import MainContractorDashboardIndex from "../pages/_main_contractor/_dashboardMgmt/DashboardIndex";
import SubConstractorDashboardIndex from "../pages/_sub_contractor/_dashboardMgmt/DashboardIndex";
import SubConstractorDashboardDetailIndex from "../pages/_sub_contractor/_dashboardMgmt/DashboardDetailIndex";
import _moduleUserInfo from "../pages/_public/_userInformationMgmt/_moduleUserInfo";
import _moduleLogin from "../pages/_public/_loginMgmt/_moduleLogin";
import { AiOutlinePartition, AiTwotoneReconciliation } from "react-icons/ai";
import _moduleBean from "../pages/_public/_moduleBean";
import codeDefine from "../defined/codeDefine";
import React from "react";
import _dashboardDetail from "../pages/_main_contractor/_dashboardMgmt/_dashboardDetailIndex";
import _dashboardFsgIndex from "../pages/_main_contractor/_dashboardMgmt/_page/_dashboardFsgIndex";

const appRoutes: RouteType[] = [
  ///////////////////////
  //  MAIN CONTRACTOR  //
  ///////////////////////
  {
    index: true,
    path: "/manager",
    element: <MainContractorDashboardIndex />,
    state: "_dashboardMgmt",
    ribbon: true,
    sidebarProps: {
      displayText: "Dashboard",
      icon: <BiHomeAlt />,
    },
    menuType: codeDefine.authList.ALL,
  },
  {
    // index: true,
    path: "/manager/workplace/details",
    element: <_dashboardDetail />,
    state: "_dashboardMgmt",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <BsFillBoxSeamFill />,
    },
    menuType: codeDefine.authList.ALL,
    hidden: true,
  },

  {
    // index: true,
    path: "/manager/workplace/total",
    element: <_dashboardFsgIndex />,
    state: "_dashboardMgmt",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <BsFillBoxSeamFill />,
    },
    menuType: codeDefine.authList.ALL,
    hidden: true,
  },


  //////////////////
  //  MANAGEMENT  //
  //////////////////

  {
    path: "/management/public-master",
    element: <CodeMasterPageLayout />,
    state: "public-master",
    sidebarProps: {
      displayText: "Public Module Management Master",
      icon: <BsShareFill />,
    },
    menuType: codeDefine.authList.DENY,
    hidden: true,


    // hidden: true, // Add this line to make the route hidden
    child: [
      {
        path: "/management/public-master/login",
        element: <_moduleLogin />,
        state: "public-master.login",
        sidebarProps: {
          displayText: "Public User Info Management",
          icon: <BsInfoCircleFill />,
        },
        hidden: true,
      },
      {
        path: "/management/public-master/user/info",
        element: <_moduleUserInfo />,
        state: "public-master.user",
        sidebarProps: {
          displayText: "Public User Info Management",
          icon: <BsInfoCircleFill />,
        },
      },
      {
        path: "/management/public-master/system/init",
        element: <_moduleBean />,
        state: "public-master.system",
        sidebarProps: {
          displayText: "Public System Info Management",
          icon: <BsInfoCircleFill />,
        },
      },
    ],
  },
  {
    path: "/management/user-master",
    element: <CodeMasterPageLayout />,
    state: "user-master",
    sidebarProps: {
      displayText: "User Management Master",
      icon: <MdManageSearch />,
    },
    auth: codeDefine.authList.ALL,
    hidden: true,

    child: [

    ],
  },

  {
    path: "/management/encyclopedia-master",
    element: <_moduleBean />,
    state: "encyclopedia-master",
    sidebarProps: {
      displayText: "Encyclopedia Management Master",
      icon: <AiTwotoneReconciliation />,
    },
    auth: codeDefine.authList.ALL,
    hidden: true,

    child: [
      {
        path: "/management/encyclopedia-master/equipment/current/error/list",
        element: <_moduleBean />,
        state: "encyclopedia-master.equipment.current.error.list",
        sidebarProps: {
          displayText: "Encyclopedia Current Error List",
          icon: <FaClipboardList />,
        },
        auth: codeDefine.authList.ALL,
      },
      {
        path: "/management/encyclopedia-master/equipment/history/error/list",
        element: <_moduleBean />,
        state: "encyclopedia-master.equipment.history.error.list",
        sidebarProps: {
          displayText: "Encyclopedia Error History List",
          icon: <TbId />,
        },
        auth: codeDefine.authList.ALL,
      },
    ],
  },




  //////////////////////
  //  SUB CONTRACTOR  //
  //////////////////////

  {
    // index: true,
    path: "/general",
    element: <SubConstractorDashboardIndex />,
    state: "_dashboardMgmt",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <BsFillBoxSeamFill />,
    },
    hidden: true,
  },
  {
    // index: true,
    path: "/general/workplace/detail",
    element: <SubConstractorDashboardDetailIndex />,
    state: "_dashboardMgmt",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <BsFillBoxSeamFill />,
    },
    hidden: true,
  },




  // sub-contractor/site/...

  // {
  //   index: true,
  //   path: "/client",
  //   element: <DashboardIndex />,
  //   state: "_dashboardClientMgmt",
  //   sidebarProps: {
  //     displayText: "DashboardClient",
  //     icon: <BsFillBoxSeamFill />,
  //   },
  // },

];

export default appRoutes;
