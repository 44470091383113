import colorConfigs from "../../../configs/colorConfigs";
import {
    Button,
    Drawer,
    Dropdown,
    ImageProps,
    MenuProps,
    message,
    Popconfirm,
    Space,
    Switch,
    Tag,
} from "antd";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import React, {FC, useCallback, useEffect, useState} from "react";
import "../_style/style.css";
import assets from "../../../assets";
import i18n, {i18n_language_items} from "../../../locales";
import {Image, RsWrapper, Text, Wrapper} from "../commonComponents";
import useWidth from "../useWidth";
import MobileMenu from "../Sidebar/MobileMenu";
import {useNavigate} from "react-router-dom";
import {
    AiFillHome,
    AiOutlineMenuFold,
    AiOutlineMenuUnfold,
} from "react-icons/ai";

import {BiLogIn} from "react-icons/bi";
import {FaUserAlt, FaUserCog} from "react-icons/fa";
import {IoIosArrowDropdown} from "react-icons/io";
import {useCookies} from "react-cookie";
import {loginResponseState} from "../../../state/userinfo";
import {
    IsLanguage,
    isLanguageState,
    isToggleAtomState,
    SystemInit,
    systemInitState,
} from "../../../state/systemInit";
import {useTranslation} from "react-i18next";
import {initI18n} from "../../../locales/i18n";

import {RouteType} from "../../../routes/config";
import styled from "styled-components";
import {CloseOutlined, SecurityScanOutlined} from "@ant-design/icons";
import {RxDropdownMenu} from "react-icons/rx";
import {MdSettings} from "react-icons/md";
import {GENERAL, MANAGER} from "../../../configs/userTypeConfig";
import Theme from "../Theme";
import TimeViewer from "../TimeViewer";
import moment from "moment/moment";
import {isMobile, isTablet} from "react-device-detect";


const CustomCloseOutlined = styled(CloseOutlined)`
    font-size: 22px;
    cursor: pointer;
    color: ${(props: any) => props.theme.basic.grey2};

    &:hover {
        color: ${(props: any) => props.theme.basic.black};
    }

    transition: 0.3s;
`;

const DrawerMenu = styled(Wrapper)`
    color: ${(props: any) => props.theme.basic.grey2};
    cursor: pointer;

    &.hover {
        color: ${(props: any) => props.theme.basic.black};
        margin-right: -50px;
    }

    &:hover {
        color: ${(props: any) => props.theme.basic.black};
        margin-right: -50px;
    }
`;

const _topbar: FC<ImageProps> = ({children}) => {
    const {t} = useTranslation("mtopbar");
    const [loginResponse, setLoginResponse] = useRecoilState(loginResponseState);
    const translationKey = loginResponse?.troleCode || 'defaultTranslationKey';

    const translatedText = t(translationKey);
    const setToggleAtom = useSetRecoilState(isToggleAtomState);
    const [systemInit, setSystemInit] = useRecoilState(systemInitState);

    // const toggle = () => setToggleAtom((prev) => ({ toggle: !prev.toggle }));
    const toggle = () => {
        setToggleAtom((prev) => ({toggle: prev ? !prev.toggle : true}));
    };

    const isToggle = useRecoilValue(isToggleAtomState);

    const setLanguageAtom = useSetRecoilState(isLanguageState);
    const isLanguage = useRecoilValue(isLanguageState);

    const movePage = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

    const toggleLocales = useCallback((locale: string) => {
        initI18n(locale);
    }, []);

    const onMenuClick: MenuProps["onClick"] = (e) => {
        const newLanguageValue = e.key;
        setLanguageAtom((prevSystemInit: IsLanguage | null) => {
            if (prevSystemInit) {
                // Create a new object by spreading the previous state and updating the isLanguage property
                return {
                    ...prevSystemInit,
                    language: newLanguageValue,
                };
            }
            // Handle the case where prevSystemInit is null (initial state)
            return {
                language: newLanguageValue,
            };
        });
        if (isLanguage && isLanguage.language) {
            toggleLocales(isLanguage.language);
        }
        toggleLocales(newLanguageValue);
    };
    const updateLoginDisabled = () => {
        const newLoginActivateValue = false;
        setSystemInit((prevSystemInit: SystemInit | null) => ({
            ...prevSystemInit!,
            loginActivate: newLoginActivateValue,
        }));
    };
    const moveLoginRouter = () => {
        // Remove the accessToken cookie
        updateLoginDisabled();
        removeCookie("accessToken");
        // Clear data in localStorage
        localStorage.removeItem("systemInit");
        localStorage.removeItem("isToggle");
        localStorage.removeItem("loginResponse");
        movePage("/login");
    };
    const moveMainRouter = () => {
        movePage("/manager");
    };
    const moveSubRouter = () => {
        movePage("/");
    };

    const moveUserInfoRouter = () => {
        movePage("/management/public-master/user/info");
    };
    const moveSystemSettingRouter = () => {
        movePage("/management/public-master/system/init");
    };

    const [isDrawer, setIsDrawer] = useState(false);

    const width = useWidth();
    const allowedRoleCodes = [GENERAL, MANAGER, "ADMIN"];

    const userinfo_dropdown_item = [
        {
            key: "1",
            label: t("dropdown_userinfo"), // Use the translation function for labels
            onClick: moveUserInfoRouter, // Assuming moveUserInfoRouter is a defined function
            icon: <FaUserCog/>,
        },
        // {
        //   key: "2",
        //   label: t("dropdown_systemInit"),
        //   onClick: moveSystemSettingRouter,
        //   icon: <MdSettings />,
        //   // disabled: true,
        // },
        {
            key: "3",
            label: t("dropdown_logout"), // Translate the 'Logout' label
            onClick: moveLoginRouter, // Assuming moveLoginRouter is a defined function
            icon: <BiLogIn/>,
        },
    ];
    const handleHomeClick = () => {
        /**
         * HOME 버튼 클릭 이벤트
         * 1. 접근 타입에 따른 페이지 이동.
         * 2023-09-18
         * Writer: June9501
         * */
        if (loginResponse?.loginType === GENERAL) {
            // moveSubRouter();
            moveMainRouter();
        } else {
            moveMainRouter();

        }
    };
    const handleSwitchClick = () => {
        /**
         * 접근 타입 변경 이벤트 구인/구직
         * 1. 접근 타입에 따른 페이지 이동.
         * 2. 접근 타입 변경에 따른 정보 업데이트.
         * 2023-09-18
         * Writer: June9501
         * */
        if (loginResponse) {
            // Create a new loginResponse object with the toggled loginType
            const updatedUserInfo = {
                ...loginResponse,
                loginType:
                    loginResponse.loginType === GENERAL ? "SUB_CONTRACTOR_USER" : GENERAL,
            };
            // Update the loginResponse state with the new object
            setLoginResponse(updatedUserInfo);
            if (loginResponse?.loginType === GENERAL) {
                moveSubRouter();
            } else {
                moveMainRouter();
            }
        }
    };
    const [loginTypeChangeOpen, setLoginTypeChangeOpen] = useState(false);

    const handleOpenChange = (newOpen: boolean) => {
        /**
         * 접근 권한 변경에 대한 질의 메세지 오픈 이벤트
         * 2023-09-18
         * Writer: June9501
         * */
        if (!newOpen) {
            setLoginTypeChangeOpen(newOpen);
            return;
        }
        setLoginTypeChangeOpen(newOpen);
    };
    const confirm = () => {
        /**
         * 접근 권한 변경에 성공에 대한 메세지 이벤트
         * 1. 접근 권한 변경 완료에 대한 메세지 팝업 오픈
         * 2023-09-18
         * Writer: June9501
         * */
        setLoginTypeChangeOpen(false);
        handleSwitchClick();
        message.success(t("type_change_success"));
    };

    const cancel = () => {
        /**
         * 접근 권한 변경 취소 이벤트
         * 2023-09-18
         * Writer: June9501
         * */
        setLoginTypeChangeOpen(false);
    };

    const [isMenu, setIsMenu] = useState(false);
    const [hoveredRoute, setHoveredRoute] = useState<RouteType | null>(null);
    const [open, setOpen] = useState<string | null>(null);

    const drawerToggle = useCallback(() => {
        setIsMenu((prev) => !prev);
        if (isMenu) {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [isMenu]);

    return (
        <Wrapper
            zIndex="99"
            top={`0`}
            right={`0`}
            shadow="0px 2px 4px rgba(0, 0, 0, 0.1)" // Adding a subtle shadow
            bgColor={colorConfigs.basic.white} // A slightly different shade than white
            color={colorConfigs.basic.black}
            dr={`row`}
            ju={`space-between`}
            // height={width < 700 ? `54px` : `64px`}
            style={{
                height: isTablet ? '5vh' : '5vh', // 모바일일 때만 10vh, 아니면 OO
            }}
            padding={`5px 0`}
            borderBottom="1px solid rgba(0, 0, 0, 0.1)" // Adding a border at the bottom
        >
            <Wrapper
                dr={`row`}
                width={`auto`}
                padding={width < 700 ? `0 0 0 12px` : `0 0 0 24px`}
                onClick={moveMainRouter}
            >
                {width < 700 ? (
                    /**
                     * display width 값에 따른 로고 출력
                     * 1. width 700 미만일 시 Logo Icon.
                     * 2. width 700 이상일 시 Full Logo.
                     * 2023-09-18
                     * Writer: June9501
                     * */
                    <Image src={assets.images.logo} width={`30px`}/>
                ) : (
                    // <Image width={`150px`} src={assets.images.hejp_logo} />
                    // <Text className={"titlescript"}>{t("logo_title")}</Text>
                    <>
                        <Image
                            width={`30px`}
                            src={assets.images.logo}
                        />
                        <Text margin={`10px`} >{t("logo_title")}</Text>
                    </>
                )}
            </Wrapper>

            <Wrapper
                width={`auto`}
                dr={`row`}
                padding={width < 700 ? `0 12px 0 0` : `0 24px 0 0`}
            >
                {allowedRoleCodes.includes(loginResponse?.roleCode || "") &&
                systemInit?.loginActivate === true ? (
                    /**
                     * 로그인 타입 변경 스위치 및 확인 팝업 컴포넌트
                     * 1. 권한이 관리자(ADMIN), 원청사용자GENERAL
                     * 2. 로그인이 완료
                     * 일때 컴포넌트 출력
                     * 2023-09-18
                     * Writer: June9501
                     * */
                    <Popconfirm
                        title={t("type_change_message")}
                        // description= ""
                        open={loginTypeChangeOpen}
                        onOpenChange={handleOpenChange}
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Switch
                            checked={loginResponse?.loginType === GENERAL}
                            checkedChildren={t("breadcrumb_target_recruiting")}
                            unCheckedChildren={t("breadcrumb_target_job_seeking")}
                            style={{margin: 10}}
                            // onClick={handleSwitchClick}
                        />
                    </Popconfirm>
                ) : null}
                {allowedRoleCodes.includes(loginResponse?.roleCode || "") &&
                loginResponse?.loginType === GENERAL &&
                systemInit?.loginActivate === true ? (
                    /**
                     * 사이트바(Side-Bar) Expand Collapse 이벤트 버튼 컴포넌트
                     * 1. 권한이 관리자(ADMIN), 원청사용자GENERAL
                     * 2. 로그인 타입이GENERAL원청 사용자)
                     * 3. 로그인이 완료
                     * 일때 컴포넌트 출력
                     * - width 값이 700 미만일 시 별도의 사이드 바 오픈
                     * - width 값이 700 이상일 시 Expand Collapse에 따른 아이콘 변경
                     * 2023-09-18
                     * Writer: June9501
                     * */
                    width < 700 ? (
                        <Button size={`small`} onClick={() => setIsDrawer((prev) => !prev)}>
                            <AiOutlineMenuFold/>
                        </Button>
                    ) : (
                        <Button onClick={toggle} size={`small`}>
                            {isToggle ? <AiOutlineMenuFold/> : <AiOutlineMenuUnfold/>}
                        </Button>
                    )
                ) : null}
                &nbsp;
                {systemInit?.loginActivate === true ? (
                    /**
                     * 홈(대쉬보드) 이동 버튼 컴포넌트
                     * 1. 로그인이 완료 일때 컴포넌트 출력
                     * - 접근 타입에 따른 Dashboard 페이지 이동.
                     * 2023-09-18
                     * Writer: June9501
                     * */
                    <Button
                        size={width < 700 ? `small` : `small`}
                        onClick={handleHomeClick}
                    >
                        <AiFillHome/>
                    </Button>
                ) : null}
                {isMobile ? (null) : <>
                    {systemInit?.loginActivate === true ? (
                        <Space>
                            <Tag>
                                {loginResponse?.userName} 님 환영합니다.</Tag>
                        </Space>

                    ) : null}
                    &nbsp;
                    {systemInit?.loginActivate === true ? (
                        <Space>
                            {/*<Tag icon={<FaUserAlt/>}>*/}
                            <Tag icon={<SecurityScanOutlined/>} color="default">
                                {translatedText}
                            </Tag>
                        </Space>
                    ) : null}

                    &nbsp;
                    {systemInit?.loginActivate === true ? (
                        <Tag bordered={true} color="default">
                            <Dropdown menu={{items: userinfo_dropdown_item}}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {/*<Tag icon={<FaUserAlt/>}>*/}

                                        Quick-Menu
                                        <IoIosArrowDropdown/>
                                        {/*</Tag>*/}
                                    </Space>
                                </a>
                            </Dropdown>
                        </Tag>
                    ) : null}

                    &nbsp;
                    <Dropdown menu={{items: i18n_language_items, onClick: onMenuClick}}>
                        {/**
                         * 언어 설정(i18n) 드롭다운 컴포넌트
                         * - 국가 선택에 따른 정보 업데이트
                         * 2023-09-18
                         * Writer: June9501
                         * */}
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <Tag>
                                    &nbsp;
                                    {isLanguage?.language}
                                    &nbsp;
                                    <IoIosArrowDropdown/>
                                </Tag>
                            </Space>
                        </a>
                    </Dropdown>
                    &nbsp;
                </>}
                {/*<TimeViewer/>*/}
                {/*<Wrapper*/}
                {/*    dr={`row`}*/}
                {/*    ju={`flex-start`}*/}
                {/*    fontSize={`14px`}*/}
                {/*    margin={`0 0 5px`}*/}
                {/*>*/}
                {/*  <Text style={{color: Theme.black2_C}}>마지막 접속 일자 : </Text>*/}
                {/*  {moment.utc(loginResponse?.lastLoginDatetime).lang("ko").format(`YYYY-MM-DD HH:mm:ss (dd)`)}*/}
                {/*</Wrapper>*/}
                {/*<Wrapper*/}
                {/*    dr={`row`}*/}
                {/*    ju={`flex-start`}*/}
                {/*    fontSize={`14px`}*/}
                {/*    margin={`0 0 5px`}*/}
                {/*>*/}
                {/*  <Text style={{color: Theme.black2_C}}>사용자 이름 : </Text>*/}
                {/*</Wrapper>*/}

                {/*{systemInit?.loginActivate === true ? (*/}
                {/*  /***/}
                {/*   * 사용자 정보 표시 및 메뉴 드롭다운 컴포넌트*/}
                {/*   * - 1. 사용자 정보 화면 이동 메뉴*/}
                {/*   * - 2. N/A*/}
                {/*   * - 3. 사용자 로그아웃*/}
                {/*   * - 사용자 로그아웃 시 사용자 정보 및 쿠키 삭제*/}
                {/*   * 2023-09-18*/}
                {/*   * Writer: June9501*/}
                {/*   /*/}
                {/*  <Dropdown menu={{ items: userinfo_dropdown_item }}>*/}
                {/*    <a onClick={(e) => e.preventDefault()}>*/}
                {/*      <Space>*/}
                {/*        <Tag icon={<FaUserAlt />}>*/}
                {/*          &nbsp;*/}
                {/*          {loginResponse?.userName}*/}
                {/*          <Tag bordered={false} color="geekblue">*/}
                {/*            ( {loginResponse?.troleCode} )*/}
                {/*          </Tag>*/}
                {/*          &nbsp;*/}
                {/*          <IoIosArrowDropdown />*/}
                {/*        </Tag>*/}
                {/*      </Space>*/}
                {/*    </a>*/}
                {/*  </Dropdown>*/}
                {/*) : null}*/}
                {/*<RxDropdownMenu style={{ fontSize: `22px` }} onClick={drawerToggle} />*/}
            </Wrapper>

            {/**
             * width 700 미만일시 Side-bar 메뉴 컴포넌트
             * 2023-09-18
             * Writer: June9501
             * */}
            {/*<Drawer*/}
            {/*    open={isDrawer}*/}
            {/*    width={`100%`}*/}
            {/*    onClose={() => setIsDrawer((prev) => !prev)}*/}
            {/*    closeIcon={*/}
            {/*        <CgCloseR*/}
            {/*            style={{*/}
            {/*                position: "absolute",*/}
            {/*                zIndex: "99",*/}
            {/*                color: colorConfigs.basic.white,*/}
            {/*                fontSize: "22px",*/}
            {/*                top: "26px",*/}
            {/*                left: "12px",*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*>*/}
            {/*    <MobileMenu/>*/}
            {/*</Drawer>*/}
        </Wrapper>
    );
};

export default _topbar;
