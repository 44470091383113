import React, {useEffect, useRef, useState, useCallback} from 'react';
import {AttackMap, DataMapsWrapper} from 'react-typescript-datamaps';
import {geoMercator, geoPath} from 'd3-geo';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Badge, List, Space} from 'antd';
import topoJsonData from './dirJson/countries-110m-with-id.json';
import {coordinates} from './coordinates';
import {PositionMap, positionMapResponseState} from "../../../state/positionMap";
import {ArcItem} from "react-typescript-datamaps/build/ArcPlugin";

interface TopoJsonMapProps {
    latitude: number;
    longitude: number;
    zoomLevel: number;
    historyData: DashboardTravelHistoryResponse[];
    refetch: () => void;
}


interface DashboardTravelHistoryResponse {
    reactKey: string;
    seq: string;
    userId: string;
    thTitle: string | null;
    thSaddress: string | null;
    thSlatitude: string | null;
    thSlongitude: string | null;
    thEaddress: string;
    thEdetailAddress: string;
    thElatitude: string;
    thElongitude: string;
    thType: string;
    thEzoneKeyword: string;
    thGubun: string;
}

const TopoJsonGlobalDetailMap: React.FC<TopoJsonMapProps> = ({latitude,longitude, zoomLevel, historyData, refetch}) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [positionMapResponse, setPositionMapResponse] = useRecoilState(positionMapResponseState);
    const getPositionMapResponse = useRecoilValue(positionMapResponseState); // Get the Recoil state value

    const [hoveredGeo, setHoveredGeo] = useState<any>(null);
    // const [bubbles, setBubbles] = useState<DashboardTravelHistoryResponse[]>(initialBubbles);

    const hoveredGeoRef = useRef<any>(null);
    interface GeographyConfig {
        popupOnHover: boolean;
        highlightOnHover: boolean;
        borderColor: string;
        borderWidth: number;
        dataJson?: any;
        dataUrl?: any;
        popupTemplate: (geo: any, data: any) => string;
        onClick: (geo: any, event: any) => void;
        onMouseOver: (geo: any, event: any) => void;
    }

    interface BubbleConfig {
        borderWidth: number;
        borderOpacity: number;
        borderColor: string;
        popupOnHover: boolean;
        radius: number | null;
        popupTemplate: (geo: any, data: any) => string;
        fillOpacity: number;
        animate: boolean;
        highlightOnHover: boolean;
        highlightFillColor: string;
        highlightBorderColor: string;
        highlightBorderWidth: number;
        highlightBorderOpacity: number;
        highlightFillOpacity: number;
        exitDelay: number;
        key: (data: any) => string;
    }

    interface BubbleData {
        latitude: number;
        longitude: number;
        fillKey: string;
        radius: number;
        state: string;
        substate?: string;
        level?: number;
    }

    // 공격 경로 데이터 설정
    // const attacks: ArcItem[] = [
    //     {
    //         origin: {
    //             name: 'Seoul',
    //             latitude: 37.5665,
    //             longitude: 126.9780,
    //         },
    //         destination: {
    //             name: 'Busan',
    //             latitude: 35.1796,
    //             longitude: 129.0756,
    //         },
    //         options: {}, // 비워둔 상태로
    //     },
    //     {
    //         origin: {
    //             name: 'Seoul',
    //             latitude: 37.5665,
    //             longitude: 126.9780,
    //         },
    //         destination: {
    //             name: 'Jeju',
    //             latitude: 34.7604,
    //             longitude: 126.4407,
    //         },
    //         options: {}, // 비워둔 상태로
    //     },
    // ];


    const initialBubbles = historyData.map((item) => ({
        latitude: parseFloat(item.thElatitude || '0'),
        longitude: parseFloat(item.thElongitude || '0'),
        fillKey: item.thType || "defaultFill",
        radius: item.thType == "HOME" ? 6 : 4,
        state: item.thEaddress,
    }));

    const dynamicData = historyData.reduce((acc, item) => {
        let zoneKeyword = null;

        if (item.thGubun != "GLOBAL"){
            zoneKeyword = "South Korea";
        }else{
            zoneKeyword = item.thEzoneKeyword;
        }
        if (zoneKeyword) {
            // 동일한 zoneKeyword가 처음 등장한 경우
            if (!acc[zoneKeyword]) {
                acc[zoneKeyword] = { fillKey: item.thType + "_A" || "defaultFill" };
            }
            // 동일한 zoneKeyword가 존재하고, thType이 다른 경우에만 색상 변경
            else if (acc[zoneKeyword].fillKey.startsWith(item.thType + "_A") === false) {
                acc[zoneKeyword] = { fillKey: "duplicationFill" };
            }
            // 이후 등장 시, thType이 동일하면 변경하지 않음
        }
        return acc;
    }, {} as Record<string, { fillKey: string }>);


    const [bubbles, setBubbles] = useState<any[]>(initialBubbles);


    useEffect(() => {
        // Update bubbles when historyData changes or when refetch is called
        setBubbles(initialBubbles);
    }, [historyData, refetch]); // Include refetch as a dependency to trigger reload

    useEffect(() => {
        hoveredGeoRef.current = hoveredGeo;
    }, [hoveredGeo]);

    const handleMouseOver = useCallback((geo: any) => {
        if (!hoveredGeoRef.current || hoveredGeoRef.current.properties.code !== geo.properties.code) {
            setHoveredGeo(geo);
        }
    }, []);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (hoveredGeoRef.current) {
                const coords = coordinates[hoveredGeoRef.current.properties.code] || {latitude: '', longitude: ''};
                const newPositionMap: PositionMap = {
                    locationName: hoveredGeoRef.current.properties.name,
                    locationCode: hoveredGeoRef.current.properties.code,
                    Latitude: coords.latitude,
                    Longitude: coords.longitude,
                    existenceAndNonexistence: bubbles.some(
                        bubble => bubble.thElatitude === parseFloat(String(coords.latitude)) &&
                            bubble.thElongitude === parseFloat(String(coords.longitude)) ||
                            bubble.thEaddress === hoveredGeoRef.current.properties.name
                    ),
                    fillType: hoveredGeoRef.current.properties.fillKey
                };

                // Check if the coordinates already exist in the bubbles
                const existingBubbleIndex = bubbles.findIndex(
                    bubble => bubble.thElatitude === parseFloat(String(newPositionMap.Latitude)) &&
                        bubble.thElongitude === parseFloat(String(newPositionMap.Longitude))
                );

                if (existingBubbleIndex >= 0) {
                    // Update the existing bubble
                    const updatedBubbles = [...bubbles];
                    updatedBubbles[existingBubbleIndex] = {
                        ...updatedBubbles[existingBubbleIndex],
                        fillKey: 'CURRENT',
                        radius: 10,
                    };
                    setBubbles(updatedBubbles);
                } else {
                    // Add a new bubble
                    setBubbles([
                        ...bubbles,
                        {
                            latitude: parseFloat(String(newPositionMap.Latitude)),
                            longitude: parseFloat(String(newPositionMap.Longitude)),
                            fillKey: 'CURRENT',
                            radius: 10,
                            state: newPositionMap.locationName,
                        }
                    ]);
                }

                setPositionMapResponse(newPositionMap);
            }
        };

        const handleMouseLeave = () => {
            setHoveredGeo(null);
        };

        const mapElement = mapRef.current;
        if (mapElement) {
            mapElement.addEventListener('click', handleClick);
            mapElement.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (mapElement) {
                mapElement.removeEventListener('click', handleClick);
                mapElement.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);


    const handleMapClick = (geo: any, event: any) => {
        console.log("Map click detected:", geo.properties.name);
        const coords = coordinates[geo.properties.code] || {latitude: '', longitude: ''};
        const newPositionMap: PositionMap = {
            locationName: geo.properties.name,
            locationCode: geo.properties.code,
            Latitude: coords.latitude,
            Longitude: coords.longitude,
            existenceAndNonexistence: bubbles.some(
                bubble => bubble.latitude === parseFloat(String(coords.latitude)) &&
                    bubble.longitude === parseFloat(String(coords.longitude)) ||
                    bubble.state === hoveredGeoRef.current.properties.name
            ),
            fillType: hoveredGeoRef.current.properties.fillKey
        };
        setPositionMapResponse(newPositionMap);
    };

    const demoProps = {
        scope: 'countries',
        geographyConfig: {
            popupOnHover: true,
            highlightOnHover: true,
            borderColor: '#444',
            borderWidth: 0.5,
            dataJson: topoJsonData,
            // dataUrl: "https://overcomplex.co.kr/files/fishing_ta_gram/public/koreaStopoJson.json",
            popupTemplate: (geo: any, data: any) => {
                const properties = geo.properties;
                const code = properties.code;
                const coords = coordinates[code];
                // handleMouseOver(geo); // Call the optimized handleMouseOver function
                return `<div class="hoverinfo">
                    <strong>Region: ${properties.name}</strong><br/>
                    Code: ${properties.code}<br/>
                    Latitude: ${coords ? coords.latitude : 'N/A'}<br/>
                    Longitude: ${coords ? coords.longitude : 'N/A'}
                </div>`;
            },
            onClick: handleMapClick, // Set the onClick handler here

        } as GeographyConfig,

        bubblesConfig: {
            borderWidth: 1,
            borderOpacity: 1,
            borderColor: '#FFFFFF',
            popupOnHover: true,
            radius: 3,
            popupTemplate: (geo: any, data: any) => {
                return `<div class="hoverinfo">
                            <strong>주소: ${data.state}</strong><br/>
                            카테고리: ${data.fillKey}<br/>
                        </div>`;
            },
            fillOpacity: 0.75,
            animate: true,
            highlightOnHover: true,
            highlightFillColor: '#FC8D59',
            highlightBorderColor: 'rgba(250, 15, 160, 0.2)',
            highlightBorderWidth: 2,
            highlightBorderOpacity: 1,
            highlightFillOpacity: 0.85,
            exitDelay: 100,
            key: JSON.stringify,
        } as BubbleConfig,

        fills: {
            HOME: '#fa0404',
            CURRENT: '#53BE28FF',
            PREDICTED: '#7128be',
            FISHING: '#0e7fe5',
            TRAVEL: `#ff6347`,
            CNF: `#567756`,
            FOOD: `#e8b326`,
            CURRENT_A: 'rgba(83,190,40,0.66)',
            PREDICTED_A: 'rgba(113,40,190,0.63)',
            FISHING_A: 'rgba(14,127,229,0.65)',
            TRAVEL_A: `rgba(255, 99, 71, 0.73)`,
            CNF_A: `rgba(86, 119, 86, 0.73)`,
            FOOD_A: `rgba(232, 179, 38, 0.75)`,
            defaultFill: '#dddddd',
            REGION_A: '#efabab',  // 지역 A 배경색 예시
            REGION_B: '#654321',  // 지역 B 배경색 예시
            duplicationFill: 'rgba(27,250,116,0.73)'
        },
        data: dynamicData, // thEzoneKeyword 값을 기반으로 생성된 data 객체를 사용

        // data: {
        //     안성시: { fillKey: "REGION_A" },
        // },
        setProjection: (element: { offsetWidth: number; offsetHeight: number; }) => {
            const projection = geoMercator()
                // .center([80, 25])
                .center([longitude,latitude])
                .scale(zoomLevel) // Use zoomLevel from props
                .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
            const path = geoPath().projection(projection);
            return {path, projection};
        },
    };

    return (
        <div style={{width: '100%', height: '100%', position: 'relative'}} className="topoMap" ref={mapRef}>
            <DataMapsWrapper {...demoProps} bubbles={bubbles}/>
            {/*<AttackMap {...demoProps} attacks={attacks} />*/}
            {/*<AttackMap data={attacks} demoMode={true} hideTicker={true} />*/}
        </div>
    );
};

export default TopoJsonGlobalDetailMap;

