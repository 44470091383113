import { instance } from "../../../../configs/axiosConfig";
import codeDefine from "../../../../defined/codeDefine";
import { LoadNotification } from "../../../../components/common/Notification/_notification";
import {LoginResponse, UserInfo} from "../../../../state/userinfo";
import {SystemInit} from "../../../../state/systemInit";

const registerUser = (
    data: any,
    navigate: Function
   ) => {
    const moveLoginRouter = () => {
        navigate('/');
    };
    instance
        .post(`/api/v1/auth/user/register`, data, { withCredentials: true })
        .then((response) => {
            const responseData = response.data.result;
            const someDateInTheFuture = new Date();
            // if(responseData.resultCode === "200"){
            //     LoadNotification({msg: "알림", content: responseData.userInfo.userName + " Login Success"});
            //     moveLoginRouter()
            // }else{
            // }

            LoadNotification({msg: "알림", content: response.data.resultMsg});
            moveLoginRouter()
        })
        .catch((error) => {
            LoadNotification({msg: "Error", content: codeDefine.axiosResponseMessages.error});
            if (error.response) {
                const errorResponseData = error.response.data;
                LoadNotification({msg: "Error", content: errorResponseData});
            } else if (error.request) {
                LoadNotification({msg: "Error", content: codeDefine.axiosResponseMessages.networkError});
            } else {
                LoadNotification({msg: "Error", content: codeDefine.axiosResponseMessages.unexpectedError});
                console.error("Unexpected error:", error.message);
            }
        });
};

export {
    registerUser
};
