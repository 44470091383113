import {api, instance} from "../../../../../configs/axiosConfig";
import codeDefine from "../../../../../defined/codeDefine";
import {LoadNotification} from "../../../../../components/common/Notification/_notification";
import * as CryptoJS from "crypto-js";
import {cryptoKey} from "../../../../../utils/cryptoManagement";



const getDashboardDataSearch = async (token: string, setCookie: Function, removeCookie: Function, thType: string, thGubun: string, thStartDatetime:string, thEndDatetime:string) => {

    const response = await api.get(`/api/v1/mDashboard/admin/?thType=${thType}&thGubun=${thGubun}&thStartDatetime=${thStartDatetime}&thEndDatetime=${thEndDatetime}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    let data = response.data;

    if (data.code === codeDefine.customErrorCode.HJ_9999) {
        const responseData = response.data;
        const encryptedAccessToken = CryptoJS.AES.encrypt(responseData.accessToken, cryptoKey).toString();
        const someDateInTheFuture = new Date();
        someDateInTheFuture.setDate(someDateInTheFuture.getDate() + 1);  // Adds 1 days
        removeCookie('accessToken');
        setCookie('accessToken', encryptedAccessToken, { path: '/', expires: someDateInTheFuture });
        // Adding new key-value pair to the data object
        data = { ...data, setComent: "accessToken Re Register" };
        // Refresh the page
        window.location.reload();
    }
    return data;
}



const getDashboardDetailDataSearch = async (token: string, setCookie: Function, removeCookie: Function, travelHistoryId: string) => {

    const response = await api.get(`/api/v1/mDashboard/admin/detail`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            travelHistoryId: travelHistoryId,
        },
    });
    let data = response.data;

    if (data.code === codeDefine.customErrorCode.HJ_9999) {
        const responseData = response.data;
        const encryptedAccessToken = CryptoJS.AES.encrypt(responseData.accessToken, cryptoKey).toString();
        const someDateInTheFuture = new Date();
        someDateInTheFuture.setDate(someDateInTheFuture.getDate() + 1);  // Adds 1 days
        removeCookie('accessToken');
        setCookie('accessToken', encryptedAccessToken, { path: '/', expires: someDateInTheFuture });
        // Adding new key-value pair to the data object
        data = { ...data, setComent: "accessToken Re Register" };
        // Refresh the page
        window.location.reload();
    }
    return data;
}

export {
    getDashboardDataSearch,
    getDashboardDetailDataSearch
};
