import {
    Avatar,
    Breadcrumb,
    Card,
    Carousel,
    Col,
    Divider,
    Empty,
    Layout,
    List,
    Row,
    Space,
    Input, Statistic, Button, DatePicker, Pagination,
} from "antd";

import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {
    Image,
    Text,
    Wrapper,
} from "../../../components/common/commonComponents";
import {IResponseProps} from "./_model/model";
import {useCookies} from "react-cookie";
import {getDashboardDataSearch} from "../../_main_contractor/_dashboardMgmt/_service/_admin/service";
import {useRecoilValue} from "recoil";
import {loginResponseState} from "../../../state/userinfo";
import colorConfigs from "../../../configs/colorConfigs";
import {useTranslation} from "react-i18next";

import useWidth from "../../../components/common/useWidth";

import {decryptToken} from "../../../utils/tokenManagement";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CalendarOutlined,
    LikeOutlined,
    PullRequestOutlined, SearchOutlined
} from "@ant-design/icons";
import {MdOutlineFactory} from "react-icons/md";
import Theme from "../../../components/common/Theme";
import {
    Bar,
    BarChart,
    CartesianGrid, LabelList,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const {Content} = Layout;

type Props = {};
type charProps = {};

interface DescriptionsProps {
    key: string;
    userImage?: string;
    userName: string;
    userPage?: string;
    children: React.ReactNode; // You can use React.ReactNode for varying children types
    span?: number; // Optional property
}

const SubConstractorDashboardIndex = (props: Props) => {
    const ITEMS_PER_PAGE = 6;


    const movePage = useNavigate();
    const moveHistory = useCallback((siteId: string, siteName: string) => {
        movePage(`/general/workplace/detail?sid=${siteId}&siteName=${siteName}`);
    }, [movePage]);
    const {t} = useTranslation("mDashboard");
    const width = useWidth();

    const {TextArea} = Input;
    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

    const {Meta} = Card;
    const token = cookies.accessToken;
    const {data, isLoading, isError, error, refetch} = useQuery<IResponseProps>(
        ["data"],
        () => getDashboardDataSearch(decryptToken(token), setCookie, removeCookie,"*","*",'2024-12-31','2024-12-31'),
        {refetchOnWindowFocus: true}
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const siteInfoData = [
        {
            siteCode: 'SID_ICA1001',
            siteName: '인천 공장',
            errorCount: 4000,
            processing: 2400,
        },
        {
            siteCode: 'SID_ASA2001',
            siteName: '안성 공장',
            errorCount: 3000,
            processing: 1398,
        },
        {
            siteCode: 'SID_SWA3001',
            siteName: '수원 공장',
            errorCount: 2000,
            processing: 8,
        },
        {
            siteCode: 'SID_SWA3002',
            siteName: '수원-1 공장',
            errorCount: 2780,
            processing: 3908,
        },
    ];

    interface LabelProps {
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        value: string;
    }

    const renderCustomizedLabel = (props: any) => { // Consider using a more specific type here if available.
        // Extract or default the values to ensure they're numbers.
        const {x, y, width, height, value} = props;
        const radius = 10;

        // Safely handle potential undefined values now that props are properly typed.
        if (typeof x !== 'number' || typeof y !== 'number' || typeof width !== 'number' || typeof height !== 'number') {
            return null; // Handle as needed for your use case.
        }

        return (
            <g>
                <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8"/>
                <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {value.split(' ')[1]}
                </text>
            </g>
        );
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const dataList = Array.from({length: 23}).map((_, i) => ({
        href: "https://ant.design",
        title: `작업명 ${i + 1}`,
        avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        content:
            "작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다. 작업의 설명이 들어가는 곳입니다.",
    }));
    useEffect(() => {
    }, []); // Empty dependency array means this runs once on mount
    // const pageDetailChange = (siteName: string) => {
    //     movePage(`/site/A1001`);
    // };
    const IconText = ({icon, text}: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );


    // 데이터 조회 로딩
    // if (isLoading) {
    //     return <Spin/>;
    // }
    // 데이터 조회 에러
    //   if (isError) {
    //     return (
    //       <Content
    //         style={{
    //           padding: 14,
    //           margin: 0,
    //           minHeight: 280,
    //           // background: Theme.basicTheme_C,
    //         }}
    //       >
    //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //       </Content>
    //     );
    //   }
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = siteInfoData.slice(indexOfFirstItem, indexOfLastItem);

    const handleChangePage = (page: React.SetStateAction<number>) => {
        setCurrentPage(page);
    };

    return (
        <Wrapper>
            <Layout
                style={{
                    padding: "0 10px 0 10px",
                    width: "100%",
                    height: `100%`,
                }}
            >
                <Breadcrumb style={{margin: "0px 0px 10px 0px"}}>
                    <Breadcrumb.Item>
                        {t("breadcrumb_status")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("breadcrumb_page")}</Breadcrumb.Item>
                </Breadcrumb>
                <Wrapper
                    display={`flex`}
                    dr={`column`}
                    ju={`flex-start`} al={`space-between`}
                >
                    <Row gutter={[36, 24]}>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Registered Workplace"
                                value={4}
                                prefix={<MdOutlineFactory/>}
                                valueStyle={{color: '#3f8600'}}
                            />
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic title="Number of errors encountered" value={93} suffix="/ 100"/>
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Number of unhandled errors"
                                value={11.28}
                                precision={2}
                                valueStyle={{color: '#3f8600'}}
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                        </Col>
                        <Col span={6} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Statistic
                                title="Error Rate"
                                value={9.3}
                                precision={2}
                                valueStyle={{color: '#cf1322'}}
                                prefix={<ArrowDownOutlined/>}
                                suffix="%"
                            />
                        </Col>
                    </Row>
                </Wrapper>
                <Divider orientation="left"/>
                <Row gutter={[36, 24]} style={{padding: "0 24px 24px"}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Breadcrumb style={{margin: "16px 0"}}>
                            <Breadcrumb.Item>Site</Breadcrumb.Item>
                            <Breadcrumb.Item>Workplace-List</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider orientation="left">Workplace Content</Divider>
                        <Wrapper
                            display={`flex`}
                            dr={`column`}
                            ju={`flex-start`} al={`space-between`}
                            padding={`32px 16px`}
                            // height={`120px`}
                            minHeight={`300px`}
                            radius={`25px`}
                            overflow={`hidden`}
                            shadow={`0 2px 4px rgba(0, 0, 0, 0.1)`}
                            bgColor={colorConfigs.basic.white}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <BarChart
                                        width={400}
                                        height={300}
                                        data={siteInfoData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Bar dataKey="errorCount" fill="#8884d8" minPointSize={5}>
                                            {/*<LabelList dataKey="name"  content={renderCustomizedLabel}/>*/}
                                        </Bar>
                                        <Bar dataKey="processing" fill="#82ca9d" minPointSize={10}/>
                                    </BarChart>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                                    <Row gutter={[16, 24]}>
                                        {currentItems.map((item, index) => (
                                            <Col key={index} xs={24} sm={24} md={24} lg={24} xl={8}>
                                                <Card
                                                    style={{
                                                        backgroundColor: Theme.lightColor,
                                                        border: "1px solid #f0f0f0",
                                                        minHeight: '120px',
                                                        maxHeight: '120px',
                                                    }}
                                                    hoverable
                                                    title={item.siteName}
                                                    onClick={() => moveHistory(item.siteCode, item.siteName)}
                                                >
                                                    <Meta description={`EC: ${item.errorCount}, PC: ${item.processing}`} />
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Pagination
                                        current={currentPage}
                                        onChange={handleChangePage}
                                        pageSize={ITEMS_PER_PAGE}
                                        total={siteInfoData.length}
                                        // Optionally customize the pagination component to fit your layout
                                        style={{ marginTop: 20, textAlign: 'center' }}
                                    />
                                </Col>
                            </Row>
                        </Wrapper>
                    </Col>
                </Row>
            </Layout>
        </Wrapper>
    );
};

export default SubConstractorDashboardIndex;
