import { atom, useRecoilValue } from "recoil";
import * as CryptoJS from "crypto-js";
import { cryptoKey } from "../utils/cryptoManagement";

export type SystemInit = {
  loginActivate: boolean;
  mainContractorModule: SystemMainContractorModule;
  subContractorModule: SystemSubContractorModule;
};

export type SystemMainContractorModule = {
  uid: string;
};

export type SystemSubContractorModule = {
  uid: string;
};

export const systemInitState = atom<SystemInit | null>({
  key: "systemInit",
  default: null,
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      const encryptedValue = localStorage.getItem("systemInit");
      if (encryptedValue != null) {
        // 로컬 스토리지에서 암호화된 값을 가져오고 복호화합니다.
        const decryptedValue = CryptoJS.AES.decrypt(
          encryptedValue,
          cryptoKey
        ).toString(CryptoJS.enc.Utf8);
        if (decryptedValue) {
          setSelf(JSON.parse(decryptedValue));
        }
      }

      onSet((newValue) => {
        if (newValue == null) {
          localStorage.removeItem("systemInit");
        } else {
          // 값을 암호화하여 로컬 스토리지에 저장합니다.
          const encryptedValue = CryptoJS.AES.encrypt(
            JSON.stringify(newValue),
            cryptoKey
          ).toString();
          localStorage.setItem("systemInit", encryptedValue);
        }
      });
    },
  ],
});

export type IsToggleAtom = {
  toggle: boolean;
};

export type IsLanguage = {
  language: string;
};

export const isToggleAtomState = atom<IsToggleAtom | null>({
  key: "isToggle",
  default: null,
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      const encryptedValue = localStorage.getItem("isToggle");
      if (encryptedValue != null) {
        // 로컬 스토리지에서 암호화된 값을 가져오고 복호화합니다.
        const decryptedValue = CryptoJS.AES.decrypt(
          encryptedValue,
          cryptoKey
        ).toString(CryptoJS.enc.Utf8);
        if (decryptedValue) {
          setSelf(JSON.parse(decryptedValue));
        }
      }

      onSet((newValue) => {
        if (newValue == null) {
          localStorage.removeItem("isToggle");
        } else {
          // 값을 암호화하여 로컬 스토리지에 저장합니다.
          const encryptedValue = CryptoJS.AES.encrypt(
            JSON.stringify(newValue),
            cryptoKey
          ).toString();
          localStorage.setItem("isToggle", encryptedValue);
        }
      });
    },
  ],
});

export const isLanguageState = atom<IsLanguage | null>({
  key: "isLanguage",
  default: null,
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      const encryptedValue = localStorage.getItem("isLanguage");
      if (encryptedValue != null) {
        // 로컬 스토리지에서 암호화된 값을 가져오고 복호화합니다.
        const decryptedValue = CryptoJS.AES.decrypt(
          encryptedValue,
          cryptoKey
        ).toString(CryptoJS.enc.Utf8);
        if (decryptedValue) {
          setSelf(JSON.parse(decryptedValue));
        }
      }

      onSet((newValue) => {
        if (newValue == null) {
          localStorage.removeItem("isLanguage");
        } else {
          // 값을 암호화하여 로컬 스토리지에 저장합니다.
          const encryptedValue = CryptoJS.AES.encrypt(
            JSON.stringify(newValue),
            cryptoKey
          ).toString();
          localStorage.setItem("isLanguage", encryptedValue);
        }
      });
    },
  ],
});

export function useLanguage() {
  return useRecoilValue(isLanguageState);
}
