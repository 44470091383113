export const coordinates: { [key: string]: { latitude: number; longitude: number } } = {
    '11010': { latitude: 37.5729503, longitude: 126.9793579 }, // 종로구
    '11020': { latitude: 37.5638303, longitude: 126.9974692 }, // 중구
    '11030': { latitude: 37.5311008, longitude: 126.9785894 }, // 용산구
    '11040': { latitude: 37.5611000, longitude: 127.0384027 }, // 성동구
    '11050': { latitude: 37.5481445, longitude: 127.0857528 }, // 광진구
    '11060': { latitude: 37.574368, longitude: 127.040901 }, // 동대문구
    '11070': { latitude: 37.606324, longitude: 127.092874 }, // 중랑구
    '11080': { latitude: 37.589412, longitude: 127.016703 }, // 성북구
    '11090': { latitude: 37.639994, longitude: 127.025585 }, // 강북구
    '11100': { latitude: 37.668800, longitude: 127.047651 }, // 도봉구
    '11110': { latitude: 37.654020, longitude: 127.056790 }, // 노원구
    '11120': { latitude: 37.617612, longitude: 126.922700 }, // 은평구
    '11130': { latitude: 37.579115, longitude: 126.936778 }, // 서대문구
    '11140': { latitude: 37.566324, longitude: 126.901636 }, // 마포구
    '11150': { latitude: 37.523702, longitude: 126.865676 }, // 양천구
    '11160': { latitude: 37.551130, longitude: 126.849403 }, // 강서구
    '11170': { latitude: 37.495985, longitude: 126.887351 }, // 구로구
    '11180': { latitude: 37.456872, longitude: 126.895183 }, // 금천구
    '11190': { latitude: 37.526488, longitude: 126.896288 }, // 영등포구
    '11200': { latitude: 37.512383, longitude: 126.939958 }, // 동작구
    '11210': { latitude: 37.478406, longitude: 126.951613 }, // 관악구
    '11220': { latitude: 37.483576, longitude: 127.032693 }, // 서초구
    '11230': { latitude: 37.517305, longitude: 127.047502 }, // 강남구
    '11240': { latitude: 37.514571, longitude: 127.106232 }, // 송파구
    '11250': { latitude: 37.530193, longitude: 127.123792 }, // 강동구
    '21010': { latitude: 35.166111, longitude: 129.035778 }, // 중구 (부산)
    '21020': { latitude: 35.181448, longitude: 129.020748 }, // 서구 (부산)
    '21030': { latitude: 35.127507, longitude: 129.040383 }, // 동구 (부산)
    '21040': { latitude: 35.088406, longitude: 129.071297 }, // 영도구
    '21050': { latitude: 35.157617, longitude: 129.059595 }, // 부산진구
    '21060': { latitude: 35.205093, longitude: 129.083541 }, // 동래구
    '21070': { latitude: 35.136061, longitude: 129.086487 }, // 남구 (부산)
    '21080': { latitude: 35.198362, longitude: 128.990513 }, // 북구 (부산)
    '21090': { latitude: 35.163168, longitude: 129.163529 }, // 해운대구
    '21100': { latitude: 35.10462, longitude: 128.92823 }, // 사하구
    '21110': { latitude: 35.24235, longitude: 129.09203 }, // 금정구
    '21120': { latitude: 35.212323, longitude: 128.980756 }, // 강서구 (부산)
    '21130': { latitude: 35.18603, longitude: 129.08506 }, // 연제구
    '21140': { latitude: 35.142565, longitude: 129.114188 }, // 수영구
    '21150': { latitude: 35.150824, longitude: 128.988151 }, // 사상구
    '21310': { latitude: 35.25200, longitude: 129.21359 }, // 기장군
    '22010': { latitude: 35.86961, longitude: 128.60178 }, // 중구 (대구)
    '22020': { latitude: 35.88223, longitude: 128.63077 }, // 동구 (대구)
    '22030': { latitude: 35.87198, longitude: 128.59013 }, // 서구 (대구)
    '22040': { latitude: 35.85312, longitude: 128.56585 }, // 남구 (대구)
    '22050': { latitude: 35.88537, longitude: 128.58351 }, // 북구 (대구)
    '22060': { latitude: 35.85851, longitude: 128.63402 }, // 수성구
    '22070': { latitude: 35.83178, longitude: 128.53269 }, // 달서구
    '22310': { latitude: 35.77447, longitude: 128.43189 }, // 달성군
    '23010': { latitude: 37.47355, longitude: 126.62293 }, // 중구 (인천)
    '23020': { latitude: 37.47582, longitude: 126.68281 }, // 동구 (인천)
    '23030': { latitude: 37.49347, longitude: 126.69018 }, // 미추홀구
    '23040': { latitude: 37.51869, longitude: 126.67648 }, // 연수구
    '23050': { latitude: 37.44727, longitude: 126.73125 }, // 남동구
    '23060': { latitude: 37.50764, longitude: 126.72122 }, // 부평구
    '23070': { latitude: 37.53884, longitude: 126.73734 }, // 계양구
    '23080': { latitude: 37.57993, longitude: 126.66992 }, // 서구 (인천)
    '23310': { latitude: 37.74691, longitude: 126.48564 }, // 강화군
    '23320': { latitude: 37.44593, longitude: 126.65991 }, // 옹진군
    '24010': { latitude: 35.15404, longitude: 126.90812 }, // 동구 (광주)
    '24020': { latitude: 35.13327, longitude: 126.89111 }, // 서구 (광주)
    '24030': { latitude: 35.13054, longitude: 126.90252 }, // 남구 (광주)
    '24040': { latitude: 35.17436, longitude: 126.91943 }, // 북구 (광주)
    '24050': { latitude: 35.13931, longitude: 126.79031 }, // 광산구
    '25010': { latitude: 36.33046, longitude: 127.44613 }, // 동구 (대전)
    '25020': { latitude: 36.32145, longitude: 127.42354 }, // 중구 (대전)
    '25030': { latitude: 36.28201, longitude: 127.38822 }, // 서구 (대전)
    '25040': { latitude: 36.36245, longitude: 127.32855 }, // 유성구
    '25050': { latitude: 36.34558, longitude: 127.41519 }, // 대덕구
    '26010': { latitude: 35.53979, longitude: 129.31289 }, // 중구 (울산)
    '26020': { latitude: 35.53881, longitude: 129.32034 }, // 남구 (울산)
    '26030': { latitude: 35.54226, longitude: 129.42774 }, // 동구 (울산)
    '26040': { latitude: 35.58221, longitude: 129.36151 }, // 북구 (울산)
    '26310': { latitude: 35.56239, longitude: 129.24432 }, // 울주군
    '29010': { latitude: 36.480351, longitude: 127.289432 }, // 세종시
    '31011': { latitude: 37.30103, longitude: 127.00954 }, // 수원시 장안구
    '31012': { latitude: 37.25794, longitude: 127.03422 }, // 수원시 권선구
    '31013': { latitude: 37.27993, longitude: 127.00845 }, // 수원시 팔달구
    '31014': { latitude: 37.24078, longitude: 127.05071 }, // 수원시 영통구
    '31021': { latitude: 37.44562, longitude: 127.14225 }, // 성남시 수정구
    '31022': { latitude: 37.41167, longitude: 127.12432 }, // 성남시 중원구
    '31023': { latitude: 37.38436, longitude: 127.12185 }, // 성남시 분당구
    '31030': { latitude: 37.74197, longitude: 127.03467 }, // 의정부시
    '31041': { latitude: 37.40132, longitude: 126.9229 }, // 안양시 만안구
    '31042': { latitude: 37.39425, longitude: 126.97155 }, // 안양시 동안구
    '31050': { latitude: 37.50328, longitude: 126.76624 }, // 부천시
    '31060': { latitude: 37.47821, longitude: 126.86472 }, // 광명시
    '31070': { latitude: 36.99210, longitude: 127.11272 }, // 평택시
    '31080': { latitude: 37.90362, longitude: 127.06173 }, // 동두천시
    '31091': { latitude: 37.30155, longitude: 126.83176 }, // 안산시 상록구
    '31092': { latitude: 37.30978, longitude: 126.82285 }, // 안산시 단원구
    '31101': { latitude: 37.64069, longitude: 126.83189 }, // 고양시 덕양구
    '31103': { latitude: 37.67857, longitude: 126.74727 }, // 고양시 일산동구
    '31104': { latitude: 37.68103, longitude: 126.71853 }, // 고양시 일산서구
    '31110': { latitude: 37.43038, longitude: 126.99692 }, // 과천시
    '31120': { latitude: 37.59435, longitude: 127.12906 }, // 구리시
    '31130': { latitude: 37.63609, longitude: 127.21490 }, // 남양주시
    '31140': { latitude: 37.14764, longitude: 127.06629 }, // 오산시
    '31150': { latitude: 37.38067, longitude: 126.80316 }, // 시흥시
    '31160': { latitude: 37.36205, longitude: 126.93579 }, // 군포시
    '31170': { latitude: 37.34400, longitude: 126.96822 }, // 의왕시
    '31180': { latitude: 37.54080, longitude: 127.21479 }, // 하남시
    '31191': { latitude: 37.23944, longitude: 127.17755 }, // 용인시 처인구
    '31192': { latitude: 37.27059, longitude: 127.11457 }, // 용인시 기흥구
    '31193': { latitude: 37.32442, longitude: 127.09598 }, // 용인시 수지구
    '31200': { latitude: 37.76114, longitude: 126.77438 }, // 파주시
    '31210': { latitude: 37.27982, longitude: 127.44239 }, // 이천시
    '31220': { latitude: 37.00699, longitude: 127.28339 }, // 안성시
    '31230': { latitude: 37.63198, longitude: 126.71575 }, // 김포시
    '31240': { latitude: 37.19955, longitude: 126.83142 }, // 화성시
    '31250': { latitude: 37.40938, longitude: 127.25723 }, // 광주시
    '31260': { latitude: 37.78504, longitude: 127.04579 }, // 양주시
    '31270': { latitude: 37.89487, longitude: 127.20026 }, // 포천시
    '31280': { latitude: 37.29564, longitude: 127.63587 }, // 여주시
    '31350': { latitude: 38.09662, longitude: 127.07448 }, // 연천군
    '31370': { latitude: 37.83102, longitude: 127.50945 }, // 가평군
    '31380': { latitude: 37.49182, longitude: 127.48755 }, // 양평군
    '32010': { latitude: 37.88132, longitude: 127.72982 }, // 춘천시
    '32020': { latitude: 37.34218, longitude: 127.92019 }, // 원주시
    '32030': { latitude: 37.75185, longitude: 128.87791 }, // 강릉시
    '32040': { latitude: 37.52470, longitude: 129.11425 }, // 동해시
    '32050': { latitude: 37.16445, longitude: 128.98553 }, // 태백시
    '32060': { latitude: 38.20702, longitude: 128.59113 }, // 속초시
    '32070': { latitude: 37.44163, longitude: 129.16550 }, // 삼척시
    '32310': { latitude: 37.69129, longitude: 127.88801 }, // 홍천군
    '32320': { latitude: 37.46813, longitude: 127.98918 }, // 횡성군
    '32330': { latitude: 37.18382, longitude: 128.46115 }, // 영월군
    '32340': { latitude: 37.37052, longitude: 128.39027 }, // 평창군
    '32350': { latitude: 37.38030, longitude: 128.66222 }, // 정선군
    '32360': { latitude: 38.14674, longitude: 127.31439 }, // 철원군
    '32370': { latitude: 38.10607, longitude: 127.70893 }, // 화천군
    '32380': { latitude: 38.10552, longitude: 128.02564 }, // 양구군
    '32390': { latitude: 38.06975, longitude: 128.17071 }, // 인제군
    '32400': { latitude: 38.37826, longitude: 128.46753 }, // 고성군
    '32410': { latitude: 38.07536, longitude: 128.61898 }, // 양양군
    '33020': { latitude: 36.99597, longitude: 127.92502 }, // 충주시
    '33030': { latitude: 37.13235, longitude: 128.19061 }, // 제천시
    '33041': { latitude: 36.62537, longitude: 127.49218 }, // 청주시 상당구
    '33042': { latitude: 36.64388, longitude: 127.48288 }, // 청주시 서원구
    '33043': { latitude: 36.63772, longitude: 127.42242 }, // 청주시 흥덕구
    '33044': { latitude: 36.67499, longitude: 127.48084 }, // 청주시 청원구
    '33320': { latitude: 36.49294, longitude: 127.73334 }, // 보은군
    '33330': { latitude: 36.30756, longitude: 127.56969 }, // 옥천군
    '33340': { latitude: 36.17505, longitude: 127.77591 }, // 영동군
    '33350': { latitude: 36.85954, longitude: 127.43486 }, // 진천군
    '33360': { latitude: 36.81500, longitude: 127.66821 }, // 괴산군
    '33370': { latitude: 36.94028, longitude: 127.66071 }, // 음성군
    '33380': { latitude: 36.98420, longitude: 128.36528 }, // 단양군
    '33390': { latitude: 36.79910, longitude: 127.58047 }, // 증평군
    '34011': { latitude: 36.78537, longitude: 127.15658 }, // 천안시 동남구
    '34012': { latitude: 36.82129, longitude: 127.13324 }, // 천안시 서북구
    '34020': { latitude: 36.45554, longitude: 127.12433 }, // 공주시
    '34030': { latitude: 36.33373, longitude: 126.61287 }, // 보령시
    '34040': { latitude: 36.78392, longitude: 127.00476 }, // 아산시
    '34050': { latitude: 36.77969, longitude: 126.45026 }, // 서산시
    '34060': { latitude: 36.20257, longitude: 127.08502 }, // 논산시
    '34070': { latitude: 36.27457, longitude: 127.24829 }, // 계룡시
    '34080': { latitude: 36.89822, longitude: 126.62971 }, // 당진시
    '34310': { latitude: 36.10894, longitude: 127.48896 }, // 금산군
    '34330': { latitude: 36.27214, longitude: 126.90948 }, // 부여군
    '34340': { latitude: 36.10829, longitude: 126.69447 }, // 서천군
    '34350': { latitude: 36.45272, longitude: 126.80490 }, // 청양군
    '34360': { latitude: 36.60092, longitude: 126.66171 }, // 홍성군
    '34370': { latitude: 36.68364, longitude: 126.84700 }, // 예산군
    '34380': { latitude: 36.73850, longitude: 126.29710 }, // 태안군
    '35011': { latitude: 35.82408, longitude: 127.14453 }, // 전주시 완산구
    '35012': { latitude: 35.85738, longitude: 127.12680 }, // 전주시 덕진구
    '35020': { latitude: 35.96750, longitude: 126.73668 }, // 군산시
    '35030': { latitude: 35.95438, longitude: 126.99066 }, // 익산시
    '35040': { latitude: 35.56945, longitude: 126.85587 }, // 정읍시
    '35050': { latitude: 35.41662, longitude: 127.39057 }, // 남원시
    '35060': { latitude: 35.80077, longitude: 126.88029 }, // 김제시
    '35310': { latitude: 35.93419, longitude: 127.15970 }, // 완주군
    '35320': { latitude: 35.79190, longitude: 127.42331 }, // 진안군
    '35330': { latitude: 35.90720, longitude: 127.66015 }, // 무주군
    '35340': { latitude: 35.61827, longitude: 127.51756 }, // 장수군
    '35350': { latitude: 35.61914, longitude: 127.28766 }, // 임실군
    '35360': { latitude: 35.37492, longitude: 127.13759 }, // 순창군
    '35370': { latitude: 35.44352, longitude: 126.70491 }, // 고창군
    '35380': { latitude: 35.72896, longitude: 126.73154 }, // 부안군
    '36010': { latitude: 34.81183, longitude: 126.39289 }, // 목포시
    '36020': { latitude: 34.76043, longitude: 127.66222 }, // 여수시
    '36030': { latitude: 34.95080, longitude: 127.48761 }, // 순천시
    '36040': { latitude: 35.01563, longitude: 126.70522 }, // 나주시
    '36060': { latitude: 34.94045, longitude: 127.69569 }, // 광양시
    '36310': { latitude: 35.32186, longitude: 126.98780 }, // 담양군
    '36320': { latitude: 35.28258, longitude: 127.28839 }, // 곡성군
    '36330': { latitude: 35.21323, longitude: 127.46421 }, // 구례군
    '36350': { latitude: 34.62520, longitude: 127.28156 }, // 고흥군
    '36360': { latitude: 34.77155, longitude: 127.07963 }, // 보성군
    '36370': { latitude: 34.97303, longitude: 126.98817 }, // 화순군
    '36380': { latitude: 34.68145, longitude: 126.90747 }, // 장흥군
    '36390': { latitude: 34.64202, longitude: 126.76756 }, // 강진군
    '36400': { latitude: 34.57126, longitude: 126.60776 }, // 해남군
    '36410': { latitude: 34.80063, longitude: 126.46309 }, // 영암군
    '36420': { latitude: 34.80075, longitude: 126.38269 }, // 무안군
    '36430': { latitude: 34.88349, longitude: 126.51966 }, // 함평군
    '36440': { latitude: 35.27806, longitude: 126.48670 }, // 영광군
    '36450': { latitude: 35.30003, longitude: 126.52958 }, // 장성군
    '36460': { latitude: 34.31113, longitude: 126.75678 }, // 완도군
    '36470': { latitude: 34.48896, longitude: 126.31547 }, // 진도군
    '36480': { latitude: 34.75560, longitude: 126.17456 }, // 신안군
    '37011': { latitude: 36.01909, longitude: 129.34349 }, // 포항시 남구
    '37012': { latitude: 36.09899, longitude: 129.36334 }, // 포항시 북구
    '37020': { latitude: 35.85617, longitude: 129.22481 }, // 경주시
    '37030': { latitude: 36.12295, longitude: 128.11370 }, // 김천시
    '37040': { latitude: 36.56850, longitude: 128.72321 }, // 안동시
    '37050': { latitude: 36.11955, longitude: 128.34458 }, // 구미시
    '37060': { latitude: 36.80672, longitude: 128.62401 }, // 영주시
    '37070': { latitude: 35.97324, longitude: 128.93834 }, // 영천시
    '37080': { latitude: 36.41510, longitude: 128.16026 }, // 상주시
    '37090': { latitude: 36.58632, longitude: 128.19968 }, // 문경시
    '37100': { latitude: 35.82500, longitude: 128.74105 }, // 경산시
    '37310': { latitude: 36.24195, longitude: 128.57280 }, // 군위군
    '37320': { latitude: 36.35550, longitude: 128.68818 }, // 의성군
    '37330': { latitude: 36.43607, longitude: 129.05790 }, // 청송군
    '37340': { latitude: 36.69422, longitude: 129.11621 }, // 영양군
    '37350': { latitude: 36.41631, longitude: 129.36610 }, // 영덕군
    '37360': { latitude: 35.64824, longitude: 128.73627 }, // 청도군
    '37370': { latitude: 35.72918, longitude: 128.26370 }, // 고령군
    '37380': { latitude: 35.92070, longitude: 128.28370 }, // 성주군
    '37390': { latitude: 35.99578, longitude: 128.39735 }, // 칠곡군
    '37400': { latitude: 36.65810, longitude: 128.45527 }, // 예천군
    '37410': { latitude: 36.89334, longitude: 128.73282 }, // 봉화군
    '37420': { latitude: 36.99309, longitude: 129.40041 }, // 울진군
    '37430': { latitude: 37.47929, longitude: 130.90578 }, // 울릉군
    '38030': { latitude: 35.19221, longitude: 128.08440 }, // 진주시
    '38050': { latitude: 34.83755, longitude: 128.39622 }, // 통영시
    '38060': { latitude: 35.00354, longitude: 128.06438 }, // 사천시
    '38070': { latitude: 35.23412, longitude: 128.88189 }, // 김해시
    '38080': { latitude: 35.49373, longitude: 128.74847 }, // 밀양시
    '38090': { latitude: 34.88250, longitude: 128.62059 }, // 거제시
    '38100': { latitude: 35.34005, longitude: 129.03263 }, // 양산시
    '38111': { latitude: 35.25874, longitude: 128.63242 }, // 창원시 의창구
    '38112': { latitude: 35.21968, longitude: 128.64897 }, // 창원시 성산구
    '38113': { latitude: 35.18458, longitude: 128.56895 }, // 창원시 마산합포구
    '38114': { latitude: 35.22766, longitude: 128.58380 }, // 창원시 마산회원구
    '38115': { latitude: 35.14649, longitude: 128.81883 }, // 창원시 진해구
    '38310': { latitude: 35.31902, longitude: 128.24875 }, // 의령군
    '38320': { latitude: 35.27217, longitude: 128.40634 }, // 함안군
    '38330': { latitude: 35.55831, longitude: 128.49319 }, // 창녕군
    '38340': { latitude: 34.97604, longitude: 128.32273 }, // 고성군 (경남)
    '38350': { latitude: 34.83718, longitude: 127.89226 }, // 남해군
    '38360': { latitude: 35.06707, longitude: 127.75194 }, // 하동군
    '38370': { latitude: 35.38416, longitude: 127.87472 }, // 산청군
    '38380': { latitude: 35.51968, longitude: 127.72598 }, // 함양군
    '38390': { latitude: 35.68817, longitude: 127.90705 }, // 거창군
    '38400': { latitude: 35.56415, longitude: 128.16512 }, // 합천군
    '39010': { latitude: 33.49962, longitude: 126.53118 }, // 제주시
    '39020': { latitude: 33.25332, longitude: 126.56182 }, // 서귀포시
};
