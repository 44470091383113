import React, { useState } from "react";
import { Wrapper } from "../../components/common/commonComponents";
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Table,
  TableColumnsType,
} from "antd";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import colorConfigs from "../../configs/colorConfigs";

const _moduleBean = () => {
  return <>bean</>;
};

export default _moduleBean;
