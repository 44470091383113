import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Breadcrumb,
    Layout,
    Row,
    Col,
    Divider,
    Table,
    Button,
    Popconfirm,
    Form,
    Input,
    Space, Badge, Typography, Spin, Empty, Flex, Descriptions, Card, Image, Tabs, Tag, Watermark, Collapse,
} from "antd";
import type {FormInstance} from "antd/es/form";
import {SpanText, Wrapper} from "../../../components/common/commonComponents";
import colorConfigs from "../../../configs/colorConfigs";
import TopoJsonDetailMap from "../../../components/common/Map/topoJsonDetailMap";
import TopoJsonGlobalDetailMap from "../../../components/common/Map/topoJsonGlobalDetailMap";
import {useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";
import {
    getDashboardDetailDataSearch
} from "../../../pages/_main_contractor/_dashboardMgmt/_service/_admin/service";
import {decryptToken} from "../../../utils/tokenManagement";
import {useCookies} from "react-cookie";
import ZoomSlider from "../../../components/common/Map/ZoomSilder";
import Theme from "../../../components/common/Theme";
import {IoIosMore} from "react-icons/io";
import useEmblaCarousel from "embla-carousel-react";
import {TwitterOutlined} from "@ant-design/icons";
import {isDesktop, isTablet} from "react-device-detect";
import useWidth from "../../../components/common/useWidth";

const {Text} = Typography;

const {Content} = Layout;

// Editable Table Context
const EditableContext = React.createContext<FormInstance<any> | null>(null);

// Editable Row
const EditableRow: React.FC<{ index: number }> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

// Editable Cell
const EditableCell: React.FC<{
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: any;
    handleSave: (record: any) => void;
}> = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = React.useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

interface DashboardCategoryResponse {
    reactKey: string;
    categoryValue: string;
    categoryColor: string;
    cntRef: string;
}

interface DashboardTravelHistoryDetailResponse {
    reactKey: string;
    travelHistoryId: string;
    seq: string;
    userId: string;
    thTitle: string | null;
    thSaddress: string | null;
    thSlatitude: string | null;
    thSlongitude: string | null;
    thEaddress: string;
    thEdetailAddress: string;
    thElatitude: string;
    thElongitude: string;
    thType: string;
    thEzoneKeyword: string;
    thGubun: string;
    thContent: string;
    thStartDatetime: string;
    thEndDatetime: string;

}

interface DashboardTravelHistoryDetailImgResponse {
    reactKey: string | null;
    thTitle: string | null;
    thEaddress: string | null;
    travelHistoryAttId: string | null;
    travelHistoryAttSeq: string | null;
    travelHistoryId: string | null;
    travelHistorySeq: string | null;
    thImgFileName: string | null;
    thImgFileUrl: string | null;
    userId: string | null;
    thImgFileDir: string | null;
    recordCnt: string | null;
    createdDatetime: string | null;
    modifiedDatetime: string | null;
}

interface IResult {
    userId: string;
    dashboardTravelHistoryDetailResponses: DashboardTravelHistoryDetailResponse[];
    dashboardTravelHistoryDetailImgResponses: DashboardTravelHistoryDetailImgResponse[];
}

interface IResponseProps {
    result: IResult;
    resultCode: number;
    resultMsg: string;
}

const _dashboardDetail: React.FC = () => {
    const [isParams] = useSearchParams();
    const isTravelHistory = isParams.get('isTravelHistory');
    const isMapSynced = isParams.get('isMapSynced')?.trim(); // 공백 제거
    const isLatitude = parseFloat(isParams.get('isLatitude') || '0');
    const isLongitude = parseFloat(isParams.get('isLongitude') || '0');
    const [zoomLevel, setZoomLevel] = useState<number>(4000); // Default zoom level
    const [zoomGlobalLevel, setZoomGlobalLevel] = useState<number>(70); // Default zoom level


    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
    const token = cookies.accessToken;
    const [searchParams, setSearchParams] = useState({
        travelHistoryId: isTravelHistory,
    });

    const {data, isLoading, isError, refetch} = useQuery<IResponseProps>(
        ["data", searchParams],
        () => getDashboardDetailDataSearch(decryptToken(token), setCookie, removeCookie, searchParams.travelHistoryId || ""),
        {refetchOnWindowFocus: true}
    );

    const width = useWidth();

    const onZoomChange = (value: number) => {
        const newZoomLevel = (value / 100) * 4000;
        setZoomLevel(newZoomLevel);
        setZoomGlobalLevel(newZoomLevel / 50);
    };

    const [dataSource, setDataSource] = useState([
        {
            key: "0",
            rowNumber: 1,
            name: "Home",
            address: "London, Park Lane no. 0",
        },
        {
            key: "98",
            rowNumber: 2,
            name: "목적지",
            address: "London, Park Lane no. 1",
        },
        {
            key: "99",
            rowNumber: 3,
            name: "Home",
            address: "London, Park Lane no. 1",
        },
    ]);
    const [count, setCount] = useState(100); // 기본적으로 추가되는 키 값은 100부터 시작

    const updateRowNumbers = (data: any[]) => {
        return data.map((item, index) => ({
            ...item,
            rowNumber: index + 1,
        }));
    };

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(updateRowNumbers(newData));
    };

    const handleAdd = () => {
        const newData = {
            key: count.toString(),
            name: `Edward King ${count}`,
            address: `London, Park Lane no. ${count}`,
        };

        const updatedData = [...dataSource, newData];
        setDataSource(updateRowNumbers(updatedData));
        setCount((prevCount) => prevCount + 1); // count 증가
    };

    const handleAddBelow = (key: string) => {
        const newData = {
            key: count.toString(),
            name: `New Row ${count}`,
            address: `New Address ${count}`,
        };

        const index = dataSource.findIndex((item) => item.key === key);
        if (index !== -1) {
            const updatedData = [
                ...dataSource.slice(0, index + 1),
                newData,
                ...dataSource.slice(index + 1),
            ];
            setDataSource(updateRowNumbers(updatedData));
            setCount((prevCount) => prevCount + 1);
        }
    };

    const handleMoveUp = (key: string) => {
        const index = dataSource.findIndex((item) => item.key === key);
        if (index > 0) {
            const newData = [...dataSource];
            const temp = newData[index - 1];
            newData[index - 1] = newData[index];
            newData[index] = temp;
            setDataSource(updateRowNumbers(newData));
        }
    };

    const handleMoveDown = (key: string) => {
        const index = dataSource.findIndex((item) => item.key === key);
        if (index < dataSource.length - 1) {
            const newData = [...dataSource];
            const temp = newData[index + 1];
            newData[index + 1] = newData[index];
            newData[index] = temp;
            setDataSource(updateRowNumbers(newData));
        }
    };

    const handleSave = (row: any) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const {Panel} = Collapse;
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [mainViewportRef, emblaMain] = useEmblaCarousel({skipSnaps: false});
    const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
        containScroll: "keepSnaps",
        dragFree: true,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = () => {
        if (!emblaMain || !emblaThumbs) return;
        setSelectedIndex(emblaMain.selectedScrollSnap());
        emblaThumbs.scrollTo(emblaMain.selectedScrollSnap());
    };

    const scrollTo = (index: number) => {
        if (!emblaMain || !emblaThumbs) return;
        emblaMain.scrollTo(index);
    };

    useEffect(() => {
        if (!emblaMain) return;
        emblaMain.on("select", onSelect);
        onSelect();
    }, [emblaMain]);

    const columns = [
        {
            title: "Row Number",
            dataIndex: "rowNumber",
            editable: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            editable: true,
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_: any, record: { key: string }) => (
                <Space>
                    <Popconfirm title="Sure to append?" onConfirm={() => handleAddBelow(record.key)}>
                        <Typography.Link onClick={() => handleMoveUp(record.key)}>
                            <Badge color={colorConfigs.table_status.forwarding} text="Append"
                                   style={{color: colorConfigs.table_status.forwarding}}/>
                        </Typography.Link>
                    </Popconfirm>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <Typography.Link onClick={() => handleMoveUp(record.key)}>
                            <Badge color={colorConfigs.table_status.ban} text="Delete"
                                   style={{color: colorConfigs.table_status.ban}}/>
                        </Typography.Link>
                    </Popconfirm>
                    <Typography.Link onClick={() => handleMoveUp(record.key)}>
                        <Badge color={colorConfigs.table_status.actionColor} text="Move Up"
                               style={{color: colorConfigs.table_status.actionColor}}/>
                    </Typography.Link>
                    <Typography.Link onClick={() => handleMoveDown(record.key)}>
                        <Badge color={colorConfigs.table_status.actionColor} text="Move Down"
                               style={{color: colorConfigs.table_status.actionColor}}/>
                    </Typography.Link>
                </Space>
            ),
        },
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });


    const [defaultActiveKey, setDefaultActiveKey] = useState("historyInfoList");
    const {TabPane} = Tabs;
    const [keyAttact, setKeyAttact] = useState<boolean>(false); // Default zoom level

    const handleTabChange = (key: React.SetStateAction<string>) => {
        setDefaultActiveKey(key);
        setKeyAttact(false);
    };
    // 데이터 조회 로딩
    if (isLoading) {
        return <Spin/>;
    }
    // 데이터 조회 에러
    if (isError) {
        return (
            <Content
                style={{
                    padding: 14,
                    margin: 0,
                    minHeight: 280,
                    // background: Theme.basicTheme_C,
                }}
            >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            </Content>
        );
    }
    return (
        <Wrapper>
            <Layout style={{padding: "0 10px 100px", width: "100%", height: "100%"}}>
                <Breadcrumb style={{margin: "0px 0px 10px 0px"}}>
                    <Breadcrumb.Item>FITA-GRAM</Breadcrumb.Item>
                    <Breadcrumb.Item>{isMapSynced}</Breadcrumb.Item>
                    <Breadcrumb.Item>{isLatitude}</Breadcrumb.Item>
                    <Breadcrumb.Item>{isLongitude}</Breadcrumb.Item>
                    <Breadcrumb.Item>{isTravelHistory}</Breadcrumb.Item>
                </Breadcrumb>
                <Divider/>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={width>1366 ? 12 : 24}>
                        <Wrapper
                            display="flex"
                            dr="row"
                            ju="flex-start"
                            al="space-between"
                            padding="32px 16px"
                            minHeight="300px"
                            // height="70vh"
                            radius="25px"
                            overflow="hidden"
                            shadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            bgColor={colorConfigs.basic.white}
                            style={{
                                height: isTablet ? '70vh' : '75vh', // 모바일일 때만 30vh, 아니면 auto
                            }}
                        >
                            <Flex gap="4px 0" style={{marginTop: 10}}>
                                <Descriptions>
                                    <Descriptions.Item label="줌 사이즈 ">
                                        <ZoomSlider zoomLevel={zoomLevel} onZoomChange={onZoomChange}/>

                                    </Descriptions.Item>
                                </Descriptions>
                            </Flex>
                            {/*<TopoJsonGlobalDetailMap latitude={isLatitude} longitude={isLongitude} refetch={refetch} zoomLevel={zoomGlobalLevel} historyData={data?.result.dashboardTravelHistoryResponses || []}/>*/}

                            {isMapSynced == "GLOBAL" ?
                                <TopoJsonGlobalDetailMap latitude={isLatitude} longitude={isLongitude} refetch={refetch}
                                                         zoomLevel={zoomGlobalLevel * 3}
                                                         historyData={data?.result.dashboardTravelHistoryDetailResponses || []}/>
                                :
                                <TopoJsonDetailMap refetch={refetch} zoomLevel={zoomLevel}
                                                   historyData={data?.result.dashboardTravelHistoryDetailResponses || []}/>
                            }
                        </Wrapper>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={width>1366 ? 12 : 24}>
                        <Wrapper
                            display="flex"
                            dr="row"
                            ju="flex-start"
                            al="space-between"
                            padding="32px 16px"
                            minHeight="300px"
                            // height="70vh"
                            radius="25px"
                            overflow="hidden"
                            shadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            bgColor={colorConfigs.basic.white}
                            overflowY="auto" /* 세로 스크롤 활성화 */
                            style={{
                                height: isTablet || width<=1366 ? '100vh' : '75vh', // 모바일일 때만 30vh, 아니면 auto
                            }}
                        >
                            <Tabs activeKey={defaultActiveKey} onChange={handleTabChange}>
                                <TabPane tab="일지 정보 조회" key="historyInfoList">
                                    <Divider orientation="left"
                                             style={{color: colorConfigs.pointColor.green}}><span>{"[ Doc No. : " + data?.result.dashboardTravelHistoryDetailResponses[1].travelHistoryId + "-" + data?.result.dashboardTravelHistoryDetailResponses[1].thTitle + " ]"}</span></Divider>
                                    <Descriptions layout="vertical"
                                                  >
                                        <Descriptions.Item label="시작 일자">
                                            {data?.result.dashboardTravelHistoryDetailResponses[1].thStartDatetime}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="">
                                            <span style={{fontSize: "16px", fontWeight: "bold"}}>→</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="종료 일자">
                                            {data?.result.dashboardTravelHistoryDetailResponses[1].thEndDatetime}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions layout="vertical"
                                                  column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}>
                                        <Descriptions.Item label="출발지">
                                            {data?.result.dashboardTravelHistoryDetailResponses[0].thEaddress}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="">
                                            <span style={{fontSize: "16px", fontWeight: "bold"}}>→</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="목적지">
                                            {data?.result.dashboardTravelHistoryDetailResponses[1].thEaddress} / {data?.result.dashboardTravelHistoryDetailResponses[1].thEdetailAddress}
                                        </Descriptions.Item>
                                    </Descriptions>


                                    <Descriptions layout="vertical">
                                        <Descriptions.Item label="구분">
                                            {data?.result?.dashboardTravelHistoryDetailResponses[1]?.thGubun && (
                                                <Tag
                                                    icon={<TwitterOutlined/>}
                                                    color={
                                                        typeof colorConfigs[data?.result.dashboardTravelHistoryDetailResponses[1].thGubun as keyof typeof colorConfigs] === "string"
                                                            ? (colorConfigs[data?.result.dashboardTravelHistoryDetailResponses[1].thGubun as keyof typeof colorConfigs] as string)
                                                            : "#D3D3D3" // 기본 색상
                                                    }
                                                >
                                                    {data.result.dashboardTravelHistoryDetailResponses[1].thGubun}
                                                </Tag>
                                            )}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="타입">
                                            {data?.result?.dashboardTravelHistoryDetailResponses[1]?.thType && (
                                                <Tag
                                                    icon={<TwitterOutlined/>}
                                                    color={
                                                        typeof colorConfigs[data?.result.dashboardTravelHistoryDetailResponses[1].thType as keyof typeof colorConfigs] === "string"
                                                            ? (colorConfigs[data?.result.dashboardTravelHistoryDetailResponses[1].thType as keyof typeof colorConfigs] as string)
                                                            : "#D3D3D3" // 기본 색상
                                                    }
                                                >
                                                    {data.result.dashboardTravelHistoryDetailResponses[1].thType}
                                                </Tag>
                                            )}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions layout="vertical">
                                        <Descriptions.Item label=" 비고" span={3}>
                                            {data?.result.dashboardTravelHistoryDetailResponses[1].thContent}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Wrapper className="embla-carousel">
                                        <Wrapper className="embla__viewport" ref={mainViewportRef}>
                                            <Image.PreviewGroup>
                                                <div className="embla__container">
                                                    {(data?.result.dashboardTravelHistoryDetailImgResponses || []).map(
                                                        (item: any, index: number) => (
                                                            <div className="embla__slide" key={index}>
                                                                {Number(item.recordCnt) > 1 ? (
                                                                    <Badge
                                                                        count={Number(item.recordCnt) - 1}
                                                                        overflowCount={99}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "10px",
                                                                            right: "10px",
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            src={item.thImgFileUrl || ""}
                                                                            alt={`Slide ${index}`}
                                                                            className="embla__slide__img"
                                                                            onError={(e) =>
                                                                                (e.currentTarget.src =
                                                                                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7////39v///y4Dmn+LgeHANwDrkl1AuO+pmg")}
                                                                        />
                                                                    </Badge>
                                                                ) : (
                                                                    <Watermark
                                                                        content={['FITA', 'Copyright ⓒ 2024, FITA All rights reserved.']}>
                                                                        <Image
                                                                            src={item.thImgFileUrl || ""}
                                                                            alt={`Slide ${index}`}
                                                                            className="embla__slide__img"
                                                                        />
                                                                    </Watermark>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Image.PreviewGroup>
                                        </Wrapper>

                                        {/* Thumbnails */}
                                        <div className="embla embla--thumb" ref={thumbViewportRef}>
                                            <div className="embla__container embla__container--thumb">
                                                {(data?.result.dashboardTravelHistoryDetailImgResponses || []).map(
                                                    (item: any, index: number) => (
                                                        <div
                                                            key={index}
                                                            className={`embla__slide embla__slide--thumb ${
                                                                index === selectedIndex ? "is-selected" : ""
                                                            }`}
                                                            onClick={() => scrollTo(index)}
                                                        >
                                                            <img
                                                                src={item.thImgFileUrl || ""}
                                                                alt={`Thumbnail ${index}`}
                                                                className="embla__slide__img embla__slide__img--thumb"
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </Wrapper>
                                </TabPane>

                                <TabPane tab="이미지 모아보기" key="historyImageList">
                                    <Wrapper
                                        display="flex"
                                        dr="column"
                                        ju="center"
                                        al="center"
                                        padding="32px 16px"
                                        minHeight="300px"
                                        height="70vh"
                                        radius="25px"
                                        bgColor={colorConfigs.basic.white}
                                    > <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                        {/* Main Carousel */}
                                        <Wrapper className="embla-carousel">
                                            <Wrapper className="embla__viewport" ref={mainViewportRef}>
                                                <Image.PreviewGroup>
                                                    <div className="embla__container">
                                                        {(data?.result.dashboardTravelHistoryDetailImgResponses || []).map(
                                                            (item: any, index: number) => (
                                                                <div className="embla__slide" key={index}>
                                                                    {Number(item.recordCnt) > 1 ? (
                                                                        <Badge
                                                                            count={Number(item.recordCnt) - 1}
                                                                            overflowCount={99}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "10px",
                                                                                right: "10px",
                                                                            }}
                                                                        >
                                                                            <Image
                                                                                src={item.thImgFileUrl || ""}
                                                                                alt={`Slide ${index}`}
                                                                                className="embla__slide__img"
                                                                                onError={(e) =>
                                                                                    (e.currentTarget.src =
                                                                                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7////39v///y4Dmn+LgeHANwDrkl1AuO+pmg")}
                                                                            />
                                                                        </Badge>
                                                                    ) : (
                                                                        <Image
                                                                            src={item.thImgFileUrl || ""}
                                                                            alt={`Slide ${index}`}
                                                                            className="embla__slide__img"
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </Image.PreviewGroup>
                                            </Wrapper>

                                            {/* Thumbnails */}
                                            <div className="embla embla--thumb" ref={thumbViewportRef}>
                                                <div className="embla__container embla__container--thumb">
                                                    {(data?.result.dashboardTravelHistoryDetailImgResponses || []).map(
                                                        (item: any, index: number) => (
                                                            <div
                                                                key={index}
                                                                className={`embla__slide embla__slide--thumb ${
                                                                    index === selectedIndex ? "is-selected" : ""
                                                                }`}
                                                                onClick={() => scrollTo(index)}
                                                            >
                                                                <img
                                                                    src={item.thImgFileUrl || ""}
                                                                    alt={`Thumbnail ${index}`}
                                                                    className="embla__slide__img embla__slide__img--thumb"
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </Wrapper>


                                    </Col>
                                    </Wrapper>
                                </TabPane>
                            </Tabs>
                        </Wrapper>
                    </Col>
                    <Divider></Divider>
                    {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                    {/*    /!*<Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>*!/*/}
                    {/*    /!*    Add a row*!/*/}
                    {/*    /!*</Button>*!/*/}
                    {/*    <Collapse accordion>*/}
                    {/*        <Panel header="상세경로 조회" key="1">*/}
                    {/*            <Table*/}
                    {/*                components={components}*/}
                    {/*                rowClassName={() => "editable-row"}*/}
                    {/*                bordered*/}
                    {/*                dataSource={dataSource}*/}
                    {/*                columns={columns}*/}
                    {/*            />*/}
                    {/*        </Panel>*/}
                    {/*    </Collapse>*/}
                    {/*</Col>*/}
                </Row>
            </Layout>
        </Wrapper>
    )
        ;
};

export default _dashboardDetail;
