// axios Response Code Define
const axiosResponseCodes = {
  200: "OK: The request was successful.", // 정상: 요청이 성공적으로 처리되었습니다. (The request was successful.)
  201: "Created: The request was successful and a resource was created.", // 생성됨: 요청이 성공하였고 리소스가 생성되었습니다. (The request was successful and a resource was created.)
  202: "Accepted: The request has been accepted for processing.", // 수락됨: 요청이 처리를 위해 수락되었습니다. (The request has been accepted for processing.)
  204: "No Content: The request was successful, but there's no representation to return.", // 내용 없음: 요청은 성공했지만 반환할 표현이 없습니다. (The request was successful, but there's no representation to return.)
  400: "Bad Request: The request was invalid.", // 잘못된 요청: 요청이 유효하지 않습니다. (The request was invalid.)
  401: "Unauthorized: Authentication failed.", // 인증 실패: 인증에 실패했습니다. (Authentication failed.)
  403: "Forbidden: Access to this resource is denied.", // 금지됨: 이 리소스에 대한 접근이 거부되었습니다. (Access to this resource is denied.)
  404: "Not Found: The requested resource was not found.", // 찾을 수 없음: 요청한 리소스를 찾을 수 없습니다. (The requested resource was not found.)
  405: "Method Not Allowed: The HTTP method used is not allowed for this resource.", // 허용되지 않는 메소드: 사용된 HTTP 메소드가 이 리소스에 대해 허용되지 않습니다. (The HTTP method used is not allowed for this resource.)
  406: "Not Acceptable: The server cannot produce a response matching the list of acceptable values.", // 허용되지 않음: 서버는 허용되는 값의 목록과 일치하는 응답을 생성할 수 없습니다. (The server cannot produce a response matching the list of acceptable values.)
  408: "Request Timeout: The server timed out waiting for the request.", // 요청 시간 초과: 서버가 요청을 기다리는 동안 시간이 초과되었습니다. (The server timed out waiting for the request.)
  429: "Too Many Requests: The user has sent too many requests in a given amount of time.", // 너무 많은 요청: 사용자가 주어진 시간 동안 너무 많은 요청을 보냈습니다. (The user has sent too many requests in a given amount of time.)
  500: "Internal Server Error: An unexpected server error occurred.", // 내부 서버 오류: 예상치 못한 서버 오류가 발생했습니다. (An unexpected server error occurred.)
  501: "Not Implemented: The server does not support the functionality required to fulfill the request.", // 구현되지 않음: 서버는 요청을 완료하기 위해 필요한 기능을 지원하지 않습니다. (The server does not support the functionality required to fulfill the request.)
  502: "Bad Gateway: The server received an invalid response from the upstream server.", // 잘못된 게이트웨이: 서버가 상위 서버로부터 잘못된 응답을 받았습니다. (The server received an invalid response from the upstream server.)
  503: "Service Unavailable: The server is currently unable to handle the request.", // 서비스를 사용할 수 없음: 서버는 현재 요청을 처리할 수 없습니다. (The server is currently unable to handle the request.)
  504: "Gateway Timeout: The gateway timed out waiting for a response from the server.", // 게이트웨이 시간 초과: 게이트웨이가 서버의 응답을 기다리는 동안 시간이 초과되었습니다. (The gateway timed out waiting for a response from the server.)
};

const axiosResponseMessages = {
  error: "Error: An error occurred.", // 기본 오류 메시지 (Default error message)
  occurred: "An error occurred.", // 오류가 발생했습니다 (An error occurred)
  networkError: "Network error: Please try again later.", // 네트워크 오류가 발생할 때 (When a network error occurs)
  unexpectedError: "Unexpected error: An unexpected error occurred.", // 예상치 못한 오류가 발생할 때 (When an unexpected error occurs)
  validationError: "Validation error: Input data is invalid.", // 입력 데이터 검증 오류 (Input data validation error)
  authenticationError:
    "Authentication error: You are not authorized to access this resource.", // 인증 오류가 발생할 때 (When an authentication error occurs)
  fileNotFoundError: "File not found: The requested file cannot be found.", // 파일을 찾을 수 없을 때 (When the file is not found.)
  dataNotFoundError: "Data not found: The requested data was not found.", // 데이터를 찾을 수 없을 때 (When data is not found.)
  timeoutError: "Timeout error: The request took too long.", // 요청이 너무 오래 걸렸을 때 (When the request takes too long)
  serviceUnavailable:
    "Service unavailable: The service is currently not accessible.", // 서비스를 사용할 수 없을 때 (When the service is unavailable)
  conflictError:
    "Conflict error: The requested action conflicts with existing data.", // 요청한 작업이 기존 데이터와 충돌할 때 (When there's a conflict with existing data)
  // 필요에 따라 추가적인 사용자 정의 오류 메시지를 추가할 수 있습니다. (You can add more custom error messages as needed.)
};

const customErrorCode = {
  HJ_9999: "HJ_9999",
};

const messageCode = {};

export const userRoleCode = {
  MAIN_CONTRACTOR_USER: "MAIN_CONTRACTOR_USER",
  SUBCONTRACTOR_USER: "SUBCONTRACTOR_USER",
  GENERAL_USER: "GENERAL_USER",
  INCORPORATED_USER: "INCORPORATED_USER",
  INDIVIDUAL_USER: "INDIVIDUAL_USER",
};

const msgResponse = {
  success: "알림",
  warning: "경고", // 기본 오류 메시지 (Default error message)
  error: "에러",
  // 필요에 따라 추가적인 사용자 정의 오류 메시지를 추가할 수 있습니다. (You can add more custom error messages as needed.)
};


const authList = {
  ALL: ["ADMIN","MANAGER","GENERAL_USER"],
  ADMIN: ["ADMIN"],
  MANAGER: ["MANAGER"],
  USER: ["GENERAL_USER"],
  MANAGERtoADMIN: ["ADMIN","MANAGER"],
  USERtoADMIN: ["ADMIN","GENERAL_USER"],
  MANAGERtoUSER: ["MANAGER", "GENERAL_USER"],
  DENY: [""],
};

// Export the errorMessages object so that it can be used in other TypeScript files
export default {
  axiosResponseCodes,
  axiosResponseMessages,
  customErrorCode,
  userRoleCode,
  msgResponse,
  authList
};
